import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import PropTypes from "prop-types";
import {
  GetDealImgUrl,
  GetShippingValue,
  ConvertDateToDateString,
} from "../../../helpers/modelsHelper";
import Link from "@material-ui/core/Link";
import { useTranslation } from "react-i18next";
import Image from "material-ui-image";
import {
  EmitCustomEvent,
  SeoCustomEvents,
  ConvertDealToAnalyticItemArray,
} from "../../../helpers/seoHelper";
import {
  BuildUtmUrl
} from "../../../helpers/utmHelper";
import { useParams } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  img: {
    margin: "auto",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "75px",
      maxHeight: "75px",
    },
  },
  oldPrice: {
    textDecoration: "line-through",
    color: grey[600],
    [theme.breakpoints.down("xs")]: {
      fontSize: "small",
    },
  },
  newPrice: {
    color: "#d50000",
    fontWeight: "bold",
  },
  discount: {
    color: grey[600],
  },
}));

 export default function DealInfo({ currentDeal, ...props }) {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);
  const { group , orderby } = useParams();
  const createMarkup = (html) => {
    return { __html: html };
  };

  const handleClick = (event) => {
    EmitCustomEvent(SeoCustomEvents.view_item_url, {
      currency: currentDeal.dealCurrency.toString(),
      value: Number(currentDeal.newPrice.toString()),
      items: ConvertDealToAnalyticItemArray(currentDeal),
    });

    let _urlToOpen = BuildUtmUrl(currentDeal.dealUrl, group , orderby ? orderby: "for-you")
    window.open(_urlToOpen);
  };

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={3} sm={3} md={3}>
        <Grid item className={classes.img}>
          <Image src={GetDealImgUrl(currentDeal)} alt={currentDeal.title} />
        </Grid>
      </Grid>

      <Grid item xs={9} sm={9} md={9}>
        <Grid item container direction="column">
          <Grid item>
            <strong dangerouslySetInnerHTML={createMarkup(currentDeal.title)} />
            <Typography variant="body2" color="textSecondary">
              {currentDeal.createAt === undefined
                ? null
                : ConvertDateToDateString(currentDeal.createAt)}
            </Typography>
            <Typography variant="subtitle1">
              <span className={classes.oldPrice}>
                {currentDeal.oldPrice} {t(currentDeal.dealCurrency)}
              </span>
              <span> | </span>
              <span className={classes.newPrice}>
                {currentDeal.newPrice} {t(currentDeal.dealCurrency)}
              </span>
              {/* <span> | </span>
                <span>
                  {GetDiscountPercentage(
                    currentDeal.oldPrice,
                    currentDeal.newPrice
                  )}
                </span> */}
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              style={{ cursor: "pointer" }}
            >
              {t("LBL_DEALS_BY")} :
              <Link
                // href={currentDeal.merchantBaseUrl ? null : "#"}
                onClick={handleClick}
              >
                {currentDeal.merchantName}
              </Link>
            </Typography>
            {/* <Typography variant="body2" color="textSecondary">
                {t("LBL_MEANS_OF_PAYMENT")} :
                {currentDeal.paymentMethod.map((item) => t(item)).join(" | ")}
              </Typography> */}
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
              variant="body2"
              color="textSecondary"
            >
              <span
                style={{
                  paddingRight: "5px",
                }}
              >
                {GetShippingValue(currentDeal, t)}
              </span>
              <LocalShippingIcon />
            </Typography>
            
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

DealInfo.propTypes = {
  currentDeal: PropTypes.object.isRequired,
};


