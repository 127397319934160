import { FromApiDealToDealModel } from "../../helpers/modelsHelper";

export async function handleResponse(response: any) {
  //console.log(response);
  return response.data;
}

// In a real app, would likely call an error logging service.
export function handleError(error: any) {
  // eslint-disable-next-line no-console
  //console.error("API call failed. " + error);
  throw error;
}

export async function handleDealResponse(response: any) {
  return FromApiDealToDealModel(response.data);
}

export async function handleDealListResponse(response: any) {
  return response.data.map((_: any) => FromApiDealToDealModel(_));
}

export async function handleAdminDealListResponse(response: any) {
  return response.data.map((_: any) => _);
}
