import axios from "axios";
import { getFirebase } from "react-redux-firebase";
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export async function GetUserToken() {
  let currentUser = await getFirebase().auth().currentUser;
  if (currentUser) {
    axios.defaults.headers.common = {
      Authorization: `bearer ${await currentUser.getIdToken()}`,
    };
  }
}

export async function GetAppTokenToken() {
  let customToken = null;
  if (customToken) {
    axios.defaults.headers.common = {
      Authorization: `bearer ${customToken}`,
    };
  }
}

export default axios;
/* DEALS */
export const API_GET_DEALS_BY_DOMAIN = "/deals/GetDealsByDomainKey";
export const API_SEARCH_DEALS = "/api/search";
export const API_GET_DEAL_BY_ID = "/deals/GetById";
export const API_GET_DEAL_BY_USER_ID = "/deals/GetFollowedDeals";
export const API_GET_DEAL_BY_OWNER_ID = "/deals/GetByOwnerId";
export const API_GET_DEAL_BY_URL = "/deals/GetDealByUrl";
export const API_POST_DEAL = "/deals/Create";
export const API_PUT_DEAL = "/deals/Edit";
export const API_POST_FOLLOW_DEAL = "/deals/Follow";
export const API_POST_UNFOLLOW_DEAL = "/deals/Unfollow";
export const API_POST_REPORT_DEAL = "/deals/Report";
export const API_POST_TEMPERATURE_DEAL = "/deals/UpdateTemperature";
export const API_DISABLE_DEAL = "/deals/Disable";
export const API_DELETE_DEAL = "/deals/Delete";

/* COMMENTS */
export const API_GET_COMMENTS_BY_DEAL_ID = "/comments/GetCommentsByDeal";
export const API_GET_COMMENT_BY_USER_AND_DEAL_ID =
  "/comments/GetByUserAndDealId";
export const API_POST_COMMENT = "/comments/Create";
export const API_PUT_COMMENT = "/comments/Edit";

/* USERS */
export const API_POST_USER = "/users/Create";

/* Admin */
export const API_ADMIN_GET_DEAL_BY_FILTERS = "api/admin/deals";
export const API_ADMIN_PUT_DEAL = "api/admin/deals";
export const API_ADMIN_PUT_DEAL_FB_POST = "api/admin/deals/${dealId}/fbpost";
export const API_ADMIN_DELETE_DEAL = "api/admin/deals/${dealId}";


/* Firebase */
export const API_FIREBASE_DELETE_DEAL = "api/firebase/deals/${dealId}";
export const API_FIREBASE_DELETE_DEAL_IMG = "api/firebase/files";
