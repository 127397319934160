import {
    handleError,
} from "./apiUtils";
import { FireStoreCollections } from "./config/firebase.config";


export function addNewUser(firestore: any, authResp: any, newUser: any) {
    return firestore
        .collection(FireStoreCollections.USERS_COLLECTION)
        .doc(authResp.user.uid)
        .set({
            pseudo: newUser.pseudo,
            initials: newUser.pseudo[0],
            id: authResp.user.uid,
            receiveUpdates: newUser.receiveUpdates,
            email :authResp.user.email
        }).catch(handleError);
}
