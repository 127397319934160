import {
  handleResponse,
  handleError,
  handleSingleDealDocResponse,
} from "./apiUtils";

import {
  FireStoreCollections,
  FireStoreDealsGetLimit,
} from "./config/firebase.config";

export function getDeals(firestore: any, groupFilter: string) {
  return firestore
    .collection(FireStoreCollections.DEALS_COLLECTION)
    .orderBy("createAt", "desc")
    .where("dealGroupKey", "array-contains", groupFilter)
    .where("status", "in", ["ACTIVE", "VERIFIED"])
    .limit(FireStoreDealsGetLimit)
    .get()
    .then(handleResponse)
    .catch(handleError);
}

export function saveDeal(docRef: any, deal: any) {
  return docRef.set(deal, { merge: true }).catch(handleError);
}

export function deleteDeal(docRef: any) {
  return docRef.delete().catch(handleError);
}

export function getUserDeals(firestore: any, userId: string) {
  return firestore
    .collection(FireStoreCollections.DEALS_COLLECTION)
    .orderBy("createAt", "desc")
    .where("ownerId", "==", userId)
    .limit(FireStoreDealsGetLimit)
    .get()
    .then(handleResponse)
    .catch(handleError);
}

export function getDealByUrl(firestore: any, dealUrl: string) {
  return firestore
    .collection(FireStoreCollections.DEALS_COLLECTION)
    .orderBy("createAt", "desc")
    .where("dealUrl", "==", dealUrl)
    .where("status", "==", "ACTIVE")
    .limit(1)
    .get()
    .then(handleResponse)
    .catch(handleError);
}

export function getDealById(firestore: any, dealId: any) {
  return firestore
    .collection(FireStoreCollections.DEALS_COLLECTION)
    .doc(dealId)
    .get()
    .then(handleSingleDealDocResponse)
    .catch(handleError);
}

export function voteDeal(
  docRef: any,
  vote: number,
  voteLevel: any,
  positiveVoteUsers: string[],
  negativeVoteUsers: string[]
) {
  return docRef
    .set(
      {
        vote,
        voteLevel,
        positiveVoteUsers,
        negativeVoteUsers,
      },
      { merge: true }
    )
    .catch(handleError);
}

export function reportDeal(docRef: any, reporterUsers: string[]) {
  return docRef
    .set(
      {
        reporterUsers,
      },
      { merge: true }
    )
    .catch(handleError);
}

export function followDeal(docRef: any, followerUsers: string[]) {
  return docRef
    .set(
      {
        followerUsers,
      },
      { merge: true }
    )
    .catch(handleError);
}
