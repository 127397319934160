import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import UserProfileTabs from "../../UserProfileTabs";
import Container from "@material-ui/core/Container";
import UserDealPreview from "./UserDealPreview";
import DealManagementForm from "./DealManagementForm";
import Paper from "@material-ui/core/Paper";
import {
  GetEmptyDeal,
  GetGroupUrlFromGroupKey,
  GetMerchantFromUrl
} from "../../../../helpers/modelsHelper";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  saveDeal,
  getDealById,
  getDealByUrl,
} from "../../../../redux/actions/dealActions";
import {
  IsValidUrl,
  IsValidVideoUrl,
  GenerateTags,
  SetDealOwner,
  SetDealStatusForExitingDeal,
  SetDealStatusForNewDeal,
  stringToFloat,
} from "../../../../helpers/modelsHelper";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import SnackbarComponent from "../../../common/SnackbarComponent";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageTagsComponent from "../../../common/PageTagsComponent";
import {
  EmitCustomEvent,
  SeoCustomEvents,
  ConvertDealToAnalyticItemArray,
} from "../../../../helpers/seoHelper";
import LoaderComponent from "../../../common/LoaderComponent";
import { getUserRoles } from "../../../../redux/actions/adminActions";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "24px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export function UserDealManagementContainer({
  auth,
  targetDeal,
  loading,
  saveDeal,
  getDealById,
  getDealByUrl,
  getUserRoles,
  ...props
}) {
  const classes = useStyles();
  const [deal, setDeal] = useState(targetDeal);
  let [roles, setRoles] = useState(false);
  const [existingDealUrl, setExistingDealUrl] = useState(null);
  const [saving, setSaving] = useState(false);
  let { id } = useParams();
  let history = useHistory();
  const [errors, setErrors] = useState({});
  const [isScraping, setIsScraping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isImgUpdated, setIsImgUpdated] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    open: false,
    message: null,
    severity: "info",
  });
  const { t } = useTranslation(["translation"]);

  useEffect(() => {
    if (roles === false) {
      getUserRoles()
        .then((_roles) => {
          if (_roles && _roles.admin === true) {
            setRoles(true);
          } else {
            setRoles(false);
          }
        })
        .catch((error) => {});
    }

    if (id && auth) {
      setIsLoading(true);
      getDealById(id)
        .then((_deal) => {
          setDeal(_deal);
          setIsLoading(false);
        })
        .catch((err) => {
          history.push("/Error");
        });
    }
  }, []);

  if (deal && deal.ownerId && auth && auth.uid !== deal.ownerId)
    return <Redirect to="/Unauthorized" />;

  function SnackbarComponentChange() {
    if (snackbarData.severity === "success") {
      history.push("/deal/my");
      return;
    }
    setSnackbarData({
      open: false,
      message: null,
      severity: snackbarData.severity,
    });
  }

  const onImgUrlChange = (fireBaseUrl) => {
    setDeal({ ...deal, dealImgUrls: [fireBaseUrl] });
    setIsImgUpdated(true);
  };
  const formIsValid = () => {
    let _errors = { ...errors };

    if (deal.dealVideoUrl && !IsValidVideoUrl(deal.dealVideoUrl)) {
      _errors.dealVideoUrl = t("LBL_VIDEO_URL_INVALID_FORMAT");
    }

    if (!deal.newPrice) _errors.newPrice = t("LBL_REQUIRED");
    if (!deal.oldPrice) _errors.oldPrice = t("LBL_REQUIRED");
    if (!deal.title) _errors.title = t("LBL_REQUIRED");

    if (!deal.dealUrl) _errors.dealUrl = t("LBL_REQUIRED");
    else if (!IsValidUrl(deal.dealUrl)) {
      _errors.dealUrl = t("LBL_INVALID_URL_FORMAT");
    }

    setErrors(_errors);
    return Object.values(_errors).filter((err) => err).length === 0;
  };

  const handleInputFormChange = (event) => {
    setDeal({ ...deal, [event.target.name]: event.target.value });
  };

  const handleDataChange = (data) => {
    setDeal(data);

    setIsScraping(false);
  };

  const handleChangeScraping = (data) => {
    setIsScraping(data);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!formIsValid()) return;
    setSaving(true);
    let dealToSubmit = GenerateTags(deal);
    dealToSubmit = SetDealOwner(dealToSubmit, auth);
    dealToSubmit = {
      ...dealToSubmit,
      newPrice: stringToFloat(dealToSubmit.newPrice),
      oldPrice: stringToFloat(dealToSubmit.oldPrice),
      shippingFees: stringToFloat(dealToSubmit.shippingFees),
      vote: stringToFloat(dealToSubmit.vote),
    };

    let merchantData = GetMerchantFromUrl(dealToSubmit.dealUrl);
    dealToSubmit = {
      ...dealToSubmit,
      merchantId: merchantData.merchantId,
      merchantName: merchantData.merchantName,
      merchantBaseUrl: merchantData.baseUrl,
    };

    if (!dealToSubmit) {
      setSnackbarData({
        open: true,
        message: "ERROR_CNX_MESSAGE",
        severity: "warning",
      });
      setSaving(false);
      return;
    }
    if (id) {
      dealToSubmit = SetDealStatusForExitingDeal(
        dealToSubmit,
        isImgUpdated,
        roles
      );
      EmitCustomEvent(SeoCustomEvents.update_item, {
        currency: dealToSubmit.dealCurrency.toString(),
        value: Number(dealToSubmit.newPrice.toString()),
        items: ConvertDealToAnalyticItemArray(dealToSubmit),
      });
    } else {
      dealToSubmit = SetDealStatusForNewDeal(dealToSubmit, roles);
      EmitCustomEvent(SeoCustomEvents.add_item, {
        currency: dealToSubmit.dealCurrency.toString(),
        value: Number(dealToSubmit.newPrice.toString()),
        items: ConvertDealToAnalyticItemArray(dealToSubmit),
      });
    }

    saveDeal(dealToSubmit)
      .then((deal) => {
        setSnackbarData({
          open: true,
          message: t("SUCCESS_MSG"),
          severity: "success",
        });
        //setDeal(deal);
        //history.push("/deal/my");
      })
      .catch((error) => {
        setSnackbarData({
          open: true,
          message: "ERROR_MESSAGE",
          severity: "error",
        });
        setSaving(false);
      });
  };

  const handleInputValidationOnBlur = (event) => {
    let _errors = {};
    switch (event.target.name) {
      case "dealUrl":
        if (!deal.dealUrl) _errors.dealUrl = t("LBL_REQUIRED");
        else if (!IsValidUrl(deal.dealUrl)) {
          _errors.dealUrl = t("LBL_INVALID_URL_FORMAT");
        } else {
          setIsScraping(true);

          getDealByUrl(deal.dealUrl).then((_deals) => {
            if (_deals && _deals.length > 0) {
              let groupUrl = GetGroupUrlFromGroupKey(_deals[0].dealGroupKey[0]);
              let url =
                window.location.origin +
                "/deal/" +
                groupUrl +
                "/details/" +
                _deals[0].id;

              setExistingDealUrl(url);

              _errors.dealUrl = t("LBL_DEAL_ALREADY_EXIST");
              setErrors({
                ...errors,
                [event.target.name]: _errors[event.target.name],
              });
            } else {
              setExistingDealUrl(null);
            }
            //setIsScraping(false);
          });
        }

        break;
      case "dealVideoUrl":
        if (deal.dealVideoUrl && !IsValidVideoUrl(deal.dealVideoUrl)) {
          _errors.dealVideoUrl = t("LBL_VIDEO_URL_INVALID_FORMAT");
        }
        break;
      default:
        _errors[event.target.name] = PropIsRequired(event.target.name);
        break;
    }
    setErrors({ ...errors, [event.target.name]: _errors[event.target.name] });
  };

  const PropIsRequired = (propName) => {
    if (!deal[propName]) return t("LBL_REQUIRED");
  };

  return (
    <>
      <SnackbarComponent {...snackbarData} onClose={SnackbarComponentChange} />
      <UserProfileTabs />
      <PageTagsComponent title={id ? "UPDATE_DEAL" : "ADD_DEAL"} />

      <Container className={classes.root}>
        <Grid container spacing={2}>
          <Grid item sm={7} xs={12} component={Paper} elevation={6} square>
            {isLoading ? (
              <LoaderComponent />
            ) : (
              <DealManagementForm
                saving={saving}
                errors={errors}
                deal={deal}
                onChange={handleInputFormChange}
                onBlur={handleInputValidationOnBlur}
                onSubmit={handleSubmit}
                handleDataChange={handleDataChange}
                existingDealUrl={existingDealUrl}
                isScraping={isScraping}
                handleChangeScraping={handleChangeScraping}
                isAdmin={roles}
              />
            )}
          </Grid>
          <Grid item sm={5} xs={12}>
            <UserDealPreview deal={deal} onImgUrlChange={onImgUrlChange} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

UserDealManagementContainer.propTypes = {
  targetDeal: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  let auth = state.firebase.auth;
  const targetDeal = GetEmptyDeal();

  return {
    auth,
    targetDeal,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  saveDeal,
  getDealById,
  getDealByUrl,
  getUserRoles,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDealManagementContainer);
