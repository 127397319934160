import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import TranslateIcon from "@material-ui/icons/Translate";
import MoreIcon from "@material-ui/icons/MoreVert";
import BackToTop from "./BackToTop";
import Button from "@material-ui/core/Button";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import SideMenuComponent from "./SideMenuComponent";
import { useTranslation } from "react-i18next";
import { languages } from "../../helpers/refData";
import { connect } from "react-redux";
import HomeIcon from "@material-ui/icons/Home";
import { GetTextFromEmail } from "../../helpers/modelsHelper";
import SearchInput from "../deal/search/SearchInputServer";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  headerClass: {
    backgroundColor: "#293847",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export function Header({ auth, ...props }) {
  const classes = useStyles();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const { i18n } = useTranslation();
  const userSavedLng = window.localStorage.getItem("i18nextLng");
  const userSavedLngIndex = languages
    .map((lng) => lng.key)
    .indexOf(userSavedLng);

  const [selectedIndex, setSelectedIndex] = React.useState(userSavedLngIndex);
  const { t } = useTranslation(["metatags"]);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  let history = useHistory();
  useEffect(() => {
    
    i18n.changeLanguage(userSavedLng);
  }, [location]);

  const handlelocalizationMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    window.localStorage.setItem("i18nextLng", lng);
    handleMobileMenuClose();
  };

  const handleMenuItemClick = (event, index, item) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    changeLanguage(item.key);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {languages.map((item, index) => (
        <MenuItem
          key={item.key}
          selected={index === selectedIndex}
          onClick={(event) => handleMenuItemClick(event, index, item)}
        >
          {item.value}
        </MenuItem>
      ))}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Button
          edge="end"
          aria-label="Localization of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          color="inherit"
          onClick={() => {
            history.push("/");
            handleMobileMenuClose();
          }}
          startIcon={<HomeIcon />}
        >
          Home page
        </Button>
      </MenuItem>
      {auth.uid && (
        <MenuItem>
          <Button
            edge="end"
            aria-label="Localization of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            color="inherit"
            onClick={() => {
              history.push("/deal/my");
              handleMobileMenuClose();
            }}
            startIcon={<PermIdentityIcon />}
          >
            {auth.displayName ? auth.displayName : GetTextFromEmail(auth.email)}
          </Button>
        </MenuItem>
      )}

      <MenuItem onClick={handlelocalizationMenuOpen}>
        <Button
          edge="end"
          aria-label="Localization of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handlelocalizationMenuOpen}
          color="inherit"
          startIcon={<TranslateIcon />}
          endIcon={<KeyboardArrowDownIcon />}
        >
          {languages[selectedIndex].value}
        </Button>
      </MenuItem>
    </Menu>
  );

  const backtotopanchor = useRef(null);

  return (
    <>
      <div className={classes.grow}>
        <AppBar
          className={classes.headerClass}
          position="static"
          ref={backtotopanchor}
        >
          <Toolbar>
            <SideMenuComponent />
            <Typography className={classes.title} variant="h6" noWrap>
            {process.env.REACT_APP_ENV === "production" ? t("SITE_NAME") : process.env.REACT_APP_NAME}
            </Typography>

            <SearchInput />

            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <Button
                edge="end"
                aria-label="Localization of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handlelocalizationMenuOpen}
                color="inherit"
                startIcon={<TranslateIcon />}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {languages[selectedIndex].value}
              </Button>
              {auth.uid && (
                <Button
                  edge="end"
                  aria-label=" user"
                  aria-controls={menuId}
                  color="inherit"
                  onClick={() => history.push("/deal/my")}
                  startIcon={<PermIdentityIcon />}
                >
                  {auth.displayName
                    ? auth.displayName
                    : GetTextFromEmail(auth.email)}
                </Button>
              )}
              <Button
                edge="end"
                aria-label=" user"
                aria-controls={menuId}
                color="inherit"
                onClick={() => {
                  history.push("/");
                }}
                startIcon={<HomeIcon />}
              />
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </div>
      <BackToTop backtotopanchor={backtotopanchor} />
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.firebase.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps)(Header);
