import { ReactTinyLink } from "react-tiny-link";

export default function MetaScrapperComponent({ url, onDataChange, ...prop }) {
  function handleOnSuccess(data) {
    if (data.url) {
      onDataChange(data);
    } else {
      onDataChange(false);
    }
  }
  function handleOnError(error) {
    onDataChange(false);
  }

  return (
    <ReactTinyLink
      cardSize="small"
      showGraphic={true}
      maxLine={2}
      minLine={1}
      url={url}
      loadSecureUrl={true}
      onSuccess={(data) => handleOnSuccess(data)}
      handleOnError={(error) => handleOnError(error)}
      proxyUrl="https://my-deal-proxy.herokuapp.com"
    />
  );
}
