import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import MerchantInfo from "./MerchantInfo"

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 200,
    // margin: theme.spacing(1),
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    marginRight: "auto",
  },
  avatar: {
    backgroundColor: grey[500],
  },
  button: {
    marginLeft: "auto",
    backgroundColor: "#2bb04a",
    color: "white",
    "&:hover": {
      background: "#44C260",
    },
  },
  paper: {
    padding: "1%",
    margin: "1%",
  },
  label: {
    // Aligns the content of the button vertically.
    flexDirection: "column",
    fontSize: "0.75rem",
  },
}));

export function MerchantDetails({ auth, currentMerchant,...props}) {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);
  const [saved, setSaved] = useState(false);
  const [merchant, setMerchant] = useState(currentMerchant);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  useEffect(() => {


  }, [auth]);


  return (
    <Card >

     
      <CardContent>
        <MerchantInfo merchant={merchant} />

      </CardContent>

    </Card>
  );
}

MerchantDetails.propTypes = {};

function mapStateToProps(state) {
  let auth = state.firebase.auth;
  return {
    auth,
  };
}

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(MerchantDetails);
