import { combineReducers } from "redux";
import dealReducer from "./dealReducer";
import userAccountData from "./userAccountReducer";
import authReducer from "./authReducer";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import apiCallsInProgress from "./apiStatusReducer";
import commentsMap from "./commentReducer";
import filteredDealsObj from "./searchReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  userAccountData,
  dealReducer ,
  commentsMap,
  filteredDealsObj,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  apiCallsInProgress,
});

export default rootReducer;
