import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
//import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import "./helpers/i18n";
import { rrfProps, store,firebaseApp } from "../src/api/config/firebase.config";
import { isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth)) {return <div></div>;}

  const analytics = firebaseApp.analytics();
  if(auth.uid && process.env.REACT_APP_USERS_ID_DO_NOT_TRACK.indexOf(auth.uid)>-1){
     console.warn("_do_not_track_")
     analytics.setAnalyticsCollectionEnabled(false);
  }else{
    analytics.setAnalyticsCollectionEnabled(true);
    console.warn("_do_track_")

  }
  if(auth.uid){
    analytics.setUserId(auth.uid)
  } 
  return children;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AuthIsLoaded>
            <App />
          </AuthIsLoaded>
        </BrowserRouter>
      </ThemeProvider>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
const configuration = {
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      if (
        window.alert("New version available!  refresh to update your app?")
      ) {
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
        window.location.reload();
      }
    }
  },
};

serviceWorkerRegistration.register(configuration);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
