import { useEffect, useState } from "react";
import MerchantAvatar from "./MerchantAvatar";
import ContactComponent from "../../common/ContactComponent";
import { GetVerifiedMerchants } from "../../../helpers/modelsHelper";
import Carousel from "react-material-ui-carousel";
import Grid from "@material-ui/core/Grid";

export default function MerchantListContainer() {
  const [merchants, setMerchants] = useState(null);

  useEffect(() => {
    if (merchants === null) {
      const shuffled = GetVerifiedMerchants().sort(() => 0.5 - Math.random());
      let selected = shuffled.slice(0, 10);
      setMerchants(selected);
    }
  }, []);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Carousel
          indicators={false}
          navButtonsAlwaysInvisible={true}
          stopAutoPlayOnHover={true}
          fullHeightHover={true}
          autoPlay={true}
        >
          {merchants
            ? merchants.map((merchant, index) => (
                <Grid item xs={12} sm={12} md={12} lg={12} key={merchant.id}>
                  <MerchantAvatar merchant={merchant} key={merchant.id} />
                </Grid>
              ))
            : null}
        </Carousel>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ContactComponent
            titleKey={"LBL_JOIN_US"}
            msgKey={"LBL_JOIN_US_MSG"}
            emailKey={"LBL_JOIN_US_EMIAL"}
          />
        </Grid>
      </Grid>
    </>
  );
}
