import React from "react";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import PropTypes from "prop-types";
import {
  GetDiscountPercentage,
  GetShippingValue,
  ConvertDateToDateString,
  guidGenerator,
} from "../../../helpers/modelsHelper";
import Link from "@material-ui/core/Link";
import { useTranslation } from "react-i18next";
import Image from "material-ui-image";
import { DropzoneDialog } from "material-ui-dropzone";
import Button from "@material-ui/core/Button";
import Resizer from "react-image-file-resizer";
import { uploadImageToUserImageFolder } from "../../../redux/actions/storageActions";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  oldPrice: {
    textDecoration: "line-through",
    color: grey[600],
  },
  newPrice: {
    color: "#d50000",
    fontWeight: "bold",
  },
  discount: {
    color: grey[600],
  },
  updateImg: {
    width: "100%",
    height: "100%",
  },
}));

function AdminDealPreviewInfo({
  currentDeal,
  auth,
  uploadImageToUserImageFolder,
  onImgUrlChange,
  ...props
}) {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);
  const [open, setOpen] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);
  const [imgId, setImgId] = useState(guidGenerator());
  useEffect(() => {
    if (currentDeal.dealImgUrls && currentDeal.dealImgUrls.length > 0) {
      setImgUrl(currentDeal.dealImgUrls[0]);
    }
  }, [currentDeal]);

  function calbackFn(fireBaseUrl) {
    setImgUrl(fireBaseUrl);
    setOpen(false);
    setSubmit(false);
    onImgUrlChange(fireBaseUrl);
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={4} sm={4} md={4}>
          <Grid item>{imgUrl ? <Image src={imgUrl} /> : null}</Grid>
          <Grid item>
            <Button
              className={classes.updateImg}
              variant="outlined"
              color="primary"
              onClick={() => setOpen(true)}
              disabled={currentDeal.dealUrl === "" ? true : false}
            >
              update image
            </Button>

            <DropzoneDialog
              acceptedFiles={["image/*"]}
              cancelButtonText={"cancel"}
              submitButtonText={
                submit ? "Pealse wait, submiting ..." : "submit"
              }
              maxFileSize={3000000}
              open={open}
              onClose={() => setOpen(false)}
              onSave={(files) => {
                setSubmit(true);
                try {
                  Resizer.imageFileResizer(
                    files[0],
                    150,
                    150,
                    "JPEG",
                    100,
                    0,
                    (newFile) => {
                      uploadImageToUserImageFolder(
                        auth.uid,
                        newFile,
                        imgId,
                        calbackFn
                      );
                    },
                    "file",
                    100,
                    100
                  );
                } catch (err) {}
              }}
              showPreviews={false}
              showFileNamesInPreview={false}
              showPreviewsInDropzone={true}
              filesLimit={1}
            />
          </Grid>
        </Grid>

        <Grid item xs={8} sm={8} md={8}>
          <Grid item container direction="column" spacing={2}>
            <Grid item>
              <strong> {currentDeal.title} </strong>
              <Typography variant="body2" color="textSecondary">
                {currentDeal.createAt === undefined
                  ? null
                  : ConvertDateToDateString(currentDeal.createAt)}
              </Typography>
              <Typography variant="subtitle1">
                <span className={classes.oldPrice}>
                  {currentDeal.oldPrice} {t(currentDeal.dealCurrency)}
                </span>
                <span> | </span>
                <span className={classes.newPrice}>
                  {currentDeal.newPrice} {t(currentDeal.dealCurrency)}
                </span>
                <span> | </span>
                <span>
                  {GetDiscountPercentage(
                    currentDeal.oldPrice,
                    currentDeal.newPrice
                  )}
                </span>
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                style={{ cursor: "pointer" }}
              >
                {t("LBL_DEALS_BY")} :
                <Link
                  href={currentDeal.merchantBaseUrl ? null : "#"}
                  onClick={(event) => event.preventDefault()}
                >
                  {currentDeal.merchantName}
                </Link>
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {t("LBL_MEANS_OF_PAYMENT")} :
                {currentDeal.paymentMethod.map((item) => t(item)).join(" | ")}
              </Typography>
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
                variant="body2"
                color="textSecondary"
              >
                <span
                  style={{
                    paddingRight: "5px",
                  }}
                >
                  {GetShippingValue(currentDeal, t)}
                </span>
                <LocalShippingIcon />
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                nbe reported Users :{" "}
                {currentDeal.reporterUsers
                  ? currentDeal.reporterUsers.length
                  : 0}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                nbe followers Users :{" "}
                {currentDeal.followerUsers
                  ? currentDeal.followerUsers.length
                  : 0}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                temperature : {currentDeal.vote}
              </Typography>
              <Link
                href={
                  currentDeal.fbPostId
                    ? "https://www.facebook.com/" + currentDeal.fbPostId
                    : "#"
                }
                onClick={(event) => {
                  event.preventDefault();
                  window.open(
                    "https://www.facebook.com/" + currentDeal.fbPostId
                  );
                }}
              >
                {currentDeal.fbPostId
                  ? "https://www.facebook.com/" + currentDeal.fbPostId
                  : null}
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

function mapStateToProps(state) {
  let auth = state.firebase.auth;

  return {
    auth,
  };
}

const mapDispatchToProps = {
  uploadImageToUserImageFolder,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDealPreviewInfo);
