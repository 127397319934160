import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { useTranslation } from "react-i18next";
import PageTagsComponent from "../common/PageTagsComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "2%",
  },
  container: {
    justifyContent: "center",
    display: "flex",
  },
}));

export default function PrivacyPage() {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);
  return (
    <Container className={classes.container}>
      <PageTagsComponent title={"PRIVACY_POLICY"} />
      <Card className={classes.root}>
        <CardHeader
          title="Politique de confidentialité de TopFlop"
          subheader={
            "Cette politique de confidentialité a été modifiée pour la dernière fois le jeudi 18 novembre 2021"
          }
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            <strong> TopFlop </strong> ("nous", "nous" ou "notre") exploite{" "}
            <a href="http://topflop.tn">http://topflop.tn </a>
            (le "Site"). Cette page vous informe de nos politiques concernant la
            collecte, l'utilisation et la divulgation des informations
            personnelles que nous recevons des utilisateurs du site. Nous
            utilisons vos informations personnelles uniquement pour fournir et
            améliorer le site. En utilisant le Site, vous acceptez la collecte
            et l'utilisation des informations conformément à cette politique.
            Sauf indication contraire dans la présente Politique de
            confidentialité, les termes utilisés dans cette Politique de
            confidentialité ont la même signification que dans nos Termes et
            Conditions, accessibles sur{" "}
            <a href="http://topflop.tn">http://topflop.tn </a>.<br />
            <br />
            <strong> Collection d'informations et utilisation</strong> En
            utilisant notre site, nous pouvons vous demander de nous fournir
            certaines informations personnelles permettant de vous contacter ou
            de vous identifier. Les informations personnellement identifiables
            peuvent inclure, mais sans s'y limiter, votre nom, votre adresse
            e-mail, votre adresse postale et votre numéro de téléphone
            («Informations personnelles»). <br />
            <br />
            <strong>Log Data </strong>Comme de nombreux opérateurs de sites,
            nous collectons les informations que votre navigateur envoie lorsque
            vous visitez notre site ("Données de journal"). Ces données de
            journal peuvent inclure des informations telles que l'adresse IP de
            votre ordinateur, le type de navigateur, la version du navigateur,
            les pages de notre site visité, l'heure et la date de votre visite,
            le temps passé sur ces pages et d'autres statistiques. <br />
            <br />
            <strong>Cookies</strong> Les cookies sont des fichiers avec une
            petite quantité de données, pouvant inclure un identifiant unique
            anonyme. Les cookies sont envoyés à votre navigateur à partir d'un
            site Web et stockés sur le disque dur de votre ordinateur. Comme de
            nombreux sites, nous utilisons des «cookies» pour collecter des
            informations. Vous pouvez demander à votre navigateur de refuser
            tous les cookies ou d'indiquer quand un cookie est envoyé.
            Cependant, si vous n'acceptez pas les cookies, vous ne pourrez
            peut-être pas utiliser certaines parties de notre site. <br />
            <br />
            <strong>DoubleClick</strong>
            Cookie Google, en tant que fournisseur tiers, utilise des cookies
            pour diffuser des annonces sur notre site. L'utilisation du cookie
            DoubleClick par Google lui permet, ainsi qu'à ses partenaires, de
            diffuser des annonces à nos utilisateurs en fonction de leur visite
            sur notre site ou d'autres sites sur Internet. Vous pouvez
            désactiver l'utilisation du cookie DoubleClick pour la publicité
            ciblée par centres d'intérêt en accédant aux{" "}
            <a href="http://www.google.com/ads/preferences/">
              annonces Google. Paramètres page
            </a>
            . <br />
            <br />
            <strong>Do Not Track</strong> Nous prenons en charge le paramètre de
            navigateur Do Not Track. Do Not Track (ou DNT) est une préférence
            que vous pouvez définir dans votre navigateur pour que les sites Web
            que vous visitez sachent que vous ne souhaitez pas qu'ils collectent
            certaines informations à votre sujet. Pour plus de détails sur Ne
            pas suivre, y compris comment activer ou désactiver cette
            préférence, consultez{" "}
            <a href="http://termsfeed.com/do-not-track">
              {" "}
              cette page Web Ne pas suivre .
            </a>{" "}
            <strong> TopFlop </strong> utilise le remarketing avec Google
            Analytics pour vous faire de la publicité sur les sites de tiers
            après votre visite sur notre site. Nous et nos fournisseurs tiers,
            comme Google, utilisons des cookies internes (tels que le cookie
            Google Analytics) et des cookies tiers (tels que le cookie
            DoubleClick) pour informer, optimiser et diffuser des annonces en
            fonction de vos visites passées sur notre site. Vous pouvez
            désactiver Google Analytics pour la publicité display et
            personnaliser les annonces du Réseau Display de Google en accédant à
            la{" "}
            <a href="http://www.google.com/settings/ads">
              {" "}
              Google. Page Paramètres des annonces .{" "}
            </a>
            Nous vous recommandons également d'installer le module
            complémentaire de navigateur de désactivation de Google Analytics
            pour votre navigateur.{" "}
            <a href="https://tools.google.com/dlpage/gaoptout">
              Le module complémentaire de navigateur de désactivation de Google
              Analytics{" "}
            </a>{" "}
            permet aux visiteurs d'empêcher la collecte et l'utilisation de
            leurs données par Google Analytics. <br />
            <br />
            <strong> Communications </strong> Nous pouvons utiliser vos
            informations personnelles pour vous contacter avec des bulletins
            d'information, du matériel de marketing ou de promotion et d'autres
            informations susceptibles de vous intéresser. Vous pouvez refuser de
            recevoir tout ou partie de ces communications en suivant les
            instructions de désabonnement fournies dans les e-mails que nous
            envoyons. <br />
            <br />
            <strong> En accord avec les lois TopFlop </strong> divulguera vos
            renseignements personnels si requis par la loi ou une assignation ou
            si nous croyons qu'une telle action est nécessaire pour se conformer
            à la loi et aux demandes raisonnables d'application de la loi ou
            pour protéger la sécurité ou l'intégrité de notre site. <br />
            <br />
            <strong>Transaction d'affaires</strong> Si{" "}
            <strong> TopFlop </strong> est impliqué dans une fusion, une
            acquisition ou une vente d'actifs, vos informations personnelles
            peuvent être transférées. Nous fournirons un avis avant que vos
            renseignements personnels ne soient transférés et deviennent soumis
            à une politique de confidentialité différente. <br />
            <br />
            <strong> Sécurité</strong> La sécurité de vos informations
            personnelles est importante pour nous, mais rappelez-vous qu'aucune
            méthode de transmission sur Internet, ou méthode de stockage
            électronique, n'est sécurisée à 100%. Bien que nous nous efforcions
            d'utiliser des moyens commercialement acceptables pour protéger vos
            informations personnelles, nous ne pouvons pas garantir leur
            sécurité absolue. <br />
            <br />
            <strong> Liens vers d'autres sites </strong>Notre site peut contenir
            des liens vers d'autres sites qui ne sont pas exploités par nous. Si
            vous cliquez sur un lien d'un tiers, vous serez redirigé vers le
            site de ce tiers. Nous vous conseillons fortement de consulter la
            politique de confidentialité de chaque site que vous visitez.{" "}
            <strong> TopFlop </strong> n'a aucun contrôle sur, et n'assume
            aucune responsabilité pour
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
}
