import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {loadDealsProp} from "../../../redux/actions/dealActions"
import LoaderComponent from "../../common/LoaderComponent";
import {DealOrderBy} from "../../../helpers/models"
import HorizontalScrollingContainer from "../../common/horizontalscrolling/HorizontalScrollingContainer";
import Paper from "@material-ui/core/Paper";
const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  }
}));
export function DealsPropContainer({currentDeal,loadDealsProp, ...props}) {
  const [deals, setDeals] = useState([]);
  const { t } = useTranslation(["translation"]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  useEffect(() => {
    if(currentDeal && currentDeal.id !==''){
    loadDealsProp(currentDeal.dealGroupKey[0],DealOrderBy.SAME_GROUP).then((_deals) => {
       setDeals(_deals);
       setLoading(false);
    }).catch((err) => {
      setLoading(false)
    });   
   }
  }, [currentDeal]);

  return (
    <>
     <Paper  className={classes.container}>
            
     <Typography variant="subtitle2" display="block" gutterBottom>{t("PROP_DEAL_MSG")}</Typography>

                {loading ? (
                  <LoaderComponent />
                ) : (
                  <HorizontalScrollingContainer itemsToDisplay={deals}/>         
                )}
     </Paper>
      
      </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
  };
}

const mapDispatchToProps = {loadDealsProp};
export default connect(mapStateToProps, mapDispatchToProps)(DealsPropContainer);