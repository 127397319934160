import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { dealGroups } from "../../helpers/refData";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { GetGroupKeysFromGroupUrl,GetOrderByKeysFromUrl } from "../../helpers/modelsHelper";
import { loadDeals } from "../../redux/actions/dealActions";
import Button from "@material-ui/core/Button";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  groupBar: {
    backgroundColor: "#334859",
  },
  tabs: {
    "& .MuiTab-root": {
      textTransform: "unset",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiTab-root": {
        fontSize: "10px",
        padding: "6px 6px",
      },
      "& .MuiTab-labelIcon": {
        paddingTop: "0px",
      },
    },
  },  
  button: {
    color: "white",
    textTransform: "unset",
  }
}));

function TabGroups({
  loadDeals,
  loading,
  ...props
}) {
  const classes = useStyles();
  let { group , orderby} = useParams();
  const [value, setValue] = React.useState(group);
  const { t } = useTranslation(["dealgroups"]);
  let history = useHistory();

  useEffect(() => {
    let groupKey = GetGroupKeysFromGroupUrl(group);
    let orderbyKey = GetOrderByKeysFromUrl(orderby);
    setValue(group)
    loadDeals(groupKey,orderbyKey.replace("SORT_","")).catch((error) => {});
  }, [group,orderby]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push("/deals/"+newValue+"/"+orderby);  
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.groupBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          //indicatorColor={{ background: "red" }}
          inkbarstyle={{ background: "red" }}
          textColor="inherit"
          aria-label="scrollable"
          className={classes.tabs}
        >
          {dealGroups.map((item, index) => (
            <Tab
              value={item.url}
              key={item.key + "_" + item.url}
              label={TabButton(item.icon,t(item.key))}
            //  label={t(item.key)}
            //  icon={item.icon}
              {...a11yProps(index)}
              disabled={!item.isActive}
            />
          ))}
        </Tabs>
      </AppBar>
    </div>
  );
}

function TabButton (icon,label) {
  const classes = useStyles();
  return (<Button className={classes.button}  startIcon={icon} fullWidth>
    {label}
  </Button>)
};

TabGroups.propTypes = {
  loading: PropTypes.bool.isRequired,
  loadDeals: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  loadDeals,
};

export default connect(mapStateToProps, mapDispatchToProps)(TabGroups);
