export const LOAD_DEALS_SUCCESS = "LOAD_DEALS_SUCCESS";
export const LOAD_USER_DEALS_SUCCESS = "LOAD_USER_DEALS_SUCCESS";
export const LOAD_USER_SAVED_DEALS_SUCCESS = "LOAD_USER_SAVED_DEALS_SUCCESS";
export const UPDATE_USER_DEALS_SUCCESS = "UPDATE_USER_DEALS_SUCCESS";
export const ADD_USER_DEALS_SUCCESS = "ADD_USER_DEALS_SUCCESS";

export const LOAD_DEAL_SUCCESS = "LOAD_DEAL_SUCCESS";
export const CREATE_DEAL_SUCCESS = "CREATE_DEAL_SUCCESS";
export const UPDATE_DEAL_SUCCESS = "UPDATE_DEAL_SUCCESS";

export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";

export const SORT_NEW_DEALS = "SORT_NEW_DEALS";
export const SORT_HOT_DEALS = "SORT_HOT_DEALS";
export const SORT_COLD_DEALS = "SORT_COLD_DEALS";
export const SORT_DEFAULT_DEALS = "SORT_DEFAULT";
export const SORT_CREATE_AT_DEALS = "SORT_CREATE_AT";
export const SORT_PLUS_HOT_DEALS = "SORT_PLUS_HOT";
export const SORT_FOR_YOU_DEALS = "SORT_FOR_YOU";
export const SORT_SAME_GROUP_DEALS = "SORT_SAME_GROUP";
export const SORT_WITHOUT_VOTE_DEALS = "SORT_WITHOUT_VOTE";



export const USER_SIGN_IN_SUCCESS = "USER_SIGN_IN_SUCCESS";
export const USER_SIGN_IN_FAILER = "USER_SIGN_IN_FAILER";

export const USER_SIGN_OUT_SUCCESS = "USER_SIGN_OUT_SUCCESS";
export const USER_SIGN_OUT_FAILER = "USER_SIGN_OUT_FAILER";

export const USER_SIGN_UP_SUCCESS = "USER_SIGN_UP_SUCCESS";
export const USER_SIGN_UP_FAILER = "USER_SIGN_UP_FAILER";

export const LOAD_COMMENTS_SUCCESS = "LOAD_COMMENTS_SUCCESS";
export const LOAD_COMMENT_SUCCESS = "LOAD_COMMENT_SUCCESS";
export const CREATE_COMMENT_SUCCESS = "CREATE_COMMENT_SUCCESS";
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";

export const UPDATE_DEAL_VOTE_OK = "UPDATE_DEAL_VOTE_OK";
export const UPDATE_DEAL_VOTE_KO = "UPDATE_DEAL_VOTE_KO";

export const UPDATE_DEAL_REPORTED_USER_OK = "UPDATE_DEAL_REPORTED_USER_OK";
export const UPDATE_DEAL_REPORTED_USER_KO = "UPDATE_DEAL_REPORTED_USER_KO";

export const FOLLOW_DEAL_BY_USER_OK = "FOLLOW_DEAL_BY_USER_OK";
export const FOLLOW_DEAL_BY_USER_KO = "FOLLOW_DEAL_BY_USER_KO";
export const UNFOLLOW_DEAL_BY_USER_OK = "UNFOLLOW_DEAL_BY_USER_OK";

export const DELETE_DEAL_BY_OWNER_OK = "DELETE_DEAL_BY_OWNER_OK";
export const DELETE_DEAL_BY_OWNER_KO = "DELETE_DEAL_BY_OWNER_KO";
export const DELETE_MY_DEAL_OK = "DELETE_MY_DEAL_OK";
export const DELETE_MY_DEAL_KO = "DELETE_MY_DEAL_KO";

export const SEARCH_DEAL_OK = "SEARCH_DEAL_OK";
export const FILTER_GROUP_DEAL_OK = "FILTER_GROUP_DEAL_OK";
export const LOAD_FILTER_GROUP_DEAL_OK = "LOAD_FILTER_GROUP_DEAL_OK";
export const UPDATE_FILTER_GROUP_DEAL_OK = "UPDATE_FILTER_GROUP_DEAL_OK";
