import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

export default function CopyrightComponent() {
  const { t } = useTranslation(["translation"]);
  return (
    <>
      <Typography variant="body2" color="textSecondary" align="center">
        {t("LBL_COPYRIGHT")}
        <Link color="inherit" href="/">
          {t("LBL_YOUR_WEBSITE")}
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
        <br />
        <Link color="inherit" href="/support/privacy">
          {t("LBL_CONFIDENTIALITY")} {"  |  "}
        </Link>
        <Link color="inherit" href="/support/terms">
          {t("LBL_CONDITION")}
        </Link>
      </Typography>
    </>
  );
}
