import * as adminFbApi from "../../api/adminApi";
import * as adminApi from "../../core/api/adminApi";
import { FireStoreCollections } from "../../api/config/firebase.config";
import * as dealFirebaseApi from "../../api/dealApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function getDealsByFilter(dealStatus, startDate, endDate, dealId) {
  return function () {
    return adminApi
      .getDealsByFilter(dealStatus, startDate, endDate, dealId)
      .then((_deals) => {
        return _deals;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getUserRoles() {
  return function (dispatch, getState, { getFirebase, getFirestore }) {
    const firestore = getFirestore();
    const state = getState();
    return adminFbApi
      .getUser(firestore, state.firebase.auth.uid)
      .then((user) => {
        return user.roles;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function purgeDeal(targetDeal) {
  return function (dispatch, getState, { getFirebase, getFirestore }) {

    return adminApi.deleteDealFromDb(targetDeal.id).then(() => {
    adminApi.deleteDealFromCacheDb(targetDeal.id)
    for (let index = 0; index < targetDeal.dealImgUrls.length; index++) {
      const element = targetDeal.dealImgUrls[index];
      if (element.startsWith(process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_URL)) {
        let _url = new URL(element, process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_URL)
        let _path = element.replace(process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_URL, "").replace(_url.search, "")
        adminApi.deleteDealImg(decodeURIComponent(_path))
      }
      
    }
    })
    .catch((error) => {
      throw error;
    });



  };
}

export function updateDealWithFbPost(dealId) {
  return function (dispatch) {
    return adminApi
      .updateDealWithFbPost(dealId)
      .then((postId) => {
        return postId;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
