import {
  DealModel,
  DealModelMinified,
  MerchantIds,
  DealGroup,
  CommentModel,
  VoteLevel,
  DealCurrency,
  UserModel,
  DealStatus,
  MerchantModel,
  MeansOfPayment
} from "./models";
import {
  dealGroups,
  dealPaymentMethod,
  merchantsList,
  languages,
  dealFilters
} from "./refData";
import i18n from "./i18n";

export function GetEmptyDeal(): DealModel {
  let dealModel = new DealModel();
  let expireAt = new Date();
  expireAt.setMonth(expireAt.getMonth() + 2);
  return {
    ...dealModel,
    expireAt: expireAt,
  };
}

export function GetEmptyUser(): UserModel {
  return new UserModel();
}

export function GetEmptyMerchant(): MerchantModel {
  let merchant = new MerchantModel()
  return {
    ...merchant,
    id:MerchantIds.MY_TEK_TN,
    address: {
      "@type": "PostalAddress",
      streetAddress: "rue de l'industrie, Tunis 2035, Tunisie",
      addressLocality: "Tunis",
      addressRegion: "Charguia",
      postalCode: "2035",
      addressCountry: "TUN"
    },
    name:"MyTek",
    geo :  {
      latitude: 36.8350675,
      longitude: 10.2025541,
    },
    description : "MyTek 🛒 un distributeur de matériel informatique, smartphone, électroménager, TV | image & son, gaming, impression, bureautique, mode | beauté & santé, moto | sport et loisirs, maison & animalerie, bébé, meuble & décoration.",
    url: "https://www.mytek.tn/",
    logoUrl :"https://mk-media.mytek.tn/media/logo/stores/1/LOGO-MYTEK-176PX-INVERSE.png",
    imgsUrl:[],
    socialMediaUrls:["https://www.facebook.com/Mytek.tn","https://twitter.com/Mytek.tn","https://www.instagram.com/Mytek.tunisie/"],
    meansOfPayment:[MeansOfPayment.CASH,MeansOfPayment.OTHER,MeansOfPayment.CB],
    aggregateRating : {
      "@type": "AggregateRating",
      ratingValue: "4",
      bestRating: "5",
      ratingCount: "1"
    }
  };
}



export function GetGoogleSchemaMarkup(merchantModel:MerchantModel){

  let data = {
    "@context": "https://schema.org/",
    "@type": "LocalBusiness",
    "name": "MyTek",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4",
      "bestRating": "5",
      "reviewCount": "1"    
     },
     "address": {
      "@type": "PostalAddress",
      "streetAddress": "rue de l'industrie, Tunis 2035, Tunisie",
      "addressLocality": "Tunis",
      "addressRegion": "Charguia",
      "postalCode": "2035",
      "addressCountry": "TUN"
    },
    "image": "https://mk-media.mytek.tn/media/logo/stores/1/LOGO-MYTEK-176PX-INVERSE.png",
    "telephone":"00216 36 010 010"}
  return JSON.stringify(data)  ;
}
export function GetEmptyComment(auth: any): CommentModel {
  if (auth && auth.uid && auth.email)
    return new CommentModel(
      auth.uid,
      auth.displayName ? auth.displayName : GetTextFromEmail(auth.email)
    );
  else return new CommentModel("", "");
}

export function ToDealModelMinified(DealModel: any): DealModelMinified {
  let dealToReturn = new DealModelMinified();

  for (let [key, value] of Object.entries(DealModel)) {
    if (dealToReturn.hasOwnProperty(key)) {
      dealToReturn = { ...dealToReturn, [key]: value };
    }
  }

  return dealToReturn;
}

export function isFloat(val: any) {
  var floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
  if (!floatRegex.test(val)) return false;

  val = parseFloat(val);
  if (isNaN(val)) return false;
  return true;
}

export function stringToFloat(val: string) {
  var floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
  if (!floatRegex.test(val)) return 0;

  let value = parseFloat(val);
  if (isNaN(value)) return 0;
  return value;
}

export function MapPaymentDataFromDeal(deal: DealModel) {
  return dealPaymentMethod.map((item: any) =>
    deal.paymentMethod.includes(item.key)
      ? { ...item, isActive: true }
      : { ...item, isActive: false }
  );
}

export function ToJsDate(value: string) {
  let dateString = new Date(Number(value));
  return (
    dateString.getDate() +
    "/" +
    (dateString.getMonth() + 1) +
    "/" +
    dateString.getFullYear()
  );
}

export function MapGroupsDataFromDeal(deal: any) {
  return dealGroups
    .filter((item) => item.key !== DealGroup.DEAL_ADS)
    .map((item) =>
      deal.dealGroupKey.includes(item.key)
        ? { ...item, isActive: true }
        : { ...item, isActive: false }
    );
}

export function GetGroupKeysFromGroupUrl(groupUrl: string) {
  let groupKey = dealGroups
    .filter((item) => item.url === groupUrl)
    .map((item) => item.key);
  if (groupKey !== null && groupKey.length > 0) {
    return groupKey[0];
  }
}

export function GetOrderByKeysFromUrl(orderBy: string) {
 
  let orderByKey = dealFilters
    .filter((item) => item.orderby === orderBy)
    .map((item) => item.key);

  if (orderByKey !== null && orderByKey.length > 0) {
    return orderByKey[0];
  }else{
     return "SORT_FOR_YOU"
  }
}

export function GetGroupUrlFromGroupKey(groupKey: string) {
  let groupUrl = dealGroups
    .filter((item) => item.key === groupKey)
    .map((item) => item.url);
  if (groupUrl !== null && groupUrl.length > 0) {
    return groupUrl[0];
  }
}

export function IsValidUrl(url: string) {
  let exp =
    "^(?!mailto:)(?:(?:http|https)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$";
  let regex = new RegExp(exp);
  return url.match(regex);
}

export function IsValidYoutubeUrl(url: string) {
  let exp = "^(https?://)?(www.)?(youtube.com|youtu.?be)/.+$";
  let regex = new RegExp(exp);
  return url.match(regex);
}

export function IsValidFacebookUrl(url: string) {
  let exp = "^(https?://)?(www.)?(facebook.com|fb.watch)/.+$";
  let regex = new RegExp(exp);
  return url.match(regex);
}

export function IsValidVideoUrl(url: string) {
  return IsValidYoutubeUrl(url) || IsValidFacebookUrl(url);
}

export function IsValidEmail(email: string) {
  let exp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let regex = new RegExp(exp);
  return email.match(regex);
}

export function IsValidPassword(email: string) {
  let exp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
  let regex = new RegExp(exp);
  return email.match(regex);
}

export function GetDiscountPercentage(oldPrice: any, newPrice: any): string {
  let oldPriceValue = 0;
  let newPriceValue = 0;
  if (!isFloat(oldPrice) || !isFloat(oldPrice)) return "";
  oldPriceValue = parseFloat(oldPrice);
  newPriceValue = parseFloat(newPrice);

  if (newPriceValue === 0 || oldPriceValue === 0) return "";
  if (oldPriceValue === newPriceValue) return "";
  let discount =
    (Math.abs(oldPriceValue - newPriceValue) * 100) / oldPriceValue;
  let prefix = " -";
  if (newPriceValue >= oldPriceValue) {
    prefix = " +";
  }
  return prefix + discount.toFixed(2) + " %";
}

export function GetDateNow(lng: string): string {
  let date = new Date();
  return date.toLocaleString(lng, {
    weekday: "short",
    year: "numeric",
    month: "2-digit",
    day: "numeric",
  });
}

export function GetMerchantFromUrl(url: string): any {
  if (!IsValidUrl(url))
    return merchantsList.find(
      (item) => item.merchantId === MerchantIds.OTHER && item.isActive
    );

  let targetMerchant = merchantsList.find(
    (item) => url.includes(item.merchantName) && item.isActive
  );
  if (targetMerchant) return targetMerchant;
  else
    return merchantsList.find(
      (item) => item.merchantId === MerchantIds.OTHER && item.isActive
    );
}

export function GetDealImgUrl(deal: DealModel): string {
  if (!deal.dealImgUrls) {
    return "/img/merchants/logo/" + MerchantIds.OTHER + ".png";
  }
  if (deal.dealImgUrls.length === 0 && deal.merchantId) {
    return "/img/merchants/logo/" + deal.merchantId + ".png";
  }
  if (deal.dealImgUrls.length === 0 && !deal.merchantId) {
    return "/img/merchants/logo/" + MerchantIds.OTHER + ".png";
  }

  return deal.dealImgUrls[0];
}

export function GenerateDealGroupTags(deal: DealModel): DealModel {
  let dealGroupKey = deal.dealGroupKey[0];
  let dealGroupTags = [];

  for (let index = 0; index < languages.length; index++) {
    let value = i18n.getResource(
      languages[index].key,
      "dealgroups",
      dealGroupKey
    );
    if (value) {
      dealGroupTags.push(value.toLowerCase());
    }
  }

  let dealToReturn = { ...deal, dealGroupTags };
  return dealToReturn;
}

export function GenerateGroupKeywords(groupKey: string): string {
  let keys = [];

  for (let index = 0; index < languages.length; index++) {
    let value = i18n.getResource(languages[index].key, "dealgroups", groupKey);
    if (value) {
      keys.push(value.toLowerCase());
    }
  }

  return keys.join(",");
}

export function GenerateDealTitleTags(deal: DealModel): DealModel {
  let title = SansAccent(deal.title);
  title = title
    .replace(/[^a-zA-Z0-9]/g, " ")
    .trim()
    .replace(/\s+/g, " ");

  let dealToReturn = {
    ...deal,
    titleTags: title
      .toLowerCase()
      .split(" ")
      .filter((item) => item.length >= 3),
  };
  return dealToReturn;
}

export function GenerateTags(deal: DealModel): DealModel {
  let dealToReturn = GenerateDealGroupTags(deal);
  dealToReturn = GenerateDealTitleTags(dealToReturn);
  return dealToReturn;
}

export function SetDealOwner(deal: DealModel, auth: any): any {
  if (!auth) return null;
  let dealToReturn = {
    ...deal,
    ownerId: auth.uid,
    ownerInitials:
      auth.displayName && auth.displayName.length > 0
        ? auth.displayName[0]
        : GetTextFromEmail(auth.email)[0],
    ownerPseudo:
      auth.displayName && auth.displayName.length > 0
        ? auth.displayName
        : GetTextFromEmail(auth.email),
  };
  return dealToReturn;
}

export function GetVerifiedMerchants(): any {
  let merchantList = [];
  for (let merchantId of Object.values(MerchantIds)) {
    if (MerchantIds.OTHER !== merchantId) {
      let merchant = {
        id: merchantId,
        logo: "/img/merchants/logo/" + merchantId + ".png",
      };
      merchantList.push(merchant);
    }
  }

  return merchantList;
}

function SansAccent(str: string): string {
  var accent = [
    /[\300-\306]/g,
    /[\340-\346]/g, // A, a
    /[\310-\313]/g,
    /[\350-\353]/g, // E, e
    /[\314-\317]/g,
    /[\354-\357]/g, // I, i
    /[\322-\330]/g,
    /[\362-\370]/g, // O, o
    /[\331-\334]/g,
    /[\371-\374]/g, // U, u
    /[\321]/g,
    /[\361]/g, // N, n
    /[\307]/g,
    /[\347]/g, // C, c
  ];
  var noaccent = [
    "A",
    "a",
    "E",
    "e",
    "I",
    "i",
    "O",
    "o",
    "U",
    "u",
    "N",
    "n",
    "C",
    "c",
  ];

  for (var i = 0; i < accent.length; i++) {
    str = str.replace(accent[i], noaccent[i]);
  }

  return str;
}

export function ConvertDateToDateString(date: any, lng: string): string {
  return convertDDMMYYYToDate(date).toLocaleDateString("fr");
}

export function convertDDMMYYYToDate(date: any) {
  if (date && (typeof date === "string" || date instanceof String)) {
    date = date.replaceAll(".", "/");
    const [day, month, year] = date.split("/");
    return new Date(year, month - 1, day);
  }

  return date;
}

export function FromApiDealToDealModel(deal: any): any {
  return {
    ...deal,
    createAt: convertDDMMYYYToDate(deal.createAt),
    updateAt: convertDDMMYYYToDate(deal.updateAt),
    expireAt: convertDDMMYYYToDate(deal.expireAt),
    startAt: convertDDMMYYYToDate(deal.startAt),
  };
}

export function FromDealModelToApiDeal(deal: any): any {
  return {
    ...deal,
    createAt: deal.createAt
      ? new Date(deal.createAt).toLocaleDateString("fr")
      : new Date().toLocaleDateString("fr"),
    updateAt: deal.updateAt
      ? new Date(deal.updateAt).toLocaleDateString("fr")
      : new Date().toLocaleDateString("fr"),
    expireAt: deal.expireAt
      ? new Date(deal.expireAt).toLocaleDateString("fr")
      : new Date().toLocaleDateString("fr"),
    startAt: deal.startAt
      ? new Date(deal.startAt).toLocaleDateString("fr")
      : new Date().toLocaleDateString("fr"),
  };
}

export function FromFirebaseDealToDealModel(deal: any): any {
  return {
    ...deal,
    createAt: deal.createAt.toDate(),
    updateAt: deal.updateAt.toDate(),
    expireAt: deal.expireAt.toDate(),
    startAt: deal.startAt.toDate(),
  };
}

export function GetDealByIdFromDeals(deals: any, id: any) {
  return deals.find((deal: { id: any }) => deal.id === id) || null;
}

export function GetTextFromEmail(email: string): string {
  return email.substring(0, email.indexOf("@"));
}

export function GetInitialsFromEmail(email: string): string {
  return email.substring(0, 1);
}

export function GetDealById(
  dealId: string,
  deals : any
): any {

    if (deals) {
      let filtredDeals = deals.filter((item:any) => item.id === dealId);
      if (filtredDeals && filtredDeals.length > 0) {
        return filtredDeals[0];
      }
    }
  
  return GetEmptyDeal();
}

export function GetShippingValue(deal: any, translate: any) {
  let shippingFees = stringToFloat(deal.shippingFees);

  //if (shippingFees === 0) return translate("LBL_SHIPPING_IS_FREE");

  if (deal.isFreeShipping === true && shippingFees === 0) {
    return translate("LBL_SHIPPING_IS_FREE");
  }

  if (deal.isFreeShipping === false && shippingFees === 0) {
    return translate("LBL_UNAVAILABLE_INFO");
  }

  if (deal.isFreeShipping === false || shippingFees === 0) {
    return shippingFees + translate(deal.dealCurrency);
  }

  return shippingFees + translate(deal.dealCurrency);
}

export function ProcessVote(deal: any, userId: string, voteValue: number) {
  let dealToReturn = { ...deal };
  if (!dealToReturn.positiveVoteUsers) dealToReturn.positiveVoteUsers = [];
  if (!dealToReturn.negativeVoteUsers) dealToReturn.negativeVoteUsers = [];

  if (voteValue === 1) {
    dealToReturn.positiveVoteUsers = [
      ...dealToReturn.positiveVoteUsers,
      userId,
    ];
  }

  if (voteValue === -1) {
    dealToReturn.negativeVoteUsers = [
      ...dealToReturn.negativeVoteUsers,
      userId,
    ];
  }

  dealToReturn = {
    ...dealToReturn,
    voteLevel: GetVoteLevel(deal.vote + voteValue),
    vote: deal.vote + voteValue,
  };

  return dealToReturn;
}

export function GetVoteLevel(voteValue: number) {
  if (voteValue === 0) return VoteLevel.NEUTRAL;
  if (voteValue <= -50) return VoteLevel.VERY_COLD;
  if (voteValue < 0) return VoteLevel.COLD;
  if (voteValue >= 50) return VoteLevel.VERY_HOT;
  if (voteValue > 0) return VoteLevel.HOT;
}

export function GetVoteColor(voteValue: number) {
  if (voteValue === 0) return { color: "#000000" };
  if (voteValue <= -50) return { color: "#00BFFF" };
  if (voteValue < 0) return { color: "#B0E0E6" };
  if (voteValue >= 50) return { color: "#d50000" };
  if (voteValue > 0) return { color: "#ff7f00" };
}

export function GetActiveItems(array: any[]) {
  if (array) return array.filter((item, index) => item.isActive === true);
  else return [];
}

export function GetCurrencyList() {
  return [
    { id: DealCurrency.TND, title: "TND" },
    { id: DealCurrency.EUR, title: "€" },
    { id: DealCurrency.USD, title: "$" },
  ];
}

export function GetDealStatusList() {
  return [
    { id: DealStatus.PENDING, title: "PENDING" },
    { id: DealStatus.DRAFT, title: "DRAFT" },
    { id: DealStatus.ACTIVE, title: "ACTIVE" },
    { id: DealStatus.VERIFIED, title: "VERIFIED" },
    { id: DealStatus.BANNED, title: "BANNED" },
    { id: DealStatus.EXPIRED, title: "EXPIRED" },
  ];
}

export function SaveDealInlocalStorage(userId: string, dealId: string) {
  let dataFromLocalstorage = localStorage.getItem(userId);
  if (dataFromLocalstorage !== null) {
    if (dataFromLocalstorage.indexOf(dealId) === -1) {
      dataFromLocalstorage = dataFromLocalstorage + "#" + dealId + "#";
    }
  } else {
    dataFromLocalstorage = "#" + dealId + "#";
  }
  localStorage.setItem(userId, dataFromLocalstorage);
}

export function UnSaveDealFromlocalStorage(userId: string, dealId: string) {
  let dataFromLocalstorage = localStorage.getItem(userId);
  if (dataFromLocalstorage !== null) {
    if (dataFromLocalstorage.indexOf(dealId) !== -1) {
      dataFromLocalstorage = dataFromLocalstorage.replace(
        "#" + dealId + "#",
        ""
      );
    }
  } else {
    dataFromLocalstorage = "";
  }

  localStorage.setItem(userId, dataFromLocalstorage);
}

export function IsDealSaved(userId: string, dealId: string): Boolean {
  let dataFromLocalstorage = localStorage.getItem(userId);
  if (dataFromLocalstorage !== null) {
    if (dataFromLocalstorage.indexOf(dealId) !== -1) {
      return true;
    }
  }
  return false;
}

export function ProcessReportDeal(deal: any, userId: string) {
  let dealToReturn = { ...deal };
  if (!dealToReturn.reporterUsers) dealToReturn.reporterUsers = [];
  if (dealToReturn.reporterUsers.includes(userId) === false) {
    dealToReturn.reporterUsers = [...dealToReturn.reporterUsers, userId];
  }

  return dealToReturn;
}

export function ProcessUnFollowDeal(deal: any, userId: string) {
  let dealToReturn = { ...deal };
  if (!dealToReturn.followerUsers) dealToReturn.followerUsers = [];

  if (dealToReturn.followerUsers.includes(userId) === true) {
    dealToReturn.followerUsers = dealToReturn.followerUsers.filter(
      (id: any) => id !== userId
    );
  }

  return dealToReturn;
}

export function ProcessFollowDeal(deal: any, userId: string) {
  let dealToReturn = { ...deal };
  if (!dealToReturn.followerUsers) dealToReturn.followerUsers = [];
  if (dealToReturn.followerUsers.includes(userId) === false) {
    dealToReturn.followerUsers = [...dealToReturn.followerUsers, userId];
  }

  return dealToReturn;
}

export function guidGenerator() {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
}

export function SetDealStatusForNewDeal(targetdeal: any, isAdmin: any) {
  let dealToReturn = { ...targetdeal };
  if (isAdmin) {
    return {
      ...dealToReturn,
      status: DealStatus.ACTIVE,
    };
  }

  if (dealToReturn.merchantId === MerchantIds.OTHER) {
    dealToReturn = {
      ...dealToReturn,
      status: DealStatus.PENDING,
    };
  } else {
    dealToReturn = {
      ...dealToReturn,
      status: DealStatus.ACTIVE,
    };
  }

  let storageUrl = dealToReturn.dealImgUrls.filter(
    (item: any) => item.indexOf("firebasestorage") !== -1
  );

  if (storageUrl.length > 0) {
    dealToReturn = {
      ...dealToReturn,
      status: DealStatus.PENDING,
    };
  }

  return dealToReturn;
}

export function SetDealStatusForExitingDeal(
  dealAfterUpdate: any,
  isImgUpdated: any,
  isAdmin: any
) {
  let dealToReturn = { ...dealAfterUpdate };

  if (isAdmin) {
    return {
      ...dealToReturn,
      status: DealStatus.ACTIVE,
    };
  }

  if (dealToReturn.merchantId === MerchantIds.OTHER) {
    dealToReturn = {
      ...dealToReturn,
      status: DealStatus.PENDING,
    };
  } else {
    dealToReturn = {
      ...dealToReturn,
      status: DealStatus.ACTIVE,
    };
  }

  if (isImgUpdated) {
    let dealAfterUpdateStorage = dealAfterUpdate.dealImgUrls.filter(
      (item: any) => item.indexOf("firebasestorage") !== -1
    );

    if (dealAfterUpdateStorage.length === 0) {
      return dealToReturn;
    } else {
      dealToReturn = {
        ...dealToReturn,
        status: DealStatus.PENDING,
      };
    }
  }

  return dealToReturn;
}
