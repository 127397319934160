import "./App.css";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import NotFoundPage from "./components/common/NotFoundPage";
import ErrorPage from "./components/common/ErrorPage";
import UnauthorizedPage from "./components/common/UnauthorizedPage";
import PrivacyPage from "./components/common/PrivacyPage";
import CookiesPage from "./components/common/CookiesPage";
import TermsPage from "./components/common/TermsPage";
import SearchResultContainer from "./components/deal/search/SearchResultContainer";
import DealsContainer from "./components/deal/display/DealsContainer";
import DealDetailContainer from "./components/deal/detail/DealDetailContainer";
import { Switch, Route, Redirect } from "react-router-dom";
import SignUp from "./components/authentication/SignUp";
import SignInSide from "./components/authentication/SignInSide";
import ForgotPassword from "./components/authentication/ForgotPassword";
import UserDealContainer from "./components/user/Deals/UserDealContainer";
import UserSavedDealContainer from "./components/user/Deals/UserSavedDealContainer";
import DealManagementContainer from "./components/user/Deals/Add/DealManagementContainer";
import AuthGuardIsConnected from "./components/security/AuthGuardIsConnected";
import { connect } from "react-redux";
import AdminDashboardContainer from "./components/admin/AdminDashboardContainer";
import PageTagsComponent from "./components/common/PageTagsComponent";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/common/ErrorFallback";
import LandingPage from "./components/common/LandingPage";
import MerchantContainer from "./components/merchant/MerchantContainer";

function App({ auth, ...props }) {
  return (
    <>
      <PageTagsComponent
        title={"GENERAL_TITLE"}
        description={"GENERAL_DESCRIPTION"}
        keywords={"SITE_URL"}
      />
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => console.log("onReset ErrorBoundary")}
      >
        <Header />
        <Switch>
          <Route
            path="/deal/:group/details/:id"
            component={DealDetailContainer}
          />

          <AuthGuardIsConnected
            path="/admin/deals/dashboard"
            component={AdminDashboardContainer}
            auth={auth}
          />

          <AuthGuardIsConnected
            path="/deal/management/:id"
            component={DealManagementContainer}
            auth={auth}
          />

          <AuthGuardIsConnected
            path="/deal/management"
            component={DealManagementContainer}
            auth={auth}
          />
          <AuthGuardIsConnected
            path="/deal/saved"
            component={UserSavedDealContainer}
            auth={auth}
          />
          <AuthGuardIsConnected
            path="/deal/my"
            component={UserDealContainer}
            auth={auth}
          />

          <Route  path="/merchant-details/:merchant" component={MerchantContainer} />
          <Route  path="/search/deals" component={SearchResultContainer} />
          <Route  path="/deals/:group/:orderby?" component={DealsContainer} />

          <Route path="/Error" component={ErrorPage} />
          <Route path="/Unauthorized" component={UnauthorizedPage} />
          <Route path="/sign-in" component={SignInSide} />
          <Route path="/sign-up" component={SignUp} />
          <Route path="/forget-pwd" component={ForgotPassword} />
          <Route path="/support/privacy" component={PrivacyPage} />
          <Route path="/support/terms" component={TermsPage} />
          <Route path="/support/cookies" component={CookiesPage} />
          <Route path="/NotFound" component={NotFoundPage} />
          <Route exact path="/" component={LandingPage} />
          <Route component={NotFoundPage} />
        </Switch>
        <Footer />
      </ErrorBoundary>
    </>
  );
}

App.propTypes = {};

function mapStateToProps(state) {
  let auth = state.firebase.auth;
  return {
    auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(App);
