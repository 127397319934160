import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Controls from "../../components/common/controls/Controls";
import { connect } from "react-redux";
import { signUp } from "../../redux/actions/authActions";
import PropTypes from "prop-types";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import {
  IsValidEmail,
  IsValidPassword,
  GetEmptyUser,
} from "../../helpers/modelsHelper";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useHistory } from "react-router-dom";
import SnackbarComponent from "../../components/common/SnackbarComponent";
import AvatarComponent from "../../components/common/AvatarComponent";
import { createAvatar } from "@dicebear/avatars";
import * as style from "@dicebear/avatars-identicon-sprites";
import { useTranslation } from "react-i18next";
import LoaderComponent from "../../components/common/LoaderComponent";
import CopyrightComponent from "../../components/common/CopyrightComponent";
import PageTagsComponent from "../../components/common/PageTagsComponent";
import { Redirect } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/img/sign-up.svg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "80%",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    height: "20%",
    width: "20%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export function SignUp({ auth, loading, authError, signUp, ...props }) {
  const { t } = useTranslation(["translation"]);
  const classes = useStyles();
  const [newUser, setNewUser] = useState(GetEmptyUser());
  const [userAvatar, setUserAvatar] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [signingUp, setSigningUp] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    message: authError,
    open: authError ? true : false,
    severity: "error",
  });
  let history = useHistory();

  useEffect(() => {
    setSnackbarData({
      severity: authError ? "error" : "success",
      message: authError,
      open: authError ? true : false,
    });
    createUserAvatar(newUser.pseudo);
  }, [authError, loading]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formIsValid()) return;
    setSigningUp(true);
    signUp(newUser)
      .then(() => {
        //window.location.reload();
      })
      .catch((err) => {
        setSigningUp(false);
      });
  };

  const handleInputFormChange = (event) => {
    const { name, value } = event.target;
    if (name === "pseudo") {
      createUserAvatar(value);
    }
    setNewUser((newUser) => ({
      ...newUser,
      [name]: name === "receiveUpdates" ? event.target.checked : value,
    }));
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleInputValidationOnBlur = (event) => {
    let _errors = {};
    switch (event.target.name) {
      case "email":
        newUser.email = newUser.email.trim();
        if (!newUser.email) _errors.email = t("LBL_REQUIRED");
        else if (!IsValidEmail(newUser.email)) {
          _errors.email = t("LBL_FORMAT_INCORRECT");
        }
        break;
      case "password":
        newUser.password = newUser.password.trim();
        if (!newUser.password) _errors.password = t("LBL_REQUIRED");
        else if (!IsValidPassword(newUser.password)) {
          _errors.password = t("LBL_PWD_FORMAT_INCORRECT");
        }
        break;
      case "pseudo":
        newUser.pseudo = newUser.pseudo.trim();
        if (!newUser.pseudo) _errors.pseudo = t("LBL_REQUIRED");
        break;
      default:
        _errors[event.target.name] = PropIsRequired(event.target.name);
        break;
    }
    setErrors({ ...errors, [event.target.name]: _errors[event.target.name] });
  };

  const PropIsRequired = (propName) => {
    if (!newUser[propName]) return t("LBL_REQUIRED");
  };

  const formIsValid = () => {
    let _errors = {};

    newUser.email = newUser.email.trim();
    if (!newUser.email) _errors.email = t("LBL_REQUIRED");
    else if (!IsValidEmail(newUser.email)) {
      _errors.email = t("LBL_FORMAT_INCORRECT");
    }

    newUser.password = newUser.password.trim();
    if (!newUser.password) _errors.password = t("LBL_REQUIRED");
    else if (!IsValidPassword(newUser.password)) {
      _errors.password = t("LBL_PWD_FORMAT_INCORRECT");
    }

    newUser.pseudo = newUser.pseudo.trim();
    if (!newUser.pseudo) _errors.pseudo = t("LBL_REQUIRED");
    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  };

  function SnackbarComponentChange() {
    setSnackbarData({ ...snackbarData, message: null, open: false });
  }

  function createUserAvatar(value) {
    let svg = createAvatar(style, {
      seed: value,
    });
    setUserAvatar(svg);
  }

  if (auth && auth.uid) return <Redirect to="/" />;

  return (
    <Grid container component="main" className={classes.root}>
      <PageTagsComponent title={"SIGN_UP"} />
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <AvatarComponent svgValue={userAvatar} />
          <Typography component="h1" variant="h4">
            {t("SIGN_UP")}
          </Typography>
          <Typography component="h5" variant="subtitle1" align="center">
            {t("ACCEPT_TERMS")}
          </Typography>
          <form
            className={classes.form}
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controls.GenericTextInput
                  variant="outlined"
                  fullWidth
                  label={t("LBL_PSEUDO")}
                  name="pseudo"
                  value={newUser.pseudo}
                  onChange={handleInputFormChange}
                  error={errors.pseudo}
                  onBlur={handleInputValidationOnBlur}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Controls.GenericTextInput
                  variant="outlined"
                  fullWidth
                  label={t("LBL_EMAIL")}
                  name="email"
                  value={newUser.email}
                  onChange={handleInputFormChange}
                  error={errors.email}
                  onBlur={handleInputValidationOnBlur}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    {t("LBL_PASSWORD")}
                  </InputLabel>
                  <OutlinedInput
                    type={showPassword ? "text" : "password"}
                    value={newUser.password}
                    name="password"
                    fullWidth
                    onChange={handleInputFormChange}
                    onBlur={handleInputValidationOnBlur}
                    error={errors.password ? true : false}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                  {!!errors.password && (
                    <FormHelperText error id="accountId-error">
                      {errors.password}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="receiveUpdates"
                      value={newUser.receiveUpdates}
                      onChange={handleInputFormChange}
                      error={errors.receiveUpdates}
                      color="primary"
                    />
                  }
                  label={t("LBL_RECEIVE_UPDATES")}
                />
              </Grid>
            </Grid>
            <Button
              disabled={signingUp}
              text={signingUp ? t("SIGN_UP") + "..." : t("SIGN_UP")}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {t("SIGN_UP")}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/sign-in" variant="body2">
                  {t("LBL_ALREADY_HAVE_ACCOUNT")} {t("SIGN_IN")}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <CopyrightComponent />
              {signingUp ? <LoaderComponent /> : null}
            </Box>
          </form>
        </div>
      </Grid>
      <SnackbarComponent
        message={snackbarData.message}
        open={snackbarData.open}
        severity={snackbarData.severity}
        onClose={SnackbarComponentChange}
      />
    </Grid>
  );
}

SignUp.propTypes = {
  loading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.firebase.auth,
    loading: state.apiCallsInProgress > 0,
    authError: state.auth ? state.auth.authError : null,
  };
}

const mapDispatchToProps = {
  signUp,
};
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
