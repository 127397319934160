import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Button from "@material-ui/core/Button";
import DealDetailInfo from "./DealDetailInfo";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import DealVoteHorizontal from "../vote/DealVoteHorizontal";
import {
  ConvertDateToDateString,
  ProcessReportDeal,
  ProcessFollowDeal,
  ProcessUnFollowDeal,
} from "../../../helpers/modelsHelper";
import { useTranslation } from "react-i18next";
import Paper from "@material-ui/core/Paper";
import ScheduleIcon from "@material-ui/icons/Schedule";
import AlarmOffIcon from "@material-ui/icons/AlarmOff";
import LinkIcon from "@material-ui/icons/Link";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import { connect } from "react-redux";
import SnackbarComponent from "../../common/SnackbarComponent";
import ConfirmDialog from "../../common/ConfirmDialog";
import SocialMediaComponent from "../../common/socialmedia/SocialMediaComponent";
import WarningIcon from "@material-ui/icons/Warning";
import {
  reportDeal,
  followDeal,
  unFollowDeal,
} from "../../../redux/actions/dealActions";
import {
  EmitReferenceEvent,
  EmitCustomEvent,
  SeoReferenceEvents,
  SeoCustomEvents,
  ConvertDealToAnalyticItemArray,
} from "../../../helpers/seoHelper";
import {
  BuildUtmUrl
} from "../../../helpers/utmHelper";
import { useParams } from 'react-router';
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 200,
    // margin: theme.spacing(1),
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    marginRight: "auto",
  },
  avatar: {
    backgroundColor: grey[500],
  },
  button: {
    marginLeft: "auto",
    backgroundColor: "#2bb04a",
    color: "white",
    "&:hover": {
      background: "#44C260",
    },
  },
  paper: {
    padding: "1%",
    margin: "1%",
  },
  label: {
    // Aligns the content of the button vertically.
    flexDirection: "column",
    fontSize: "0.75rem",
  },
}));

export function DealDetail({
  deal,
  onDataChange,
  auth,
  reportDeal,
  followDeal,
  unFollowDeal,
  ...props
}) {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);
  const [saved, setSaved] = useState(false);
  const [reportedDeal, setReportedDeal] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    open: false,
    severity: "error",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const { group  } = useParams();
  useEffect(() => {
    if (auth.uid && deal.reporterUsers) {
      setReportedDeal(
        deal.reporterUsers.includes(auth.uid) === false ? null : true
      );
    }

    if (auth.uid && deal.followerUsers) {
      setSaved(deal.followerUsers.includes(auth.uid) === false ? null : true);
    }
  }, [deal]);

  const handleClick = (event) => {
    EmitCustomEvent(SeoCustomEvents.view_item_url, {
      currency: deal.dealCurrency.toString(),
      value: Number(deal.newPrice.toString()),
      items: ConvertDealToAnalyticItemArray(deal),
    });
    let _urlToOpen = BuildUtmUrl(deal.dealUrl, group , "deal-details")
    window.open(_urlToOpen);
  };

  const handleSaveDealClick = (event, savedValue) => {
    if (auth.uid) {
      let dealToSave = {};
      let fn = {};
      if (savedValue) {
        dealToSave = { ...ProcessUnFollowDeal(deal, auth.uid) };
        EmitCustomEvent(SeoCustomEvents.remove_from_wishlist, {
          currency: dealToSave.dealCurrency.toString(),
          value: Number(dealToSave.newPrice.toString()),
          items: ConvertDealToAnalyticItemArray(dealToSave),
        });
        fn = unFollowDeal;
      } else {
        dealToSave = { ...ProcessFollowDeal(deal, auth.uid) };
        EmitReferenceEvent(SeoReferenceEvents.add_to_wishlist, {
          currency: dealToSave.dealCurrency.toString(),
          value: Number(dealToSave.newPrice.toString()),
          items: ConvertDealToAnalyticItemArray(dealToSave),
        });
        fn = followDeal;
      }

      fn(auth.uid, dealToSave)
        .then((_deal) => {
          setSaved(!savedValue);
          setSnackbarData({
            ...snackbarData,
            message: t("SUCCESS_MSG"),
            open: true,
            severity: "success",
          });
        })
        .catch((error) => {
          setSnackbarData({
            ...snackbarData,
            message: t("ERROR_MSG"),
            open: true,
            severity: "error",
          });
        });
    } else {
      setSnackbarData({
        ...snackbarData,
        message: t("CONNECTED_USER_REQUIRED"),
        open: true,
        severity: "info",
      });
    }
  };

  const handleCopyLinkClick = (event) => {
    //navigator.clipboard.writeText(window.location.href);
    let _urlToOpen = BuildUtmUrl(deal.dealUrl, group , "deal-details")
    navigator.clipboard.writeText(_urlToOpen)
    setSnackbarData({
      ...snackbarData,
      message: t("DEAL_URL_COPIED"),
      open: true,
      severity: "success",
    });
    EmitCustomEvent(SeoCustomEvents.copy_item_url, {
      currency: deal.dealCurrency.toString(),
      value: Number(deal.newPrice.toString()),
      items: ConvertDealToAnalyticItemArray(deal),
    });
  };

  const handleReportDealClick = (event) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    if (auth.uid) {
      if (reportedDeal) return;
      let dealToSave = { ...ProcessReportDeal(deal, auth.uid) };
      EmitCustomEvent(SeoCustomEvents.report_item, {
        currency: dealToSave.dealCurrency.toString(),
        value: Number(dealToSave.newPrice.toString()),
        items: ConvertDealToAnalyticItemArray(dealToSave),
      });
      reportDeal(auth.uid, dealToSave)
        .then((_deal) => {
          setReportedDeal(true);
          setSnackbarData({
            ...snackbarData,
            message: t("DEAL_REPORTED_OK"),
            open: true,
            severity: "success",
          });
        })
        .catch((error) => {
          setSnackbarData({
            ...snackbarData,
            message: t("DEAL_REPORTED_KO"),
            open: true,
            severity: "error",
          });
        });
    } else {
      setSnackbarData({
        ...snackbarData,
        message: t("CONNECTED_USER_REQUIRED"),
        open: true,
        severity: "info",
      });
    }
  };

  function SnackbarComponentChange() {
    setSnackbarData({ ...snackbarData, message: null, open: false });
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {deal.ownerInitials}
          </Avatar>
        }
        action={
          <DealVoteHorizontal currentDeal={deal} onDataChange={onDataChange} />
        }
        title={deal.ownerPseudo == null ? "" : deal.ownerPseudo}
        subheader={ConvertDateToDateString(deal.createAt)}
      />

      <DealDetailInfo deal={deal} />
      <CardContent>
        <div>
          <SocialMediaComponent />
        </div>

        <Paper variant="outlined" className={classes.paper}>
          <Typography
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
            variant="body2"
            color="textSecondary"
          >
            <ScheduleIcon />
            <span style={{ paddingLeft: "5px" }}>
              {t("DEAL_START_AT")}{" "}
              {deal.startAt
                ? ConvertDateToDateString(deal.startAt)
                : t("LBL_UNAVAILABLE_INFO")}
            </span>
          </Typography>
        </Paper>
        <Paper variant="outlined" className={classes.paper}>
          <Typography
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
            variant="body2"
            color="textSecondary"
          >
            <AlarmOffIcon />
            <span style={{ paddingLeft: "5px" }}>
              {t("DEAL_END_AT")}{" "}
              {deal.expireAt
                ? ConvertDateToDateString(deal.expireAt)
                : t("LBL_UNAVAILABLE_INFO")}
            </span>
          </Typography>
        </Paper>
        <Paper variant="outlined" className={classes.paper}>
          <Typography
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
            variant="body2"
            color="textSecondary"
          >
            <CardGiftcardIcon />
            <span style={{ paddingLeft: "5px" }}>
              {t("DEAL_PROMO_CODE")}{" "}
              {deal.promoCode ? deal.promoCode : t("LBL_UNAVAILABLE_INFO")}
            </span>
          </Typography>
        </Paper>
        <Typography variant="body2" color="textSecondary" component="p">
          {deal.description}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton
          aria-label="add to favorites"
          onClick={(event) => handleSaveDealClick(event, saved)}
          classes={{ label: classes.label }}
        >
          <FavoriteIcon style={{ color: saved ? "red" : "grey" }} />
          {saved ? t("DEAL_ALREADY_SAVED") : t("SAVE_DEAL")}
        </IconButton>
        <IconButton
          aria-label="share"
          onClick={(event) => handleCopyLinkClick(event)}
          classes={{ label: classes.label }}
        >
          <LinkIcon />
          {t("COPY_LINK")}
        </IconButton>
        <IconButton
          aria-label="report"
          style={{ color: reportedDeal ? "orange" : "grey" }}
          onClick={() => {
            setConfirmDialog({
              isOpen: true,
              title: t("REPORT_DEAL_CONFIRM_MSG"),
              subTitle: t("CONFIRM_SUB_TITLE_MSG"),
              onConfirm: () => {
                handleReportDealClick();
              },
            });
          }}
          classes={{ label: classes.label }}
        >
          <WarningIcon />
          {reportedDeal ? t("DEAL_ALREADY_REPORTED") : t("REPORT_DEAL")}
        </IconButton>
      </CardActions>
      <CardActions disableSpacing>
        <Button
          variant="contained"
          className={classes.button}
          endIcon={<ExitToAppIcon />}
          onClick={handleClick}
        >
          {t("BTN_SEE_DEAL")}
        </Button>
      </CardActions>
      <SnackbarComponent
        message={snackbarData.message}
        open={snackbarData.open}
        severity={snackbarData.severity}
        onClose={SnackbarComponentChange}
      />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Card>
  );
}

DealDetail.propTypes = {};

function mapStateToProps(state) {
  let auth = state.firebase.auth;
  return {
    auth,
  };
}

const mapDispatchToProps = {
  reportDeal,
  followDeal,
  unFollowDeal,
};

export default connect(mapStateToProps, mapDispatchToProps)(DealDetail);
