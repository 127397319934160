import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Controls from "../common/controls/Controls";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { GetDealStatusList } from "../../helpers/modelsHelper";
import { Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

export default function AdminDataTableFilter({ onFilterChange, ...props }) {
  const classes = useStyles();

  const [searching, setSearching] = useState(false);
  const { t } = useTranslation(["admin"]);
  const [filter, setFilter] = useState({
    status: "PENDING",
    fromDate: null,
    toDate: null,
    dealId: "",
    nbReportedUsers: "0",
  });

  const [errors, setErrors] = useState({
    status: "",
    fromDate: "",
    toDate: "",
    nbReportedUsers: "",
  });

  const onChange = (event) => {
    setFilter({ ...filter, [event.target.name]: event.target.value });
  };
  const onSubmit = (event) => {
    event.preventDefault();

    onFilterChange(filter);
  };

  return (
    <div>
      <form
        className={classes.form}
        onSubmit={onSubmit}
        noValidate
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Controls.GenericTextInput
              label={t("LBL_DEAL_ID")}
              name="dealId"
              value={filter.dealId}
              onChange={onChange}
              error={errors.dealId}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography>Filter by id OR by status</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controls.GenericSelect
              label={t("LBL_DEAL_STATUS")}
              name="status"
              value={filter.status}
              onChange={onChange}
              options={GetDealStatusList()}
              error={errors.status}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography>AND </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controls.GenericDatePicker
              label={t("ExpireAt <= endDateValue")}
              name="fromDate"
              value={filter.fromDate}
              onChange={onChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={2}>
          <Typography>Union DistinctBy deal ID </Typography>

          </Grid>
          <Grid item xs={12} sm={3}>
            <Controls.GenericDatePicker
              label={t("CreateAt <= startDateValue")}
              name="toDate"
              value={filter.toDate}
              onChange={onChange}
              required
            />
          </Grid>
          {/* <Grid item xs={12} sm={3}>
            <Controls.GenericNumberInput
              label={t("LBL_DEAL_NBE_REPORTED_USERS")}
              name="nbReportedUsers"
              value={filter.nbReportedUsers}
              onChange={onChange}
              error={errors.nbReportedUsers}
              required
            />
          </Grid> */}
          <Grid item xs={12} sm={3}>
            <Controls.GenericButton
              disabled={searching}
              text={searching ? "Searching..." : "Search"}
              type="submit"
              className={classes.submit}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
