import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { Avatar } from "@material-ui/core";
import {
  GetTextFromEmail,
  ToJsDate,
  GetInitialsFromEmail,
} from "../../helpers/modelsHelper";
import AvatarComponent from "../../components/common/AvatarComponent";
import { createAvatar } from "@dicebear/avatars";
import * as style from "@dicebear/avatars-identicon-sprites";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  headerClass: {
    backgroundColor: "#334859",
  },
  avatar: {
    margin: theme.spacing(2),
    //backgroundColor: "#8B4513",
    width: theme.spacing(8),
    height: theme.spacing(8),
    float: "right",
    //border: "white",
    //borderStyle: "solid",
  },
  item: {
    margin: theme.spacing(2),
  },
}));

export function UserProfileHeader({ auth, ...props }) {
  const classes = useStyles();
  const [userAvatar, setUserAvatar] = useState(null);
  useEffect(() => {
    if (userAvatar === null) {
      createUserAvatar(
        auth.displayName
          ? auth.displayName[0]
          : GetInitialsFromEmail(auth.email)
      );
    }
  }, [auth]);
  function createUserAvatar(value) {
    let svg = createAvatar(style, {
      seed: value,
    });
    setUserAvatar(svg);
  }
  return (
    <div className={classes.grow}>
      <AppBar className={classes.headerClass} position="static">
        <Toolbar>
          <Grid container spacing={1}>
            <Grid item sm={4} xs={4}>
              {/* <Avatar className={classes.avatar}>
                {auth.displayName
                  ? auth.displayName[0]
                  : GetInitialsFromEmail(auth.email)}
              </Avatar> */}
              <div className={classes.avatar}>
                <AvatarComponent svgValue={userAvatar} />
              </div>
            </Grid>
            <Grid item sm={8} xs={8}>
              <Grid item container direction="column" spacing={1}>
                {auth ? (
                  <Grid item className={classes.item}>
                    <Typography variant="h6" color="initial">
                      {auth.displayName
                        ? auth.displayName
                        : GetTextFromEmail(auth.email)}
                    </Typography>
                    <Typography variant="body2" color="initial">
                      {auth.email}
                    </Typography>
                    <Typography variant="body2" color="initial">
                      Membre depuis : {ToJsDate(auth.createdAt)}
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.firebase.auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps)(UserProfileHeader);
