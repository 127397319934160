import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  EmitReferenceEvent,
  SeoReferenceEvents,
} from "../../helpers/seoHelper";

function PageTagsComponent({
  title,
  description,
  subDescription,
  keywords,
  auth,
  q = "",
  ...props
}) {
  const { t } = useTranslation(["metatags"]);
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname !== "/" &&
      (title === "GENERAL_TITLE" || title === "")
    )
      return;
    if (auth && auth.uid) {
      EmitReferenceEvent(SeoReferenceEvents.page_view, {
        client_id: auth.uid,
        page_location: location.pathname,
        page_title: t(title),
        language: window.localStorage.getItem("i18nextLng"),
      });
    } else {
      EmitReferenceEvent(SeoReferenceEvents.page_view, {
        page_location: location.pathname,
        page_title: t(title),
        language: window.localStorage.getItem("i18nextLng"),
      });
    }
  }, [title, location.pathname]);
  return (
    <Helmet>
      <title>{t("SITE_NAME") + " - " + t(title) + " " + q} </title>
      <meta
        name="description"
        content={t(description) + " - " + t(subDescription)}
      />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
}

PageTagsComponent.propTypes = {};

function mapStateToProps(state) {
  let auth = state.firebase.auth;
  return {
    auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PageTagsComponent);
