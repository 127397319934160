import * as types from "../actions/actionTypes";

const commentsMap = new Map();

const initialState = {
  commentsMap: commentsMap,
};

export default function dealReducer(state = initialState.commentsMap, action) {
  switch (action.type) {
    case types.LOAD_COMMENTS_SUCCESS:
      if (action.comments && action.comments.length > 0) {
        commentsMap.set(action.dealId, action.comments);
      }
      return commentsMap;
    case types.CREATE_COMMENT_SUCCESS:
      let comments = commentsMap.get(action.dealId);
      if (comments) {
        commentsMap.set(action.dealId, [{ ...action.comment }, ...comments]);
      } else {
        let dealComment = new Array();
        commentsMap.set(action.dealId, [{ ...action.comment }, ...dealComment]);
      }
      return commentsMap;

    case types.UPDATE_COMMENT_SUCCESS:
      let commentsToUpdate = commentsMap
        .get(action.dealId)
        .map((_comment) =>
          _comment.id === action.comment.id ? action.comment : _comment
        );
      if (commentsToUpdate) {
        commentsMap.set(action.dealId, commentsToUpdate);
      }
      return commentsMap;
    default:
      return state;
  }
}
