import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";
import Link from "@material-ui/core/Link";
import { useTranslation } from "react-i18next";
import Image from "material-ui-image";
import RatingComponent from "../../common/RatingComponent"
import SocialMediaIcon from "../../common/socialmedia/SocialMediaIcon"



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
  },
  image: {
    width: 200,
    height: 200,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  oldPrice: {
    textDecoration: "line-through",
    color: grey[600],
  },
  newPrice: {
    color: "#d50000",
    fontWeight: "bold",
  },
  discount: {
    color: grey[600],
  },
}));

export default function MerchantInfo({ merchant, ...props }) {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);

  return (
        <Grid container spacing={3}>
          <Grid item>
            <div className={classes.image}>
              <Image src={merchant.logoUrl} alt={merchant.name} />
            </div>
          </Grid>

            <Grid item xs container direction="column" spacing={2}>

        
                <Typography gutterBottom variant="h5">
                  <strong>{"Codes promo & Bons plans " + merchant.name}</strong>
                </Typography>
                {merchant.description ? 
                   <>
                   <Typography variant="body1" color="textSecondary" > 
                   {merchant.description}                 
                   </Typography>  
                  <Link href={merchant.url} target="_blank" >
                                     {merchant.url}
                   </Link>
                   </>
                          : null}  

            {merchant.meansOfPayment  ? <>
            <Typography variant="h6">
                 <strong>{"Modalités de paiement de  " +merchant.name}</strong>
                </Typography>
                <Typography variant="body1" color="textSecondary">
                {merchant.meansOfPayment.map((item, index) => t(item)).join(" | ")}
                </Typography> </>
                : null}    



         {merchant.socialMediaUrls  ? <>
          <Typography variant="h6">
                <strong>{merchant.name + " sur les réseaux sociaux"}</strong>
          </Typography>
            <Typography variant="body1" color="textSecondary">
                 {`Retrouvez ${merchant.name} sur ses différents réseaux sociaux pour ne pas manquer les annonces importantes, les réductions et les bons plans. \n`}
                   </Typography> 
                   <Typography variant="subtitle1">
                   {
                         merchant.socialMediaUrls.map((item, index) =>  {
                          return (        
                            <>
                                 <SocialMediaIcon key={index+"_sc"} url={item}/>
                                 {"   "}
                            </>       
                          )                          
                          })                  
                   }
                   
                   </Typography> 
              </>
                : null} 

                <Typography variant="h6">
                  <strong>{"Évaluez votre expérience avec " +merchant.name}</strong>
                </Typography>
               
                <RatingComponent ratingValue={merchant.aggregateRating.ratingValue}/>
                <Typography variant="body1" color="textSecondary">
               
                     { merchant.aggregateRating.ratingValue +"/5 étoiles d'après " + merchant.aggregateRating.ratingCount+ " évaluations"}
                 </Typography>

  
            </Grid>
        </Grid>
  );
}
