import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import PropTypes from "prop-types";

export default function GenericDatePicker({
  name,
  label,
  value,
  onChange,
  ...props
}) {
  const convertToDefaultEventParam = (name, value) => ({
    target: {
      name,
      value,
    },
  });
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          //disableToolbar
          // variant="inline"
          fullWidth
          inputVariant="outlined"
          label={label}
          value={value}
          onChange={(date) => onChange(convertToDefaultEventParam(name, date))}
          name={name}
          format="dd/MM/yyyy"
        />
      </MuiPickersUtilsProvider>
    </>
  );
}

GenericDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.string,
};

GenericDatePicker.defaultProps = {
  error: "",
};
