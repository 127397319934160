import axios from "../config/api.config";
import * as config from "../config/api.config";
import {
  handleDealResponse,
  handleDealListResponse,
  handleAdminDealListResponse,
  handleError,
  handleResponse,
} from "../helper/apiUtils";

export async function getDealsByFilter(
  dealStatus: string,
  startDate: string,
  endDate: string,
  dealId: string
) {
  await config.GetUserToken();
  return axios
    .get(config.API_ADMIN_GET_DEAL_BY_FILTERS, {
      params: {
        dealStatus: dealStatus,
        startDate: startDate,
        endDate: endDate,
        dealId: dealId,
      },
    })
    .then(handleDealListResponse)
    .catch(handleError);
}

export async function updateDeal(deal: any) {
  await config.GetUserToken();
  return axios
    .put(config.API_ADMIN_PUT_DEAL, deal)
    .then(handleDealResponse)
    .catch(handleError);
}

export async function updateDealWithFbPost(dealId: string) {
  await config.GetUserToken();
  let url =config.API_ADMIN_PUT_DEAL_FB_POST.replace("${dealId}",dealId);
  return axios
    .put(url, null)
    .then(handleResponse)
    .catch(handleError);
}

export async function deleteDealFromDb(dealId: string) {
  await config.GetUserToken();
  let url =config.API_ADMIN_DELETE_DEAL.replace("${dealId}",dealId);
  return axios
    .delete(url)
    .then(handleResponse)
    .catch(handleError);
}

export async function deleteDealFromCacheDb(dealId: string) {
  await config.GetUserToken();
  let url =config.API_FIREBASE_DELETE_DEAL.replace("${dealId}",dealId);
  return axios
    .delete(url)
    .then(handleResponse)
    .catch(handleError);
}

export async function deleteDealImg(path: string) {
  await config.GetUserToken();
  return axios
    .delete(config.API_FIREBASE_DELETE_DEAL_IMG, {
      params: {
        path: path,
      }})
    .then(handleResponse)
    .catch(handleError);
}
