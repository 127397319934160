import * as types from "./actionTypes";
import * as authFireBase from "../../api/authApi";
import * as authApi from "../../core/api/userApi";

import { beginApiCall, apiCallError } from "./apiStatusActions";
import {
  EmitReferenceEvent,
  SeoReferenceEvents,
} from "../../helpers/seoHelper";

export function loginSuccess() {
  return { type: types.USER_SIGN_IN_SUCCESS };
}

export function loginError(err) {
  return { type: types.USER_SIGN_IN_FAILER, err };
}

export function logoutSuccess() {
  return { type: types.USER_SIGN_OUT_SUCCESS };
}

export function logoutError(err) {
  return { type: types.USER_SIGN_OUT_FAILER, err };
}

export function signUpSuccess() {
  return { type: types.USER_SIGN_UP_SUCCESS };
}

export function signUpError(err) {
  return { type: types.USER_SIGN_UP_FAILER, err };
}

export function signIn(credentials) {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch(beginApiCall());
    const firebase = getFirebase();
    return firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then((_user) => {
        EmitReferenceEvent(SeoReferenceEvents.login);
        dispatch(loginSuccess());
      })
      .catch((err) => {
        dispatch(loginError(err));
        dispatch(apiCallError(err));
        throw err;
      });
  };
}

export function signOut() {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    return firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch(logoutSuccess());
      })
      .catch((err) => {
        dispatch(logoutError(err));
      });
  };
}

export function signUp(newUser) {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch(beginApiCall());
    const firebase = getFirebase();
    const firestore = getFirestore();
    return firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then((response) => {
        return authFireBase
          .addNewUser(firestore, response, newUser)
          .then(() => {
            response.user
              .updateProfile({
                displayName: newUser.pseudo,
              })
              .then(() => {
                EmitReferenceEvent(SeoReferenceEvents.sign_up);
                dispatch(signUpSuccess());

                let userToAdd = {
                  id: response.user.uid,
                  initials: newUser.pseudo
                    ? newUser.pseudo[0]
                    : response.user.email[0],
                  email: response.user.email,
                  pseudo: newUser.pseudo,
                  receiveUpdates: newUser.receiveUpdates,
                };

                authApi.createUser(userToAdd, response.user);
              });
          });
      })
      .catch((err) => {
        dispatch(signUpError(err));
        dispatch(apiCallError(err));
        throw err;
      });
  };
}

export function resetPwdFn(credentials) {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    return firebase.auth().sendPasswordResetEmail(credentials.email);
  };
}
