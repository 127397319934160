import * as types from "./actionTypes";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as dealApi from "../../api/dealApi";
import * as dealsApi from "../../core/api/dealApi";
import * as adminApi from "../../core/api/adminApi";

import { FireStoreCollections } from "../../api/config/firebase.config";
import {
  FromFirebaseDealToDealModel,
  FromDealModelToApiDeal,
} from "../../helpers/modelsHelper";

export function loadDealsSuccess(deals) {
  return { type: types.LOAD_DEALS_SUCCESS,deals : deals};
}

export function loadDealsFailer() {
  return { type: types.LOAD_DEALS_SUCCESS, deals:[] };
}

export function loadUserDealsSuccess(userId, deals) {
  return { type: types.LOAD_USER_DEALS_SUCCESS, userId, deals };
}

export function createUserDealSuccess(deal) {
  return { type: types.ADD_USER_DEALS_SUCCESS, deal };
}

export function updateUserDealSuccess(deal) {
  return { type: types.UPDATE_USER_DEALS_SUCCESS, deal };
}

export function loadDealSuccess(deal) {
  return { type: types.LOAD_DEAL_SUCCESS, deal };
}

export function createDealSuccess( deal) {
  return { type: types.CREATE_DEAL_SUCCESS, deal };
}

export function updateDealSuccess( deal) {
  return { type: types.UPDATE_DEAL_SUCCESS, deal };
}

export function updateDealVoteSuccess(deal) {
  return { type: types.UPDATE_DEAL_VOTE_OK,  deal };
}

export function updateReportSuccess(deal) {
  return { type: types.UPDATE_DEAL_REPORTED_USER_OK, deal };
}

export function followDealSuccess(deal) {
  return { type: types.FOLLOW_DEAL_BY_USER_OK, deal };
}

export function unFollowDealSuccess(deal) {
  return { type: types.UNFOLLOW_DEAL_BY_USER_OK, deal };
}

export function deleteDealSuccess(deal) {
  return { type: types.DELETE_DEAL_BY_OWNER_OK, deal };
}

export function deleteMyDealSuccess(deal) {
  return { type: types.DELETE_MY_DEAL_OK, deal };
}

export function loadUserSavedDealsSuccess(userId, deals) {
  return { type: types.LOAD_USER_SAVED_DEALS_SUCCESS, userId, deals };
}

export function loadDeals(groupKey,orderbyKey) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return dealsApi
      .getDealsByDomainKey(groupKey,orderbyKey)
      .then((deals) => {
        dispatch(loadDealsSuccess(deals));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        dispatch(loadDealsFailer());
        throw error;
      });
  };
}

export function loadDealsProp(groupKey,orderbyKey) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return dealsApi
      .getDealsByDomainKey(groupKey,orderbyKey)
      .then((deals) => {
        return deals
      })
      .catch((error) => {
        dispatch(apiCallError(error));
       
        throw error;
      });
  };
}


export function loadUserDeals(ownerId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return dealsApi
      .getDealsOwner(ownerId)
      .then((deals) => {
        dispatch(loadUserDealsSuccess(ownerId, deals));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadSavedUserDeals(userId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return dealsApi
      .getSavedDeals(userId)
      .then((deals) => {
        dispatch(loadUserSavedDealsSuccess(userId, deals));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function reportDeal(userId, targetDeal) {
  return function (dispatch) {
    return dealsApi
      .reportDeal(targetDeal.id, userId)
      .then(() => {
        dispatch(updateDealSuccess(targetDeal));
        dispatch(updateReportSuccess(targetDeal));
        return targetDeal;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function followDeal(userId, targetDeal) {
  return function (dispatch) {
    return dealsApi
      .followDeal(targetDeal.id, userId)
      .then(() => {
        dispatch(updateDealSuccess(targetDeal));
        dispatch(followDealSuccess(targetDeal));
        return targetDeal;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function unFollowDeal(userId, targetDeal) {
  return function (dispatch) {
    return dealsApi
      .unFollowDeal(targetDeal.id, userId)
      .then(() => {
        dispatch(updateDealSuccess(targetDeal));
        dispatch(unFollowDealSuccess(targetDeal));
        return targetDeal;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function getDealById(dealId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return dealsApi
      .getDealById(dealId)
      .then((deal) => {
        dispatch(loadDealSuccess(deal));
        return deal;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

//-----------------------------------------firebase and api
export function getDealByUrl(dealUrl) {
  return function (dispatch, getState, { getFirebase, getFirestore }) {
    const firestore = getFirestore();
    dispatch(beginApiCall());
    return dealApi
      .getDealByUrl(firestore, dealUrl)
      .then((deal) => {
        dispatch(loadDealSuccess(deal));
        return deal;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
export function voteDeal(targetDeal, userId, vote) {
  return function (dispatch, getState, { getFirebase, getFirestore }) {
    return dealsApi
      .updateDealTemperature(targetDeal.id, userId, vote)
      .then(() => {
        dispatch(updateDealVoteSuccess( targetDeal));
        updateVoteFireStore(getFirestore(), targetDeal);
        return targetDeal;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function saveDeal(targetDeal) {
  return function (dispatch, getState, { getFirebase, getFirestore }) {
    const firestore = getFirestore();
    let { docRef, dealToSave } = GetDealAndDocRef(targetDeal, firestore);

    dispatch(beginApiCall());
    return dealApi
      .saveDeal(docRef, dealToSave)
      .then(() => {
        let dealToReturn = FromFirebaseDealToDealModel(dealToSave);
        dealToReturn = FromDealModelToApiDeal(dealToReturn);

        if (targetDeal.id) {
          dispatch(
            updateDealSuccess(dealToReturn)
          );
          dispatch(updateUserDealSuccess(dealToReturn));
          invokeSaveDealApi(dealToReturn, false);
        } else {
          dispatch(
            createDealSuccess(dealToReturn)
          );
          dispatch(createUserDealSuccess(dealToReturn));
          invokeSaveDealApi(dealToReturn, true);
        }

        return dealToReturn;
      })
      .catch((error) => {
        dispatch(apiCallError(error));

        throw error;
      });
  };
}

function invokeSaveDealApi(targetDeal, isNew) {
  if (isNew) {
    dealsApi.createDeal(targetDeal);
  } else {
    dealsApi.updateDeal(targetDeal);
  }
}

export function deleteMyDeal(targetDeal) {
  return function (dispatch, getState, { getFirebase, getFirestore }) {
    const firestore = getFirestore();

    let docRef = firestore
      .collection(FireStoreCollections.DEALS_COLLECTION)
      .doc(targetDeal.id);

    return dealApi
      .deleteDeal(docRef)
      .then(() => {
        dealsApi
          .deleteDeal(targetDeal.id)
          .then(() => {
            dispatch(deleteDealSuccess(targetDeal));
            dispatch(deleteMyDealSuccess(targetDeal));
          })
          .catch((error) => {
            dispatch(apiCallError(error));
            throw error;
          });
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function saveAdminDeal(targetDeal) {
  return function (dispatch, getState, { getFirebase, getFirestore }) {
    const firestore = getFirestore();
    let { docRef, dealToSave } = GetDealAndDocRef(targetDeal, firestore);
    dispatch(beginApiCall());
    return dealApi
      .saveDeal(docRef, dealToSave)
      .then(() => {
        let dealToReturn = FromFirebaseDealToDealModel(dealToSave);
        dealToReturn = FromDealModelToApiDeal(dealToReturn);
        dispatch(updateDealSuccess(dealToReturn));
        dispatch(updateUserDealSuccess(dealToReturn));
        adminApi.updateDeal(dealToReturn);
        return dealToReturn;
      })
      .catch((error) => {
        console.log(error)
        dispatch(apiCallError(error));

        throw error;
      });
  };
}

//---------------------------------Fire STORE fn
function updateVoteFireStore(firestore, targetDeal) {
  let docRef = firestore
    .collection(FireStoreCollections.DEALS_COLLECTION)
    .doc(targetDeal.id);
  dealApi
    .voteDeal(
      docRef,
      targetDeal.vote,
      targetDeal.voteLevel,
      targetDeal.positiveVoteUsers,
      targetDeal.negativeVoteUsers
    )
    .then(() => {})
    .catch((error) => {});
}

function GetDealAndDocRef(deal, firestore) {
  if (deal.id) {
    let { docRef, dealToSave } = GeneratePropsForExistingDeal(deal, firestore);
    return { docRef, dealToSave };
  } else {
    let { docRef, dealToSave } = GeneratePropsForNewDeal(deal, firestore);
    return { docRef, dealToSave };
  }
}

function GeneratePropsForNewDeal(deal, firestore) {
  let docRef = {};
  let dealToSave = {};

  docRef = firestore.collection(FireStoreCollections.DEALS_COLLECTION).doc();
  dealToSave = {
    ...deal,
    id: docRef.id,
    createAt: firestore.Timestamp.fromDate(new Date()),
    updateAt: firestore.Timestamp.fromDate(new Date()),
    expireAt: deal.expireAt
      ? firestore.Timestamp.fromDate(new Date(deal.expireAt))
      : firestore.Timestamp.fromDate(new Date()),
    startAt: deal.startAt
      ? firestore.Timestamp.fromDate(new Date(deal.startAt))
      : firestore.Timestamp.fromDate(new Date()),
  };

  return { docRef, dealToSave };
}

function GeneratePropsForExistingDeal(deal, firestore) {
  let docRef = {};
  let dealToSave = {};
  docRef = firestore
    .collection(FireStoreCollections.DEALS_COLLECTION)
    .doc(deal.id);
  dealToSave = {
    ...deal,
    createAt: deal.createAt
      ? firestore.Timestamp.fromDate(new Date(deal.createAt))
      : firestore.Timestamp.fromDate(new Date()),
    updateAt: firestore.Timestamp.fromDate(new Date()),
    expireAt: deal.expireAt
      ? firestore.Timestamp.fromDate(new Date(deal.expireAt))
      : firestore.Timestamp.fromDate(new Date()),
    startAt: deal.startAt
      ? firestore.Timestamp.fromDate(new Date(deal.startAt))
      : firestore.Timestamp.fromDate(new Date()),
  };

  return { docRef, dealToSave };
}
