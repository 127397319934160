import ErrorPage from "./ErrorPage";
import { EmitCustomEvent, SeoCustomEvents } from "../../helpers/seoHelper";

export default function ErrorFallback({ error, resetErrorBoundary }) {
  EmitCustomEvent(SeoCustomEvents.render_exception, {
    error,
  });

  return <ErrorPage />;
}
