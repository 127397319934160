import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import PlusOneIcon from "@material-ui/icons/PlusOne";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import MoreIcon from "@material-ui/icons/More";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import { connect } from "react-redux";
import { signOut } from "../../redux/actions/authActions";
import { useTranslation } from "react-i18next";
import { dealGroups } from "../../helpers/refData";
import ListSubheader from '@material-ui/core/ListSubheader';
import { useParams, useHistory } from "react-router-dom";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      //width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    // [theme.breakpoints.up('sm')]: { display: 'none',},
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  toolbarCss: {
    backgroundColor: "#293847",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  img: {
    width: "100%",
    height: "100%",
    padding: "10px",
  },
}));

function SideMenuComponent({ auth, signOut, ...props }) {
  const { window } = props;
  const [userConnected, setUserConnected] = React.useState(false);
  let history = useHistory();
  const { t } = useTranslation(["useraccount"]);
  useEffect(() => {
    setUserConnected(auth.uid ? true : false);
  }, [auth]);

  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClose = () => {
    setMobileOpen(false);
  };

  function handleLogOut() {
    signOut().then(() => {
      document.location.reload();
      setMobileOpen(false);
    });
  }
  const handleGroupClick = (event, newValue) => {
    setMobileOpen(false);
   // history.push(newValue);
  };
  const drawer = (
    <div>
      <div
        className={classes.toolbar}
        style={{
          background: "#293847",
        }}
      >
        <img src="/dark-logo.png" className={classes.img} />
      </div>
      <Divider />
      <List>
        {[
          { text: "HOME_PAGE", icon: <HomeIcon />, to: "/", hidden: false },
          {
            text: "MY_DEALS",
            icon: <ListAltIcon />,
            to: "/deal/my",
            hidden: !userConnected,
          },
          {
            text: "SAVED_DEALS",
            icon: <LoyaltyIcon />,
            to: "/deal/saved",
            hidden: !userConnected,
          },
          {
            text: "ADD_DEAL",
            icon: <MoreIcon />,
            to: "/deal/management",
            hidden: !userConnected,
          },
        ].map((item, index) => {
          return item.hidden === false ? (
            <Link
              to={item.to}
              key={item.text}
              onClick={handleClose}
              style={{ textDecoration: "none", color: "grey" }}
            >
              <ListItem button>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText secondary={t(item.text)} />
              </ListItem>
            </Link>
          ) : null;
        })}
      </List>
      <Divider />
      <List>
        {[
          {
            text: "ACCOUNT_REGISTER",
            icon: <PlusOneIcon />,
            to: "/sign-up",
            onClick: null,
            hidden: userConnected,
          },
          {
            text: "ACCOUNT_SIGN_IN",
            icon: <LockOpenIcon />,
            to: "/sign-in",
            onClick: null,
            hidden: userConnected,
          },
          {
            text: "ACCOUNT_SIGN_OUT",
            icon: <PowerSettingsNewIcon />,
            to: "/",
            onClick: handleLogOut,
            hidden: !userConnected,
          },
          {
            text: "ACCOUNT_FORGET_PWD",
            icon: <VpnKeyIcon />,
            to: "/forget-pwd",
            onClick: null,
            hidden: userConnected,
          },
          {
            text: "ACCOUNT_PROFILE",
            icon: <PersonOutlineIcon />,
            to: "/profile",
            onClick: null,
            hidden: true,
          },
        ].map((item, index) => {
          return item.hidden === false ? (
            <Link
              to={item.to}
              key={item.text}
              onClick={item.onClick === null ? handleClose : item.onClick}
              style={{ textDecoration: "none", color: "grey" }}
            >
              <ListItem button>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText secondary={t(item.text)} />
              </ListItem>
            </Link>
          ) : null;
        })}
      </List>
      <Divider />
      <List
         subheader={
          <ListSubheader component="div" >
            
            {t("LBL_GROUP_LIST", { ns: "translation" })}
          </ListSubheader>
        }
      >
        {dealGroups.map((item, index) => {
          return item.isActive === true ? (
            <Link
              to={"/deals/"+item.url}
              key={item.key + "_" + item.url}
              onClick={handleGroupClick}
              style={{ textDecoration: "none", color: "grey" }}
            >
              <ListItem button>       
              <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText secondary={t(item.key, { ns: "dealgroups" })} />
              </ListItem>
            </Link>
          ) : null;
        })}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>

      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

SideMenuComponent.propTypes = {};

function mapStateToProps(state) {
  return {
    auth: state.firebase.auth,
  };
}

const mapDispatchToProps = {
  signOut,
};
export default connect(mapStateToProps, mapDispatchToProps)(SideMenuComponent);
