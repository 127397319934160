import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import {
  FacebookMessengerShareButton,
  ViberShareButton,
  WhatsappShareButton,
} from "react-share";

import { FacebookMessengerIcon, ViberIcon, WhatsappIcon } from "react-share";

const useStyles = makeStyles((theme) => ({
  buttonclass: {
    padding: theme.spacing(1),
    //textAlign: "center",
  },
}));

export default function SocialMediaComponent() {
  let shareUrl = window.location.href;
  const appId = process.env.REACT_APP_FACEBOOK_APP_ID;
  const classes = useStyles();
  return (
    <Grid container spacing={1}>
      <Grid item xs={3} />
      <Grid item xs={2}>
        <FacebookMessengerShareButton url={shareUrl} appId={appId}>
          <FacebookMessengerIcon size={32} round={true}></FacebookMessengerIcon>
        </FacebookMessengerShareButton>
      </Grid>
      <Grid item xs={2}>
        <WhatsappShareButton url={shareUrl} className={classes.buttonclass}>
          <WhatsappIcon size={32} round={true}></WhatsappIcon>
        </WhatsappShareButton>
      </Grid>
      <Grid item xs={2}>
        <ViberShareButton url={shareUrl} className={classes.buttonclass}>
          <ViberIcon size={32} round={true}></ViberIcon>
        </ViberShareButton>
      </Grid>
      <Grid item xs={3} />
    </Grid>
  );
}
