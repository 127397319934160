import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MerchantDetails from "./details/MerchantDetails";
import {GetEmptyMerchant,GetGoogleSchemaMarkup} from "../../helpers/modelsHelper"
import Container from "@material-ui/core/Container";
import PageTagsComponent from "../common/PageTagsComponent";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

export default function MerchantContainer({...props }) {
  const classes = useStyles();
  const [merchant, setMerchant] = useState(GetEmptyMerchant());
  const [googleSchemaMarkup, setGoogleSchemaMarkup] = useState(GetGoogleSchemaMarkup(merchant));
  
  useEffect(() => {


}, []);
  return (
    <>
    <PageTagsComponent
        title={merchant.name}
        description={merchant.description}
        subDescription={merchant.url}
        keywords={merchant.description}
      />
    <Container className={classes.root}>
    <Helmet>
    <script type="application/ld+json">
    {googleSchemaMarkup }
    </script>
      </Helmet>
      <MerchantDetails currentMerchant={merchant}></MerchantDetails>
    </Container>
    </>
  );
}