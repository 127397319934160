import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import UserProfileTabs from "../UserProfileTabs";
import Container from "@material-ui/core/Container";
import UserDeals from "./display/UserDeals";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { loadSavedUserDeals } from "../../../redux/actions/dealActions";
import PageTagsComponent from "../../common/PageTagsComponent";
import LoaderComponent from "../../common/LoaderComponent";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
function UserSavedDealContainer({
  auth,
  followedDeals,
  loadSavedUserDeals,
  ...props
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (auth.uid && followedDeals && followedDeals.length === 0) {
      loadSavedUserDeals(auth.uid)
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);
  return (
    <>
      <UserProfileTabs />
      <PageTagsComponent title={"SAVED_DEALS"} />
      <Container className={classes.root}>
        {loading ? (
          <LoaderComponent />
        ) : (
          <UserDeals dealsData={followedDeals} myDeals={false} />
        )}
      </Container>
    </>
  );
}

UserSavedDealContainer.propTypes = {
  loadSavedUserDeals: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  let auth = state.firebase.auth;
  let followedDeals = state.userAccountData.followedDeals;
  return {
    auth,
    followedDeals,
  };
}

const mapDispatchToProps = {
  loadSavedUserDeals,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSavedDealContainer);
