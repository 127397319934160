import { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import Divider from "@material-ui/core/Divider";
import BasicPagination from "../../../common/BasicPagination";
import ConfirmDialog from "../../../common/ConfirmDialog";
import SnackbarComponent from "../../../common/SnackbarComponent";
import InformativeMsgComponent from "../../../common/InformativeMsgComponent";

import usePagination from "../../../../Hooks/usePagination";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { GetGroupUrlFromGroupKey } from "../../../../helpers/modelsHelper";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  unFollowDeal,
  deleteMyDeal,
} from "../../../../redux/actions/dealActions";
import {
  EmitCustomEvent,
  SeoCustomEvents,
  ConvertDealToAnalyticItemArray,
  EmitReferenceEvent,
  SeoReferenceEvents,
} from "../../../../helpers/seoHelper";
import Chip from "@material-ui/core/Chip";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    margin: "0 auto",
    alignSelf: "center",
  },
}));

function UserDeals({
  dealsData,
  myDeals,
  auth,
  unFollowDeal,
  deleteMyDeal,
  ...props
}) {
  const classes = useStyles();
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(dealsData.length / PER_PAGE);
  let userDealsPerPage = usePagination(dealsData, PER_PAGE);
  const { t } = useTranslation(["useraccount"]);
  let history = useHistory();
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    open: false,
    severity: "error",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const handleChange = (e, p) => {
    setPage(p);
    userDealsPerPage.jump(p);
  };

  const handleEditClick = (event, id) => {
    history.push("/deal/management/" + id);
  };

  const handleUnfollowClick = (item) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    EmitCustomEvent(SeoCustomEvents.remove_from_wishlist, {
      currency: item.dealCurrency.toString(),
      value: Number(item.newPrice.toString()),
      items: ConvertDealToAnalyticItemArray(item),
    });
    unFollowDeal(auth.uid, item)
      .then((_deal) => {
        setSnackbarData({
          ...snackbarData,
          message: t("SUCCESS_MSG", { ns: "translation" }),
          open: true,
          severity: "success",
        });
      })
      .catch((error) => {
        setSnackbarData({
          ...snackbarData,
          message: t("ERROR_MSG", { ns: "translation" }),
          open: true,
          severity: "error",
        });
      });
  };

  const onDelete = (item) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    if (auth.uid === item.ownerId) {
      EmitCustomEvent(SeoCustomEvents.delete_item, {
        currency: item.dealCurrency.toString(),
        value: Number(item.newPrice.toString()),
        items: ConvertDealToAnalyticItemArray(item),
      });
      deleteMyDeal(item)
        .then(() => {
          setSnackbarData({
            ...snackbarData,
            message: t("SUCCESS_MSG", { ns: "translation" }),
            open: true,
            severity: "success",
          });
        })
        .catch((error) => {
          setSnackbarData({
            ...snackbarData,
            message: t("ERROR_MSG", { ns: "translation" }),
            open: true,
            severity: "error",
          });
        });
    }
  };

  const handleDetailsClick = (event, item) => {
    let groupUrl = GetGroupUrlFromGroupKey(item.dealGroupKey[0]);
    //window.open("/deal/" + groupUrl + "/details/" + item.id);
    history.push("/deal/" + groupUrl + "/details/" + item.id);
    EmitReferenceEvent(SeoReferenceEvents.view_item, {
      currency: item.dealCurrency.toString(),
      value: Number(item.newPrice.toString()),
      items: ConvertDealToAnalyticItemArray(item),
    });
  };
  function SnackbarComponentChange() {
    setSnackbarData({ ...snackbarData, message: null, open: false });
  }
  return (
    <Grid container className={classes.root}>
      <Grid item sm={12} xs={12}>
        <List dense={false}>
          {dealsData && dealsData.length !== 0 ? (
            userDealsPerPage.currentData().map((item, index) => (
              <div key={item.id}>
                <ListItem key={item.id}>
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <ListItemAvatar>
                      <Avatar
                        src={
                          item.dealImgUrls && item.dealImgUrls.length > 0
                            ? item.dealImgUrls[0]
                            : null
                        }
                        className={classes.avatar}
                      ></Avatar>
                    </ListItemAvatar>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <ListItemText
                      primary={
                        item.title.length > 50
                          ? item.title.substring(0, 40) + "..."
                          : item.title
                      }
                    />

                    <ListItemText secondary={item.merchantName} />
                    <ListItemText
                      secondary={
                        item.oldPrice +
                        " " +
                        t(item.dealCurrency, { ns: "translation" }) +
                        " " +
                        " => " +
                        item.newPrice +
                        " " +
                        t(item.dealCurrency, { ns: "translation" }) +
                        " "
                      }
                    />

                    <Chip
                      color={
                        item.status === "ACTIVE" || item.status === "VERIFIED"
                          ? "primary"
                          : "secondary"
                      }
                      variant="outlined"
                      size="small"
                      label={t(item.status, { ns: "translation" })}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(event) => handleDetailsClick(event, item)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      {myDeals ? (
                        <>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={(event) => handleEditClick(event, item.id)}
                          >
                            <EditIcon />
                          </IconButton>

                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => {
                              setConfirmDialog({
                                isOpen: true,
                                title: t("DELETE_DEAL_CONFIRM_MSG", {
                                  ns: "translation",
                                }),
                                subTitle: t("CONFIRM_SUB_TITLE_MSG", {
                                  ns: "translation",
                                }),
                                onConfirm: () => {
                                  onDelete(item);
                                },
                              });
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      ) : (
                        <IconButton
                          edge="end"
                          aria-label="unfollow"
                          onClick={() => {
                            setConfirmDialog({
                              isOpen: true,
                              title: t("UNFOLLOW_DEAL_CONFIRM_MSG", {
                                ns: "translation",
                              }),

                              onConfirm: () => {
                                handleUnfollowClick(item);
                              },
                            });
                          }}
                        >
                          <CancelIcon />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  </Grid>
                </ListItem>
                <Divider />
              </div>
            ))
          ) : (
            <InformativeMsgComponent msgKey="NO_DEALS_TO_DISPLAY" />
          )}
        </List>
        {dealsData && dealsData.length > 0 ? (
          <BasicPagination count={count} page={page} onChange={handleChange} />
        ) : null}
      </Grid>
      <SnackbarComponent
        message={snackbarData.message}
        open={snackbarData.open}
        severity={snackbarData.severity}
        onClose={SnackbarComponentChange}
      />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Grid>
  );
}

UserDeals.propTypes = {};

function mapStateToProps(state) {
  let auth = state.firebase.auth;
  return {
    auth,
  };
}

const mapDispatchToProps = {
  unFollowDeal,
  deleteMyDeal,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserDeals);
