import { FromFirebaseDealToDealModel } from "../helpers/modelsHelper";

export async function handleResponse(snapshot: any) {
  return snapshot.docs.map((doc: any) => doc.data());
}

export async function handleAdminDealsResponse(snapshot: any) {
  return snapshot.docs.map((doc: any) =>
    FromFirebaseDealToDealModel(doc.data())
  );
}

export async function handleSingleDealDocResponse(docRef: any) {
  let item = docRef.data();
  if (item) {
    return { ...FromFirebaseDealToDealModel(item) };
  } else return null;
}

export async function handleSingleUserDocResponse(docRef: any) {
  return docRef.data();
}

// In a real app, would likely call an error logging service.
export function handleError(error: any) {
  // eslint-disable-next-line no-console
  console.error("API call failed. " + error);
  throw error;
}
