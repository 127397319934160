import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import DeckIcon from "@material-ui/icons/Deck";
import CellWifiIcon from "@material-ui/icons/CellWifi";
import CallIcon from "@material-ui/icons/Call";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import HouseIcon from "@material-ui/icons/House";
import ChildFriendlyIcon from "@material-ui/icons/ChildFriendly";
import DevicesOtherIcon from "@material-ui/icons/DevicesOther";
import PetsIcon from "@material-ui/icons/Pets";
import StoreIcon from "@material-ui/icons/Store";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PublicIcon from "@material-ui/icons/Public";
import RestoreIcon from "@material-ui/icons/Restore";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import MoneyIcon from "@material-ui/icons/Money";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import * as types from "../redux/actions/actionTypes";
import {
  MerchantIds,
  LanguagesValues,
  DealGroup,
  PaymentMethod,
} from "./models";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import MoreIcon from "@material-ui/icons/More";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import FaceIcon from '@material-ui/icons/Face';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import GroupIcon from '@material-ui/icons/Group';
export const deals = [];

export const languages = [
  { key: LanguagesValues.FR, value: "Francais", isActive: true },
  { key: LanguagesValues.EN, value: "Anglais", isActive: true },
  { key: LanguagesValues.TN, value: "Derja", isActive: true },
  { key: LanguagesValues.AR, value: "Arabe", isActive: true },
];

export const dealGroups = [
  /*{
    url: "deal-ads",
    key: DealGroup.DEAL_ADS,
    lnkey: "DEAL_ADS",
    icon: <AttachMoneyIcon />,
    isActive: true,
  },*/

  {
    url: "high-tech",
    key: DealGroup.HIGH_TECH,
    lnkey: "HIGH_TECH",
    icon: <DevicesOtherIcon />,
    isActive: true,
  },
  {
    url: "fashion-and-accessories",
    key: DealGroup.FASHION_AND_ACCESSORIES,
    lnkey: "FASHION_AND_ACCESSORIES",
    icon: <ShoppingCartIcon />,
    isActive: true,
  },
  {
    url: "health-and-cosmotics",
    key: DealGroup.HEALTH_AND_COSMETICS,
    lnkey: "HEALTH_AND_COSMETICS",
    icon: <StoreIcon />,
    isActive: true,
  },
  {
    url: "animals",
    key: DealGroup.ANIMALS,
    lnkey: "ANIMALS",
    icon: <PetsIcon />,
    isActive: true,
  },
  {
    url: "family-and-children",
    key: DealGroup.FAMILY_AND_CHILDREN,
    lnkey: "FAMILY_AND_CHILDREN",
    icon: <ChildFriendlyIcon />,
    isActive: true,
  },
  {
    url: "house-and-habitat",
    key: DealGroup.HOUSE_AND_HABITAT,
    lnkey: "HOUSE_AND_HABITAT",
    icon: <HouseIcon />,
    isActive: true,
  },
  {
    url: "sports-and-outdoors",
    key: DealGroup.SPORTS_AND_OUTDOORS,
    lnkey: "SPORTS_AND_OUTDOORS",
    icon: <FitnessCenterIcon />,
    isActive: true,
  },
  {
    url: "travel",
    key: DealGroup.TRAVEL,
    lnkey: "TRAVEL",
    icon: <FlightTakeoffIcon />,
    isActive: true,
  },
  {
    url: "phone-operator",
    key: DealGroup.PHONE_OPERATOR,
    lnkey: "PHONE_OPERATOR",
    icon: <CallIcon />,
    isActive: true,
  },
  {
    url: "internet-provider",
    key: DealGroup.INTERNET_PROVIDER,
    lnkey: "INTERNET_PROVIDER",
    icon: <CellWifiIcon />,
    isActive: true,
  },
  {
    url: "groceries",
    key: DealGroup.GROCERIES,
    lnkey: "GROCERIES",
    icon: <ShoppingBasket />,
    isActive: true,
  },
  {
    url: "garden-and-diy",
    key: DealGroup.GARDEN_AND_DIY,
    lnkey: "GARDEN_AND_DIY",
    icon: <DeckIcon />,
    isActive: true,
  },
  {
    url: "other",
    key: DealGroup.OTHER,
    lnkey: "OTHER",
    icon: <PublicIcon />,
    isActive: true,
  },
];

export const dealFilters = [
  { key: types.SORT_PLUS_HOT_DEALS, icon: <WhatshotIcon />, isActive: true , orderby: "deals-plus-hot" },
  { key: types.SORT_FOR_YOU_DEALS, icon:<FaceIcon /> , isActive: true , orderby: "for-you"},
  { key: types.SORT_WITHOUT_VOTE_DEALS, icon: <GroupIcon /> , isActive: true, orderby: "without-community-vote" },
  { key: types.SORT_CREATE_AT_DEALS, icon: <RestoreIcon />, isActive: true , orderby: "new"},
];


export const dealPaymentMethod = [
  {
    key: PaymentMethod.OTHER,
    lnKey: PaymentMethod.OTHER,
    isActive: true,
    icon: <AccountBalanceWalletIcon />,
  },
  {
    key: PaymentMethod.CASH,
    lnKey: PaymentMethod.CASH,
    isActive: true,
    icon: <MoneyIcon />,
  },
  {
    key: PaymentMethod.CB,
    lnKey: PaymentMethod.CB,
    isActive: true,
    icon: <CreditCardIcon />,
  },
];

export const merchantsList = [
  {
    merchantId: MerchantIds.OTHER,
    merchantName: "OTHER",
    imgUrl: "",
    baseUrl: "",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.JUMIA_TN,
    merchantName: "jumia.com.tn",
    imgUrl: "",
    baseUrl: "https://www.jumia.com.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.MY_TEK_TN,
    merchantName: "mytek.tn",
    imgUrl: "",
    baseUrl: "https://www.mytek.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.WAMIA_TN,
    merchantName: "wamia.tn",
    imgUrl: "",
    baseUrl: "https://www.wamia.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.SAVANA_TN,
    merchantName: "savanna.tn",
    imgUrl: "",
    baseUrl: "https://www.savanna.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.SCOOP_TN,
    merchantName: "scoop.com.tn",
    imgUrl: "",
    baseUrl: "https://www.scoop.com.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.FNAC_TN,
    merchantName: "fnac.tn",
    imgUrl: "",
    baseUrl: "https://fnac.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.DARTY_TN,
    merchantName: "darty.tn",
    imgUrl: "",
    baseUrl: "https://darty.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.PEAK_SPORTS_TN,
    merchantName: "peaksports.tn",
    imgUrl: "",
    baseUrl: "https://www.peaksports.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.DECATHLON_TN,
    merchantName: "decathlon.tn",
    imgUrl: "",
    baseUrl: "http://decathlon.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.BEAUTY_STORE_TN,
    merchantName: "beautystore.tn",
    imgUrl: "",
    baseUrl: "https://beautystore.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.FATALES_TN,
    merchantName: "fatales.tn",
    imgUrl: "",
    baseUrl: "https://www.fatales.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.PARASHOP_TN,
    merchantName: "parashop.tn",
    imgUrl: "",
    baseUrl: "https://www.parashop.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.LAST_PRICE_TN,
    merchantName: "lastpricetunisie.tn",
    imgUrl: "",
    baseUrl: "https://www.lastpricetunisie.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.POINT_M_TN,
    merchantName: "pointm.tn",
    imgUrl: "",
    baseUrl: "https://www.pointm.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.PANDA_TN,
    merchantName: "panda.tn",
    imgUrl: "",
    baseUrl: "https://panda.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.TUNISIE_BOOKING_TN,
    merchantName: "tunisiebooking.com",
    imgUrl: "",
    baseUrl: "https://www.tunisiebooking.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.TAP_AND_GET_TN,
    merchantName: "tapandget.co",
    imgUrl: "",
    baseUrl: "https://tapandget.co/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.WARM_TN,
    merchantName: "warm.tn",
    imgUrl: "",
    baseUrl: "https://warm.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.MY_SHOP_TN,
    merchantName: "myshop.tn",
    imgUrl: "",
    baseUrl: "https://www.myshop.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.MEGA_TN,
    merchantName: "mega.tn",
    imgUrl: "",
    baseUrl: "hhttps://www.mega.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.DIRECTELECTRO_TN,
    merchantName: "directelectro.tn",
    imgUrl: "",
    baseUrl: "https://directelectro.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.TDISCOUNT_TN,
    merchantName: "tdiscount.tn",
    imgUrl: "",
    baseUrl: "https://tdiscount.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.ALLANI_TN,
    merchantName: "allani.com.tn",
    imgUrl: "",
    baseUrl: "https://allani.com.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.TUNISIANET_TN,
    merchantName: "tunisianet.com.tn",
    imgUrl: "",
    baseUrl: "https://www.tunisianet.com.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.PICSTORE_TN,
    merchantName: "picstore.tn",
    imgUrl: "",
    baseUrl: "https://www.picstore.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.BAITY_TN,
    merchantName: "baity.tn",
    imgUrl: "",
    baseUrl: "https://baity.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.BOUTIKA_TN,
    merchantName: "boutika.tn",
    imgUrl: "",
    baseUrl: "https://www.boutika.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.TUNISIE_PROMO_TN,
    merchantName: "tunisiepromo.com",
    imgUrl: "",
    baseUrl: "https://www.tunisiepromo.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.TAHA_VOYAGES_TN,
    merchantName: "tahavoyages.com",
    imgUrl: "",
    baseUrl: "https://www.tahavoyages.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.LAMODE_TN,
    merchantName: "lamode.tn",
    imgUrl: "",
    baseUrl: "https://www.lamode.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.FIERCE_SPORTSWEAR_TN,
    merchantName: "fierce-sportswear.com",
    imgUrl: "",
    baseUrl: "https://fierce-sportswear.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.SPACENET_TN,
    merchantName: "spacenet.tn",
    imgUrl: "",
    baseUrl: "https://spacenet.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.LA_MAISON_TN,
    merchantName: "lamaison.tn",
    imgUrl: "",
    baseUrl: "https://www.lamaison.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.CITY_SHOP_TN,
    merchantName: "cityshop.tn",
    imgUrl: "",
    baseUrl: "https://www.cityshop.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.FARM_TRUST_TN,
    merchantName: "farmtrust.tn",
    imgUrl: "",
    baseUrl: "https://farmtrust.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.FLORAISON_TN,
    merchantName: "floraisonnaturalbeauty.com",
    imgUrl: "",
    baseUrl: "https://floraisonnaturalbeauty.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.NOS_SAVEURS_TN,
    merchantName: "nos-saveurs.com",
    imgUrl: "",
    baseUrl: "https://nos-saveurs.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.AYSHEK_TN,
    merchantName: "ayshek.com",
    imgUrl: "",
    baseUrl: "https://ayshek.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.ELAOULA_TN,
    merchantName: "elaoula.com",
    imgUrl: "",
    baseUrl: "https://www.elaoula.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.SYNOTEC_TN,
    merchantName: "synotec.tn",
    imgUrl: "",
    baseUrl: "https://synotec.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.JNINA_TN,
    merchantName: "jnina.tn",
    imgUrl: "",
    baseUrl: "https://jnina.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.ZOOM_TN,
    merchantName: "zoom.com.tn",
    imgUrl: "",
    baseUrl: "https://www.zoom.com.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.MEGA_PC_TN,
    merchantName: "megapc.tn",
    imgUrl: "",
    baseUrl: "https://megapc.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.WINCOM_SHOP_TN,
    merchantName: "wincomshop.com",
    imgUrl: "",
    baseUrl: "http://wincomshop.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.SHOPA_TN,
    merchantName: "shopa.tn",
    imgUrl: "",
    baseUrl: "https://shopa.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.AREA_NATURE_TN,
    merchantName: "arvea-nature.com",
    imgUrl: "",
    baseUrl: "https://www.arvea-nature.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.LE_MARCHET_TUNISIEN_TN,
    merchantName: "lemarchetunisien.com",
    imgUrl: "",
    baseUrl: "https://lemarchetunisien.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.PIECES_AUTOS_TN,
    merchantName: "piecesautos.tn",
    imgUrl: "",
    baseUrl: "https://www.piecesautos.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.ALGOVITA_TN,
    merchantName: "algovita.tn",
    imgUrl: "",
    baseUrl: "http://algovita.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.MI_STORE_TN,
    merchantName: "mistore.com.tn",
    imgUrl: "",
    baseUrl: "http://mistore.com.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.MASMOUDI_TN,
    merchantName: "masmoudi.tn",
    imgUrl: "",
    baseUrl: "https://www.masmoudi.tn",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.MASMOUDI_FR,
    merchantName: "patisseriemasmoudi.fr",
    imgUrl: "",
    baseUrl: "https://www.patisseriemasmoudi.fr/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.INTERIEURS_TN,
    merchantName: "interieurs.com.tn",
    imgUrl: "",
    baseUrl: "https://www.interieurs.com.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.TALOS_TN,
    merchantName: "talos.tn",
    imgUrl: "",
    baseUrl: "https://www.talos.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.BEN_YAGHLANES_SHOPS_TN,
    merchantName: "benyaghlaneshops.tn",
    imgUrl: "",
    baseUrl: "https://www.benyaghlaneshops.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.HOLLYWOOD_TN,
    merchantName: "hollywood.tn",
    imgUrl: "",
    baseUrl: "https://hollywood.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.NINE_999_TN,
    merchantName: "999.com.tn",
    imgUrl: "",
    baseUrl: "http://999.com.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.TUNISIE_SHOP_TN,
    merchantName: "tunisieshop.tn",
    imgUrl: "",
    baseUrl: "https://www.tunisieshop.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.MERKADO_TN,
    merchantName: "merkado.tn",
    imgUrl: "",
    baseUrl: "https://www.merkado.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.HEXABYTE_TN,
    merchantName: "hexabyte.tn",
    imgUrl: "",
    baseUrl: "https://hexabyte.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.TOPNET_TN,
    merchantName: "topnet.tn",
    imgUrl: "",
    baseUrl: "https://www.topnet.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.ORANGE_TN,
    merchantName: "orange.tn",
    imgUrl: "",
    baseUrl: "https://www.orange.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.OOREDOO_TN,
    merchantName: "ooredoo.tn",
    imgUrl: "",
    baseUrl: "https://www.ooredoo.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.TUNISIE_TELECOM_TN,
    merchantName: "tunisietelecom.tn",
    imgUrl: "",
    baseUrl: "https://www.tunisietelecom.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.TRAVEL_STORE_TN,
    merchantName: "travelstore.tn",
    imgUrl: "",
    baseUrl: "https://www.travelstore.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.EDEN_TOURS_TN,
    merchantName: "edentours.tn",
    imgUrl: "",
    baseUrl: "https://www.edentours.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.TRAVEL_TODO_TN,
    merchantName: "traveltodo.com",
    imgUrl: "",
    baseUrl: "https://www.traveltodo.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.SHOPHEAN_TN,
    merchantName: "shophean.tn",
    imgUrl: "",
    baseUrl: "http://shophean.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.FEERIE_TN,
    merchantName: "feerie.tn",
    imgUrl: "",
    baseUrl: "https://www.feerie.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.COSMETIQUE_TN,
    merchantName: "cosmetique.tn",
    imgUrl: "",
    baseUrl: "https://www.cosmetique.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.TANIT_DECO_TN,
    merchantName: "tanitdeco.com",
    imgUrl: "",
    baseUrl: "https://www.tanitdeco.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.ICE_TN,
    merchantName: "lce.tn",
    imgUrl: "",
    baseUrl: "http://lce.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.PROMOUV_TN,
    merchantName: "promouv.com",
    imgUrl: "",
    baseUrl: "http://www.promouv.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.MODECO_TN,
    merchantName: "modeco.tn",
    imgUrl: "",
    baseUrl: "https://www.modeco.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.TAYARA_TN,
    merchantName: "tayara.tn",
    imgUrl: "",
    baseUrl: "https://www.tayara.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.VONGO_TN,
    merchantName: "vongo.tn",
    imgUrl: "",
    baseUrl: "https://www.vongo.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.AFFARIYET_TN,
    merchantName: "affariyet.com",
    imgUrl: "",
    baseUrl: "https://www.affariyet.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.ZANIMO_FOUNA_TN,
    merchantName: "zanimo.founa.com",
    imgUrl: "",
    baseUrl: "https://zanimo.founa.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.BIO_FOUNA_TN,
    merchantName: "bio.founa.com",
    imgUrl: "",
    baseUrl: "https://bio.founa.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.MAISON_FOUNA_TN,
    merchantName: "maison.founa.com",
    imgUrl: "",
    baseUrl: "https://maison.founa.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.SANTE_FOUNA_TN,
    merchantName: "sante.founa.com",
    imgUrl: "",
    baseUrl: "https://sante.founa.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.BEAUTE_FOUNA_TN,
    merchantName: "beaute.founa.com",
    imgUrl: "",
    baseUrl: "https://beaute.founa.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.BEBE_FOUNA_TN,
    merchantName: "bebe.founa.com",
    imgUrl: "",
    baseUrl: "https://bebe.founa.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.ALIMENTATION_FOUNA_TN,
    merchantName: "alimentation.founa.com",
    imgUrl: "",
    baseUrl: "https://alimentation.founa.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.ENTRETIEN_FOUNA_TN,
    merchantName: "entretien.founa.com",
    imgUrl: "",
    baseUrl: "https://entretien.founa.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.BRICO_FOUNA_TN,
    merchantName: "brico.founa.com",
    imgUrl: "",
    baseUrl: "https://brico.founa.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.ANIMALERIE_FOUNA_TN,
    merchantName: "animalerie.founa.com",
    imgUrl: "",
    baseUrl: "https://animalerie.founa.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.LOISIRS_FOUNA_TN,
    merchantName: "loisirs.founa.com",
    imgUrl: "",
    baseUrl: "https://loisirs.founa.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.LIBRAIRIE_FOUNA_TN,
    merchantName: "librairie.founa.com",
    imgUrl: "",
    baseUrl: "https://librairie.founa.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },

  {
    merchantId: MerchantIds.PROMOTIONS_FOUNA_TN,
    merchantName: "promotions.founa.com",
    imgUrl: "",
    baseUrl: "https://promotions.founa.com/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.PHARMA_SHOP_TN,
    merchantName: "pharma-shop.tn",
    imgUrl: "",
    baseUrl: "https://pharma-shop.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.CRAVATE_TN,
    merchantName: "cravate.tn",
    imgUrl: "",
    baseUrl: "https://www.cravate.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },

  {
    merchantId: MerchantIds.HABIBA_JEWELRY_TN,
    merchantName: "habiba.jewelry",
    imgUrl: "",
    baseUrl: "https://habiba.jewelry/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
  {
    merchantId: MerchantIds.SAMSUNG_SHOP_TN,
    merchantName: "samsungshop.tn",
    imgUrl: "",
    baseUrl: "https://www.samsungshop.tn/",
    fbUrl: "",
    isActive: true,
    tags: [],
  },
];

export const userTabs = [
  {
    url: "/deal/my",
    key: "MY_DEALS",
    lnkey: "MY_DEALS",
    icon: <ListAltIcon />,
    isActive: true,
  },
  {
    url: "/deal/saved",
    key: "SAVED_DEALS",
    lnkey: "SAVED_DEALS",
    icon: <LoyaltyIcon />,
    isActive: true,
  },
  {
    url: "/deal/management",
    key: "ADD_EDIT_DEAL",
    lnkey: "ADD_EDIT_DEAL",
    icon: <MoreIcon />,
    isActive: true,
  },
  {
    url: "/account/my",
    key: "MY_ACCOUNT",
    lnkey: "MY_ACCOUNT",
    icon: <PersonOutlineIcon />,
    isActive: false,
  },
];
