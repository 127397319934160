import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { EmitCustomEvent, SeoCustomEvents } from "../../helpers/seoHelper";
import { useLocation } from "react-router-dom";
export default function ContactComponent({
  titleKey,
  msgKey,
  emailKey,
  ...props
}) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation(["translation"]);
  const location = useLocation();
  const handleClickOpen = () => {
    setOpen(true);
    EmitCustomEvent(
      titleKey === "LBL_JOIN_US"
        ? SeoCustomEvents.join_us
        : SeoCustomEvents.advertise_with_us,
      {
        page_location: location.pathname,
      }
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        style={{ color: "grey" }}
        onClick={handleClickOpen}
        size="small"
      >
        <strong>{t(titleKey)}</strong>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t(titleKey)}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t(msgKey)}
            <strong> {t(emailKey)} </strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("LBL_CANCEL")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
