import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as dealsApi from "../../core/api/dealApi";
import { loadDealsSuccess,loadDealsFailer } from "../actions/dealActions";

export function searchDeals(q) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return dealsApi
      .searchDeals(q)
      .then((deals) => {
        dispatch(loadDealsSuccess(deals));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        dispatch(loadDealsFailer());
        throw error;
      });
  };
}
