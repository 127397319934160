import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DealInfo from "./DealInfo";
import PropTypes from "prop-types";
import DealVoteHorizontal from "../vote/DealVoteHorizontal";
import { useHistory } from "react-router-dom";
import { GetGroupUrlFromGroupKey } from "../../../helpers/modelsHelper";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import { useTranslation } from "react-i18next";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { GetVoteColor } from "../../../helpers/modelsHelper";
import { connect } from "react-redux";
import SnackbarComponent from "../../common/SnackbarComponent";
import Chip from "@material-ui/core/Chip";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import CardContent from "@material-ui/core/CardContent";
import {
  EmitReferenceEvent,
  SeoReferenceEvents,
  ConvertDealToAnalyticItemArray,
} from "../../../helpers/seoHelper";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    maxHeight: 800,
    margin: "4px",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    marginRight: "auto",
  },
  chipClass: {
    marginRight: "auto",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
  },
  button: {
    marginLeft: "auto",
    backgroundColor: "#2bb04a",
    color: "white",
    "&:hover": {
      background: "#44C260",
    },
  },
  dealScore: {
    color: "#d50000",
    fontWeight: "bold",
  },
  label: {
    // Aligns the content of the button vertically.
    flexDirection: "column",
    fontSize: "0.75rem",
  },
  cardHeader: {
    padding: "8px",
  },
  cardActionsClass: {
    padding: "4px",
  },
}));

export function DealCard({ currentDeal, auth, ...props }) {
  const classes = useStyles();
  let history = useHistory();
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    open: false,
    severity: "error",
  });
  const { t } = useTranslation(["translation"]);
  const handleClick = (event) => {
    let groupUrl = GetGroupUrlFromGroupKey(currentDeal.dealGroupKey[0]);
    history.push("/deal/" + groupUrl + "/details/" + currentDeal.id);
    EmitReferenceEvent(SeoReferenceEvents.view_item, {
      currency: currentDeal.dealCurrency.toString(),
      value: Number(currentDeal.newPrice.toString()),
      items: ConvertDealToAnalyticItemArray(currentDeal),
    });
  };
  function SnackbarComponentChange() {
    setSnackbarData({ ...snackbarData, message: null, open: false });
  }
  const handleDataChange = (newData) => {
    currentDeal = { ...newData };
  };

  function GetDealVoteIcon() {
    var sponsorized = (
      <Typography
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
        variant="body2"
        color="textSecondary"
      >
        <span
          style={{
            paddingRight: "5px",
          }}
        >
          ad
        </span>
        <MonetizationOnIcon />
      </Typography>
    );

    var newItem = (
      <Typography
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
        variant="body2"
        color="textSecondary"
      >
        <span
          style={{
            paddingRight: "5px",
          }}
        >
          top/flop ?
        </span>
        <SwapVertIcon />
      </Typography>
    );

    if (currentDeal.isSponsorised) return sponsorized;
    if (currentDeal.vote === 0) return null;
    if (currentDeal.vote <= -50)
      return <AcUnitIcon style={GetVoteColor(currentDeal.vote)} />;
    if (currentDeal.vote < 0)
      return <AcUnitIcon style={GetVoteColor(currentDeal.vote)} />;
    if (currentDeal.vote >= 50)
      return <WhatshotIcon style={GetVoteColor(currentDeal.vote)} />;
    if (currentDeal.vote > 0)
      return <WhatshotIcon style={GetVoteColor(currentDeal.vote)} />;
  }

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <DealVoteHorizontal
              currentDeal={currentDeal}
              onDataChange={handleDataChange}
            />
          }
          action={GetDealVoteIcon()}
        />

        <DealInfo currentDeal={currentDeal} />
        <CardContent>
        <Typography variant="body2"  component="p">
          {currentDeal.description && currentDeal.description.length > 100 ? currentDeal.description.slice(0,80)+" ..." : currentDeal.description  }
        </Typography>
        </CardContent>
        <CardActions className={classes.cardActionsClass}>
          <Chip
            className={classes.chipClass}
            avatar={<Avatar>{currentDeal.ownerInitials}</Avatar>}
            label={currentDeal.ownerPseudo ? currentDeal.ownerPseudo : ""}
            variant="outlined"
          />
          <Button
            variant="contained"
            className={classes.button}
            endIcon={<ChevronRightIcon />}
            onClick={handleClick}
          >
            {t("BTN_DETAILS")}
          </Button>
        </CardActions>
      </Card>
      <SnackbarComponent
        message={snackbarData.message}
        open={snackbarData.open}
        severity={snackbarData.severity}
        onClose={SnackbarComponentChange}
      />
    </>
  );
}

DealCard.propTypes = {
  currentDeal: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  let auth = state.firebase.auth;
  return {
    auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DealCard);
