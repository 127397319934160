import * as types from "../actions/actionTypes";

const initialState = {
  deals:[]
};

export default function dealReducer(state = initialState, action) {

  switch (action.type) {
    case types.LOAD_DEALS_SUCCESS:

      return {...state , deals : action.deals};

    case types.CREATE_DEAL_SUCCESS: {
      return {...state , deals : [action.deal, ...state.deals]};

    }
    case types.UPDATE_DEAL_SUCCESS:
    case types.UPDATE_DEAL_VOTE_OK:
    case types.UPDATE_DEAL_REPORTED_USER_OK:
    let deals = state.deals
        .map((_deal) =>
          _deal.id === action.deal.id
            ? { ...action.deal, title: _deal.title }
            : _deal
        );
        return {...state , deals : deals};
    case types.DELETE_DEAL_BY_OWNER_OK: {
      let deals = state.deals
        .filter((_deal) => _deal.id !== action.deal.id);
     
        return {...state , deals : deals};
    }

    default:
      return state;
  }
}
