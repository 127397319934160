import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import PageTagsComponent from "../common/PageTagsComponent";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    margin: "2%",
  },
  container: {
    justifyContent: "center",
    display: "flex",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
}));

export default function NotFoundPage() {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <PageTagsComponent title={"NOT_FOUND_404"} />
      <Card className={classes.root}>
        <CardHeader title="404" subheader="Page Not Found" />
        <CardMedia
          className={classes.media}
          image="/img/404.svg"
          title="Paella dish"
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            The specified page was not found on this website. Please check the
            URL for mistakes and try again.
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            <strong>Why am I seeing this ?</strong>
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Perhaps you can return back to the site's <a href="/"> homepage </a>
            and see if you can find what you are looking for.
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
}
