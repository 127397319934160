import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

export default function GenericButton({
  text,
  size,
  color,
  variant,
  onClick,
  ...props
}) {
  return (
    <>
      <Button
        variant={variant || "contained"}
        size={size || "large"}
        color={color || "primary"}
        onClick={onClick}
        fullWidth
        {...props}
      >
        {text}
      </Button>
    </>
  );
}

GenericButton.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  error: PropTypes.string,
};

GenericButton.defaultProps = {
  error: "",
};
