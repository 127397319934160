import configureStore from "../../redux/configureStore";
import { createFirestoreInstance } from "redux-firestore";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";
import firebase from "firebase/app";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
};


export const firebaseApp = firebase.initializeApp(firebaseConfig);
firebaseApp.analytics();
firebaseApp.firestore().settings({ timestampsInSnapshots: true });

export const store = configureStore();
export const storage = firebase.storage();
export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
  storage,
};

export const FireStoreDealsGetLimit = 20;

export const FireStoreCollections = {
  DEALS_COLLECTION: "deals",
  USERS_COLLECTION: "users",
  COMMENTS_COLLECTION: "comments",
};
