import React from "react";
import IconButton from '@material-ui/core/IconButton';
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

export function LeftArrow() {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleElements,
    initComplete
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
        <IconButton disabled={disabled} onClick={() => scrollPrev()} color="secondary" aria-label="add an alarm">
          <NavigateBeforeIcon />
        </IconButton>
  );
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext, visibleElements } = React.useContext(
    VisibilityContext
  );


  const [disabled, setDisabled] = React.useState(
    !visibleElements.length && isLastItemVisible
  );
  React.useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (

<IconButton disabled={disabled} onClick={() => scrollNext()} color="secondary" aria-label="add an alarm">
<NavigateNextIcon />
</IconButton>
  );
}
