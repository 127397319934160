import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { dealFilters } from "../../helpers/refData";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { GetGroupKeysFromGroupUrl,GetOrderByKeysFromUrl } from "../../helpers/modelsHelper";
import { useParams, useHistory } from "react-router-dom";
import { EmitCustomEvent, SeoCustomEvents } from "../../helpers/seoHelper";


const useStyles = makeStyles({
  root: {
    width: "100%",
    marginBottom: "1%",
  }, 
});

export function TabFilters({  ...props }) {
  const classes = useStyles();
  

  const { t } = useTranslation(["dealfilters"]);
  let { group,orderby } = useParams();
  let history = useHistory();
  let groupKey = GetGroupKeysFromGroupUrl(group);
  let orderbyKey = GetOrderByKeysFromUrl(orderby);
  const [value, setValue] = React.useState(dealFilters.findIndex((x) => x.key === orderbyKey));
 
  useEffect(() => {

  }, [group,orderby]);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        EmitCustomEvent(SeoCustomEvents.sort_items, {
          groupKey: groupKey,
          sortKey: dealFilters[newValue].orderby,
        });
        history.push("/deals/"+group+"/"+dealFilters[newValue].orderby);         
      }}
      showLabels = {true}
      className={classes.root}
    >

    {dealFilters.map((item, index) => {
          return item.isActive === true ? (
            <BottomNavigationAction
            key={item.key}
            label={t(item.key)}
            icon={item.icon}
            disabled={!item.isActive}
            
          />
          ) : null;
        })}
    </BottomNavigation>
  );
}

TabFilters.propTypes = {

};

function mapStateToProps(state) {
  
  return {

  };
}

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(TabFilters);
