import * as types from "../actions/actionTypes";

const initialState = {
  followedDeals: [],
  userDeals: [],
  userDeals : []
};

export default function userAccountReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_USER_SAVED_DEALS_SUCCESS:
      return { ...state, followedDeals: action.deals };
    case types.LOAD_USER_DEALS_SUCCESS:
      return { ...state, userDeals: action.deals };
    case types.ADD_USER_DEALS_SUCCESS:
      return { ...state, userDeals: [action.deal, ...state.userDeals] };
    case types.FOLLOW_DEAL_BY_USER_OK:
      return {
        ...state,
        followedDeals: [action.deal, ...state.followedDeals],
      };
    case types.UNFOLLOW_DEAL_BY_USER_OK: {
      let dealsToReturn = state.followedDeals.filter(
        (_deal) => _deal.id !== action.deal.id
      );
      return {
        ...state,
        followedDeals: dealsToReturn,
      };
    }
    case types.DELETE_MY_DEAL_OK: {
      let dealsToReturn = state.userDeals.filter(
        (_deal) => _deal.id !== action.deal.id
      );
      return {
        ...state,
        userDeals: dealsToReturn,
      };
    }

    case types.UPDATE_USER_DEALS_SUCCESS:
      let dealsToReturn = state.userDeals.map((_deal) =>
        _deal.id === action.deal.id ? action.deal : _deal
      );
      return {
        ...state,
        userDeals: dealsToReturn,
      };

    default:
      return state;
  }
}
