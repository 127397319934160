import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { useTranslation } from "react-i18next";
import PageTagsComponent from "../common/PageTagsComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "2%",
  },
  container: {
    justifyContent: "center",
    display: "flex",
  },
}));

export default function CookiesPage() {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);
  return (
    <Container className={classes.container}>
      <PageTagsComponent title={"COOKIES_POLICY"} />
      <Card className={classes.root}>
        <CardHeader
          title={"Politique de Cookies TopFlop"}
          subheader={
            "Politique de Cookies a été modifiée pour la dernière fois le jeudi 18 novembre 2021"
          }
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            <strong> TopFlop </strong> <br />
            <br />
            <strong> Sélection, diffusion et signalement de contenu</strong> La
            collecte d’informations que l’on associe à celles rassemblées
            précédemment afin de sélectionner et diffuser des contenus à votre
            égard, puis évaluer leur diffusion ainsi que leur efficacité. Cela
            comprend : le fait d’utiliser des informations collectées
            précédemment relativement à vos intérêts afin de sélectionner du
            contenu ; le traitement de données indiquant quel contenu a été
            affiché, à quelle fréquence, pendant combien de temps, à quel moment
            et où il a été affiché ; et le fait de savoir si vous avez réagi par
            rapport audit contenu, par exemple si vous cliqué dessus. Cela ne
            comprend pas la Personnalisation qui consiste en la collecte et le
            traitement d’informations relatives à votre utilisation de ce
            service afin de vous adresser, ultérieurement du contenu et/ou des
            publicités personnalisés dans d’autres contextes, par exemple sur
            des sites ou applications. <br />
            <br />
            <strong>Évaluation</strong> La collecte d’informations relatives à
            votre utilisation du contenu et association desdites informations
            avec celles précédemment collectées afin d’évaluer, de comprendre et
            de rendre compte de la façon dont vous utilisez le service. Cela ne
            comprend pas la Personnalisation, la collecte d’informations
            relatives à votre utilisation de ce service afin de vous adresser
            ultérieurement du contenu et/ou des publicités personnalisés dans
            d’autres contextes, c’est-à-dire sur d’autres services, tels que des
            sites ou des applications.
            <br />
            <br />
            <strong>Personnalisation </strong>Collecte et traitement
            d’informations relatives à votre utilisation de ce service afin de
            vous adresser ultérieurement des publicités et/ou du contenu
            personnalisés dans d’autres contextes, par exemple sur d’autres
            sites ou applications. En général, le contenu du site ou de
            l’application est utilisé pour faire des déductions concernant vos
            intérêts, ce qui sera utile dans le cadre de sélections ultérieures
            de publicité et/ou de contenu.
            <br />
            <br />
            <strong>Sélection, diffusion et signalement de publicités </strong>
            Collecte d’informations qui sont en suite associées à celles
            rassemblées précédemment, afin de sélectionner et diffuser des
            publicités à votre égard, puis évaluer leur diffusion ainsi que leur
            efficacité. Cela comprend : le fait d’utiliser des informations
            collectées précédemment relativement à vos intérêts afin de
            sélectionner des publicités ; le traitement de données indiquant
            quelles publicités ont été affichées et à quelle fréquence, à quel
            moment et où elles ont été affichées ; et le fait de savoir si vous
            avez réagi par rapport auxdites publicités, par exemple si vous avez
            cliqué dessus ou effectué un achat. Cela ne comprend pas la
            Personnalisation qui consiste en la collecte et le traitement
            d’informations relatives à votre utilisation de ce service afin de
            vous adresser ultérieurement des publicités et/ou du contenu
            personnalisés dans d’autres contextes, par exemple sur des sites ou
            applications.
            <br />
            <br />
            <strong>Conservation et accès aux informations </strong>
            La conservation d’informations ou l’accès à des informations déjà
            conservées sur votre appareil, par exemple des identifiants
            publicitaires, des identifiants de l’appareil, des cookies et des
            technologies similaires. <br />
            <br />
            <strong>PARTENAIRES </strong>
            <br />
            <br />
            <strong>Conservation et accès aux informations </strong>
            La conservation d’informations ou l’accès à des informations déjà
            conservées sur votre appareil, par exemple des identifiants
            publicitaires, des identifiants de l’appareil, des cookies et des
            technologies similaires.
            <br />
            <br />
            <strong>Personnalisation </strong>Collecte et traitement
            d’informations relatives à votre utilisation de ce service afin de
            vous adresser ultérieurement des publicités et/ou du contenu
            personnalisés dans d’autres contextes, par exemple sur d’autres
            sites ou applications. En général, le contenu du site ou de
            l’application est utilisé pour faire des déductions concernant vos
            intérêts, ce qui sera utile dans le cadre de sélections ultérieures
            de publicité et/ou de contenu.
            <br />
            <br />
            <strong>Sélection, diffusion et signalement de publicités</strong>
            Collecte d’informations qui sont en suite associées à celles
            rassemblées précédemment, afin de sélectionner et diffuser des
            publicités à votre égard, puis évaluer leur diffusion ainsi que leur
            efficacité. Cela comprend : le fait d’utiliser des informations
            collectées précédemment relativement à vos intérêts afin de
            sélectionner des publicités ; le traitement de données indiquant
            quelles publicités ont été affichées et à quelle fréquence, à quel
            moment et où elles ont été affichées ; et le fait de savoir si vous
            avez réagi par rapport auxdites publicités, par exemple si vous avez
            cliqué dessus ou effectué un achat. Cela ne comprend pas la
            Personnalisation qui consiste en la collecte et le traitement
            d’informations relatives à votre utilisation de ce service afin de
            vous adresser ultérieurement des publicités et/ou du contenu
            personnalisés dans d’autres contextes, par exemple sur des sites ou
            applications.
            <br />
            <br />
            <strong>Sélection, diffusion et signalement de contenu </strong>La
            collecte d’informations que l’on associe à celles rassemblées
            précédemment afin de sélectionner et diffuser des contenus à votre
            égard, puis évaluer leur diffusion ainsi que leur efficacité. Cela
            comprend : le fait d’utiliser des informations collectées
            précédemment relativement à vos intérêts afin de sélectionner du
            contenu ; le traitement de données indiquant quel contenu a été
            affiché, à quelle fréquence, pendant combien de temps, à quel moment
            et où il a été affiché ; et le fait de savoir si vous avez réagi par
            rapport audit contenu, par exemple si vous cliqué dessus. Cela ne
            comprend pas la Personnalisation qui consiste en la collecte et le
            traitement d’informations relatives à votre utilisation de ce
            service afin de vous adresser, ultérieurement du contenu et/ou des
            publicités personnalisés dans d’autres contextes, par exemple sur
            des sites ou applications.
            <br />
            <br />
            <strong>Évaluation</strong> La collecte d’informations relatives à
            votre utilisation du contenu et association desdites informations
            avec celles précédemment collectées afin d’évaluer, de comprendre et
            de rendre compte de la façon dont vous utilisez le service. Cela ne
            comprend pas la Personnalisation, la collecte d’informations
            relatives à votre utilisation de ce service afin de vous adresser
            ultérieurement du contenu et/ou des publicités personnalisés dans
            d’autres contextes, c’est-à-dire sur d’autres services, tels que des
            sites ou des applications.
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
}
