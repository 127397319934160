import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { useTranslation } from "react-i18next";
import PageTagsComponent from "../common/PageTagsComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "2%",
  },
  container: {
    justifyContent: "center",
    display: "flex",
  },
}));

export default function TermsPage() {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);
  return (
    <Container className={classes.container}>
      <PageTagsComponent title={"TERMS_AND_CONDITIONS"} />
      <Card className={classes.root}>
        <CardHeader
          title={"Termes et conditions de TopFlop ('Accord')"}
          subheader={
            "Termes et conditions a été modifiée pour la dernière fois le jeudi 18 novembre 2021"
          }
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            Veuillez lire attentivement ces Termes et Conditions ("Contrat",
            "Termes et Conditions") avant d'utiliser
            <a href="http://topflop.tn">http://topflop.tn </a> ("le Site")
            exploité par <strong> TopFlop </strong> ("nous", "nous" ou "notre").
            <br />
            <br />
            Le présent accord énonce les termes et conditions juridiquement
            contraignants pour votre utilisation du site{" "}
            <a href="http://topflop.tn">http://topflop.tn </a>. En accédant ou
            en utilisant le Site de quelque manière que ce soit, y compris, mais
            sans s'y limiter, en visitant ou en parcourant le Site ou en
            contribuant au contenu ou d'autres matériaux au Site, vous acceptez
            d'être lié par ces Termes et Conditions. Les termes en majuscules
            sont définis dans le présent contrat. <br />
            <br />
            <strong>Propriété intellectuelle</strong> Le Site et son contenu
            original, ses caractéristiques et ses fonctionnalités sont la
            propriété de <strong> TopFlop </strong> et sont protégés par les
            lois internationales sur les droits d'auteur, les marques de
            commerce, les brevets, les secrets commerciaux et autres droits de
            propriété intellectuelle. <br />
            <br />
            <strong>Résiliation </strong>Nous pouvons résilier votre accès au
            Site, sans motif ni préavis, ce qui peut entraîner la confiscation
            et la destruction de toutes les informations qui vous sont
            associées. Toutes les dispositions du présent Contrat qui, de par
            leur nature, doivent rester en vigueur après la résiliation
            continueront à être résiliées, y compris, sans s'y limiter, les
            clauses de propriété, les clauses de non-responsabilité, les
            indemnités et les limitations de responsabilité. <br />
            <br />
            <strong>Liens vers d'autres sites</strong> Notre site peut contenir
            des liens vers des sites tiers qui ne sont pas détenus ou contrôlés
            par <strong> TopFlop </strong>. <strong> TopFlop </strong> n'a aucun
            contrôle sur le contenu, les politiques de confidentialité ou les
            pratiques des sites ou services tiers, et n'assume aucune
            responsabilité à leur égard. Nous vous conseillons vivement de lire
            les termes et conditions et la politique de confidentialité de tout
            site tiers que vous visitez. <br />
            <br />
            <strong> Réglementation de la loi </strong>Le présent Accord (et
            toutes autres règles, politiques ou lignes directrices incorporées
            par référence) sera régi et interprété conformément aux lois de la
            Tunisie, sans donner effet aux principes de conflits de lois.
            <br />
            <br />
            <strong> Changements à cet accord</strong> Nous nous réservons le
            droit, à notre seule discrétion, de modifier ou de remplacer ces
            Termes et Conditions en affichant les conditions mises à jour sur le
            Site. Votre utilisation continue du Site après de tels changements
            constitue votre acceptation des nouvelles Termes et Conditions.
            Veuillez réviser cet accord périodiquement pour les changements. Si
            vous n'êtes pas d'accord avec l'un ou l'autre des termes du présent
            Contrat ou des modifications apportées à ce Contrat, n'utilisez pas,
            n'accédez pas ou ne continuez pas à accéder au Site ou cessez
            immédiatement toute utilisation du Site.
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
}
