import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";
import DealPreviewInfo from "./DealPreviewInfo";
import PropTypes from "prop-types";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import { useHistory } from "react-router-dom";
import { GetDateNow } from "../../../../helpers/modelsHelper";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    maxHeight: 800,
    margin: theme.spacing(1),
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    marginRight: "auto",
  },
  avatar: {
    backgroundColor: grey[500],
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  controls: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
  },
  button: {
    marginLeft: "auto",
  },
  dealScore: {
    color: "#d50000",
    fontWeight: "bold",
  },
}));

export function DealPreviewCard({
  currentDeal,
  onImgUrlChange,
  auth,
  ...props
}) {
  const classes = useStyles();

  const GetDescription = () => {
    let emptyDescription =
      "En manque d'idées ? Présentez le produit ou l'offre avec vos propres mots, expliquez en quoi l'offre est intéressante selon vous, décrivez la façon d'obtenir le prix s'il y a une astuce… ";
    if (!currentDeal.description || currentDeal.description.length === 0) {
      return emptyDescription;
    }

    if (
      currentDeal.description.length > 0 &&
      currentDeal.description.length <= 150
    ) {
      return currentDeal.description;
    }

    if (currentDeal.description.length > 150) {
      return currentDeal.description.slice(0, 150) + "... Afficher plus";
    }
  };

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          action={<WhatshotIcon style={{ color: "#d50000" }} />}
          subheader={GetDateNow()}
        />

        <DealPreviewInfo
          currentDeal={currentDeal}
          onImgUrlChange={onImgUrlChange}
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {GetDescription()}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <Chip
            className={classes.chipClass}
            avatar={
              <Avatar>
                {auth.displayName && auth.displayName.length > 0
                  ? auth.displayName[0]
                  : auth.email[0]}
              </Avatar>
            }
            label={auth.displayName ? auth.displayName : auth.email}
            variant="outlined"
          />
        </CardActions>
      </Card>
    </>
  );
}

DealPreviewCard.propTypes = {
  currentDeal: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  let auth = state.firebase.auth;
  return {
    auth,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DealPreviewCard);
