import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Zoom from "@material-ui/core/Zoom";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 900,
  },
}));

function ScrollTop(props) {
  const { children, backtotopanchor } = props;

  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    /* const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );*/

    if (backtotopanchor) {
      backtotopanchor.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  backtotopanchor: PropTypes.object.isRequired,
};

export default function BackToTop({ backtotopanchor }) {
  return (
    <ScrollTop backtotopanchor={backtotopanchor}>
      <Fab color="secondary" size="small" aria-label="scroll back to top">
        <KeyboardArrowUpIcon />
      </Fab>
    </ScrollTop>
  );
}

BackToTop.propTypes = {
  backtotopanchor: PropTypes.object.isRequired,
};
