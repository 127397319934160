import { makeStyles } from "@material-ui/core/styles";
import AdminDealPreviewCard from "./AdminDealPreviewCard";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({}));

export default function AdminDealPreview({ deal, onImgUrlChange, ...props }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h4">Preview</Typography>
      <AdminDealPreviewCard
        currentDeal={deal}
        onImgUrlChange={onImgUrlChange}
      />
    </div>
  );
}
