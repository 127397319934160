import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import { isFloat } from "../../../helpers/modelsHelper";

export default function GenericNumberInput({
  name,
  label,
  value,
  onChange,
  error = null,
  ...props
}) {
  const convertToDefaultEventParam = (name, value) => ({
    target: {
      name,
      value: isFloat(value.replace(",", ".").replace(" ", ""))
        ? value.replace(",", ".").replace(" ", "")
        : "",
    },
  });
  return (
    <>
      <TextField
        variant="outlined"
        //type="number"
        fullWidth
        label={label}
        name={name}
        value={value}
        onChange={(e) =>
          onChange(convertToDefaultEventParam(name, e.target.value))
        }
        {...(error && { error: true, helperText: error })}
        {...props}
      />
    </>
  );
}

GenericNumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.any,
};

GenericNumberInput.defaultProps = {
  error: null,
};
