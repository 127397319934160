import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { userTabs } from "../../helpers/refData";
import { GetActiveItems } from "../../helpers/modelsHelper";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import UserProfileHeader from "./UserProfileHeader";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

export default function UserProfilTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [tabs, setTabs] = React.useState(GetActiveItems(userTabs));
  const { t } = useTranslation(["useraccount"]);
  let history = useHistory();

  useEffect(() => {
    const targetItem = (element) =>
      history.location.pathname.includes(element.url);
    const index = tabs.findIndex(targetItem);
    if (index !== -1) setValue(index);
  }, [history.location.pathname]);

  return (
    <>
      <UserProfileHeader />
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          if (newValue === value) return;
          history.push(tabs[newValue].url);
          setValue(newValue);
        }}
        showLabels
        className={classes.root}
      >
        {tabs.map((item, index) => (
          <BottomNavigationAction
            key={item.key}
            label={t(item.key)}
            icon={item.icon}
            disabled={!item.isActive}
          />
        ))}
      </BottomNavigation>
    </>
  );
}
