import React from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "./arrows";
import { Card } from "./card";
import usePreventBodyScroll from "./usePreventBodyScroll";

// NOTE: embrace power of CSS flexbox!
import "./hideScrollbar.css";
type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;


 export default function HorizontalScrollingContainer({itemsToDisplay}:{itemsToDisplay:any}) {
  const [items] = React.useState(itemsToDisplay);
  const { disableScroll, enableScroll } = usePreventBodyScroll();

  return (
    <>
        <div onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
          <ScrollMenu
            //Header={<div>HEADER</div>}
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            onWheel={onWheel}
          >
            {items.map((deal :any) =>
              (<Card deal={deal} key={deal.id}/>
            ))}
          </ScrollMenu>
        </div>
    </>
  );
}


function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

