import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AdminDataTable from "./AdminDataTable";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { connect } from "react-redux";
import CardContent from "@material-ui/core/CardContent";
import {
  getDealsByFilter,
  getUserRoles,
} from "../../redux/actions/adminActions";
import { GetDataTableColumns } from "../../helpers/adminHelpers";
import {
  purgeDeal,
} from "../../redux/actions/adminActions";
import { GetEmptyDeal } from "../../helpers/modelsHelper";
import AdminDataTableFilter from "./AdminDataTableFilter";
import AdminManageDeal from "./deal/AdminManageDeal";
import Controls from "../common/controls/Controls";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../common/ConfirmDialog";
import SnackbarComponent from "../common/SnackbarComponent";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1%",
  },
}));

function AdminDashboardContainer({
  auth,
  getDealsByFilter,
  getUserRoles,
  purgeDeal,
  ...props
}) {
  const classes = useStyles();
  let [deals, setDeals] = useState(null);
  let [roles, setRoles] = useState(false);
  let [selectedDeal, setSelectedDeal] = useState(GetEmptyDeal());
  let [selectedIds, setSelectedIds] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    open: false,
    severity: "error",
  });
  function SnackbarComponentChange() {
    setSnackbarData({ ...snackbarData, message: null, open: false });
  }
  const { t } = useTranslation(["translation"]);
  let [columns, setColumns] = useState(null);
  let history = useHistory();
  useEffect(() => {
    if (roles === false) {
      getUserRoles()
        .then((_roles) => {
          if (_roles && _roles.admin === true) {
            setRoles(true);
          } else {
            setRoles(false);
            history.push("/Unauthorized");
          }
        })
        .catch((error) => {});
    }
  }, [auth]);
  const handleDataChangeOnSelect = (selectedDeal,dealIds) => {
    setSelectedDeal(selectedDeal);
    setSelectedIds(dealIds)
  };
  const onFilterChange = (data) => {
    getDealsByFilter(data.status, data.fromDate, data.toDate,data.dealId)
      .then((_deals) => {
        setDeals(_deals);
        setColumns(GetDataTableColumns());
      })
      .catch((error) => { setDeals([]);});
  };

  const onPurgeSelectedItems = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    let _dealsToDelete = deals.filter((_deal) => selectedIds.includes(_deal.id));
    for (let index = 0; index < _dealsToDelete.length; index++) {
      const element = _dealsToDelete[index];
      purgeDeal(element)
      .then(() => {
        setSnackbarData({
          ...snackbarData,
          message: t("SUCCESS_MSG", { ns: "translation" }),
          open: true,
          severity: "success",
        });
      })
      .catch((error) => {
        setSnackbarData({
          ...snackbarData,
          message: t("ERROR_MSG", { ns: "translation" }),
          open: true,
          severity: "error",
        });
      });
      
    }


  };
  
  return (
    <>
      {roles === true ? (
        <>
          <div className={classes.root}>
            <Card>
              <CardHeader title="Filter" />
              <CardContent>
                <AdminDataTableFilter onFilterChange={onFilterChange} />
              </CardContent>
            </Card>
          </div>
          <div className={classes.root}>
          <Controls.GenericButton
              disabled={selectedIds.length===0}
              text={"PURGE SELECTED DEALS"}
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: t("DELETE_DEAL_CONFIRM_MSG", {
                    ns: "translation",
                  }),
                  subTitle: t("CONFIRM_SUB_TITLE_MSG", {
                    ns: "translation",
                  }),
                  onConfirm: () => {
                    onPurgeSelectedItems();
                  },
                });
              }}
            />
            <Card>
              <CardHeader title="Admin dashboard" >

              </CardHeader>
              <CardContent>
                {deals && columns ? (
                  <>
                    <AdminDataTable
                      data={deals}
                      columns={columns}
                      handleDataChangeOnSelect={handleDataChangeOnSelect}

                    />
                  </>
                ) : null}
              </CardContent>
            </Card>
          </div>
          <div className={classes.root}>
            <Card>
              <CardHeader title="Update deal" />
              <CardContent>
                {deals && columns && selectedDeal && selectedDeal.id ? (
                  <>
                    <AdminManageDeal targetDeal={selectedDeal} />
                  </>
                ) : null}
              </CardContent>
            </Card>
          </div>
          <SnackbarComponent
        message={snackbarData.message}
        open={snackbarData.open}
        severity={snackbarData.severity}
        onClose={SnackbarComponentChange}
      />
          <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
        </>
      ) : null}
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.firebase.auth,
  };
}

const mapDispatchToProps = { getDealsByFilter, getUserRoles ,purgeDeal};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDashboardContainer);
