import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import DealCard from "./DealCard";
import TabFilters from "../../common/TabFilters";
import TabGroups from "../../common/TabGroups";
import LoaderComponent from "../../common/LoaderComponent";
import ContactComponent from "../../common/ContactComponent";

import InformativeMsgComponent from "../../common/InformativeMsgComponent";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MerchantListContainer from "../../merchant/carousel/MerchantListContainer";
import {
  GetGroupKeysFromGroupUrl,
  GetOrderByKeysFromUrl,
  GenerateGroupKeywords,
} from "../../../helpers/modelsHelper";
import PageTagsComponent from "../../common/PageTagsComponent";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: "1%",
  },
  paperGrid: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  paperAds: {
    padding: theme.spacing(3),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  merchantPapper: {
    position: "sticky",
    top: 0,
    paddingTop: "3%",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: "2%",
  },
}));

export function DealsContainer({ deals, groupKey,orderbyKey, loading, ...props }) {
  const classes = useStyles();
  const { t } = useTranslation([ "translation"]);
  useEffect(() => {}, [groupKey,orderbyKey, deals]);

  return (
    <>
      <TabGroups />
      <TabFilters />
      <PageTagsComponent
        title={groupKey}
        description={"GENERAL_DESCRIPTION"}
        keywords={GenerateGroupKeywords(groupKey)}
      />
      <Container>
        <div className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Paper variant="outlined" className={classes.paperAds}>
                {" "}
                <ContactComponent
                  titleKey={"LBL_ADVERTISE_WITH_US"}
                  msgKey={"LBL_ADVERTISE_WITH_MSG"}
                  emailKey={"LBL_ADVERTISING_EMIAL"}
                />
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={3}
              lg={3}
              className={classes.paperGrid}
            >
              <Paper variant="outlined" className={classes.paper}>
              {t("LBL_ADVERTISING_SPACE", { ns: "translation" })}
              </Paper>
            </Grid>

            <Grid item xs={12} sm={8} md={6} lg={6}>
              {loading ? (
                <LoaderComponent />
              ) : (
                <>
                  {deals
                    ? deals.map((deal, index) =>
                        index === 2 ? (
                          <div key={index}>
                            <Paper variant="outlined" className={classes.paper}>
                            {t("LBL_ADVERTISING_SPACE", { ns: "translation" })}
                            </Paper>
                            <DealCard currentDeal={deal} />
                          </div>
                        ) : (
                          <DealCard key={index} currentDeal={deal} />
                        )
                      )
                    : null}
                </>
              )}

              {loading === false && deals && deals.length === 0 ? (
                <InformativeMsgComponent msgKey="NO_DEALS_TO_DISPLAY" />
              ) : (
                <></>
              )}
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={3}>
              <Paper variant="outlined" className={classes.paper}>
                <MerchantListContainer />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
}

DealsContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  const group = ownProps.match.params.group;
  const orderby = ownProps.match.params.orderby;
  let groupKey = GetGroupKeysFromGroupUrl(group);
  let orderbyKey = GetOrderByKeysFromUrl(orderby);
  
  return {
    deals: state.dealReducer.deals,
    groupKey: groupKey,
    orderbyKey :orderbyKey,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(DealsContainer);
