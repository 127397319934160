import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";
import {
  GetDealImgUrl,
  GetDiscountPercentage,
  GetShippingValue,
} from "../../../helpers/modelsHelper";
import Link from "@material-ui/core/Link";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import { useTranslation } from "react-i18next";
import Image from "material-ui-image";
import {
  EmitCustomEvent,
  SeoCustomEvents,
  ConvertDealToAnalyticItemArray,
} from "../../../helpers/seoHelper";
import Chip from "@material-ui/core/Chip";
import {
  BuildUtmUrl
} from "../../../helpers/utmHelper";
import { useParams } from 'react-router';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
  },
  image: {
    width: 150,
    height: 150,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  oldPrice: {
    textDecoration: "line-through",
    color: grey[600],
  },
  newPrice: {
    color: "#d50000",
    fontWeight: "bold",
  },
  discount: {
    color: grey[600],
  },
}));

export default function DealDetailInfo({ deal, ...props }) {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);
  const { group  } = useParams();
  const handleClick = (event) => {
    EmitCustomEvent(SeoCustomEvents.view_item_url, {
      currency: deal.dealCurrency.toString(),
      value: Number(deal.newPrice.toString()),
      items: ConvertDealToAnalyticItemArray(deal),
    });
    let _urlToOpen = BuildUtmUrl(deal.dealUrl, group , "deal-details")
    window.open(_urlToOpen);
  };

  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item>
            <div className={classes.image}>
              <Image src={GetDealImgUrl(deal)} alt={deal.title} />
            </div>
          </Grid>
          <Grid item xs={12} sm container spacing={3}>
            <Grid item xs container direction="column" spacing={2}>
              
              <Grid item>
                <Typography gutterBottom variant="subtitle1">
                  <strong>{deal.title}</strong>
                </Typography>
                
              </Grid>
              <Grid item>
              <Chip
                      color={
                        deal.status === "ACTIVE" || deal.status === "VERIFIED"
                          ? "primary"
                          : "secondary"
                      }
                      variant="outlined"
                      size="small"
                      label={t(deal.status, { ns: "translation" })}
                    />
                <Typography variant="subtitle1">
                  <span className={classes.oldPrice}>
                    {deal.oldPrice}
                    {t(deal.dealCurrency)}
                  </span>
                  <span> | </span>
                  <span className={classes.newPrice}>
                    {deal.newPrice}
                    {t(deal.dealCurrency)}
                  </span>
                  <span> | </span>
                  <span>
                    {GetDiscountPercentage(deal.oldPrice, deal.newPrice)}
                  </span>
                </Typography>
                
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ cursor: "pointer" }}
                >
                  {t("LBL_DEALS_BY")} :
                  <Link onClick={handleClick}>{deal.merchantName}</Link>
                </Typography>

                <Typography variant="body2" color="textSecondary">
                  {t("LBL_MEANS_OF_PAYMENT")} :
                  {deal.paymentMethod.map((item) => t(item)).join(" | ")}
                </Typography>
                <Typography
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                  variant="body2"
                  color="textSecondary"
                >
                  <span
                    style={{
                      paddingRight: "5px",
                    }}
                  >
                    {GetShippingValue(deal, t)}
                  </span>
                  <LocalShippingIcon />
                </Typography>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
