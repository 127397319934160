import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { GetDealImgUrl ,GetDiscountPercentage , GetGroupUrlFromGroupKey,GetVoteColor} from "../../../helpers/modelsHelper";
import Typography from '@material-ui/core/Typography';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  EmitReferenceEvent,
  SeoReferenceEvents,
  ConvertDealToAnalyticItemArray,
} from "../../../helpers/seoHelper";

const useStyles = makeStyles((theme) => ({
  oldPrice: {
    textDecoration: "line-through",
    color: grey[600],
  },
  newPrice: {
    color: "#d50000",
    fontWeight: "bold",
  },
  discount: {
    color: "black",
    fontWeight: "bold",
  },  
  dealScore: {
    color: "#d50000",
    fontWeight: "bold",
    float:"right",
    position:"absolute",
    backgroundColor:"white",
    borderRadius:"5px"
  }
}));

export function Card({ deal }) {
  const visibility = React.useContext(VisibilityContext);
  const classes = useStyles();
  const visible = visibility.isItemVisible(deal.id);
  const { t } = useTranslation(["translation"]);
  let history = useHistory();
  const handleClick = () => {
    let groupUrl = GetGroupUrlFromGroupKey(deal.dealGroupKey[0]);
    history.push("/deal/" + groupUrl + "/details/" + deal.id);
    EmitReferenceEvent(SeoReferenceEvents.view_item, {
      currency: deal.dealCurrency.toString(),
      value: Number(deal.newPrice.toString()),
      items: ConvertDealToAnalyticItemArray(deal),
    });
  };
  return (
    <div
      role="button"
      style={{
        display: "inline-block",
        margin: "0 2px",
        width: "130px",
        height: "240px",
        userSelect: "none",
        cursor: "pointer",
        position:"relative"
      }}
      tabIndex={0}
      className="card"
      onClick={handleClick}
    >
            <p
              className={classes.dealScore}
              style={GetVoteColor(deal.vote)}
            >
              {deal.vote}°
            </p>
       <img src={GetDealImgUrl(deal)} alt={deal.title}  style={{
          width: "100px",
          height: "100px",
        }}/>
      <div>
      <Typography variant="caption" display="block" gutterBottom>
      {deal.title.substring(0, 50)+"..."}
      </Typography>
      <Typography variant="subtitle1">
                  <span className={classes.newPrice}>
                    {deal.newPrice} {" "}
                    {t(deal.dealCurrency)} {" "}
                  </span>
      </Typography>
      
      <Typography variant="caption">
                  <span className={classes.oldPrice}>
                    {deal.oldPrice} {" "} {" "}
                    {t(deal.dealCurrency)} {" "}
                  </span>
                  <span className={classes.discount}>
                    {GetDiscountPercentage(deal.oldPrice, deal.newPrice)}
                  </span>
      </Typography>
      </div>
    </div>
  );
}
