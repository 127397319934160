import { useEffect } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import PropTypes from "prop-types";
import {
  GetDiscountPercentage,
  GetDealImgUrl,
  GetShippingValue,
  ConvertDateToDateString,
} from "../../../../helpers/modelsHelper";
import Link from "@material-ui/core/Link";
import { useTranslation } from "react-i18next";
import Image from "material-ui-image";
import { DropzoneDialog } from "material-ui-dropzone";
import Button from "@material-ui/core/Button";
import { uploadImageToUserImageFolder } from "../../../../redux/actions/storageActions";
import { guidGenerator } from "../../../../helpers/modelsHelper";
import Resizer from "react-image-file-resizer";
import { connect } from "react-redux";
import {
  EmitCustomEvent,
  SeoCustomEvents,
  ConvertDealToAnalyticItemArray,
} from "../../../../helpers/seoHelper";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  oldPrice: {
    textDecoration: "line-through",
    color: grey[600],
  },
  newPrice: {
    color: "#d50000",
    fontWeight: "bold",
  },
  discount: {
    color: grey[600],
  },
  updateImg: {
    width: "100%",
    height: "100%",
  },
}));

function DealPreviewInfo({
  currentDeal,
  onImgUrlChange,
  auth,
  uploadImageToUserImageFolder,
  ...props
}) {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);
  const [open, setOpen] = React.useState(false);
  const [submit, setSubmit] = React.useState(false);
  const [imgUrl, setImgUrl] = React.useState("");
  const [imgId, setImgId] = React.useState(guidGenerator());

  useEffect(() => {
    setImgUrl(GetDealImgUrl(currentDeal));
  }, [currentDeal]);

  function calbackFn(fireBaseUrl) {
    setImgUrl(fireBaseUrl);
    setOpen(false);
    setSubmit(false);
    onImgUrlChange(fireBaseUrl);
    EmitCustomEvent(SeoCustomEvents.update_item_img);
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={4} sm={4} md={4}>
          <Grid item>
            <Image src={imgUrl} />
          </Grid>
          <Grid item>
            <Button
              className={classes.updateImg}
              variant="outlined"
              color="primary"
              onClick={() => setOpen(true)}
              disabled={currentDeal.dealUrl === "" ? true : false}
            >
              update image
            </Button>

            <DropzoneDialog
              acceptedFiles={["image/*"]}
              cancelButtonText={"cancel"}
              submitButtonText={
                submit ? "Pealse wait, submiting ..." : "submit"
              }
              maxFileSize={3000000}
              open={open}
              onClose={() => setOpen(false)}
              onSave={(files) => {
                setSubmit(true);
                try {
                  Resizer.imageFileResizer(
                    files[0],
                    150,
                    150,
                    "JPEG",
                    100,
                    0,
                    (newFile) => {
                      uploadImageToUserImageFolder(
                        auth.uid,
                        newFile,
                        imgId,
                        calbackFn
                      );
                    },
                    "file",
                    100,
                    100
                  );
                } catch (err) {}
              }}
              showPreviews={false}
              showFileNamesInPreview={false}
              showPreviewsInDropzone={true}
              filesLimit={1}
            />
          </Grid>
        </Grid>

        <Grid item xs={8} sm={8} md={8}>
          <Grid item container direction="column" spacing={2}>
            <Grid item>
              <strong> {currentDeal.title} </strong>
              <Typography variant="body2" color="textSecondary">
                {currentDeal.createAt === undefined
                  ? null
                  : ConvertDateToDateString(currentDeal.createAt)}
              </Typography>
              <Typography variant="subtitle1">
                <span className={classes.oldPrice}>
                  {currentDeal.oldPrice} {t(currentDeal.dealCurrency)}
                </span>
                <span> | </span>
                <span className={classes.newPrice}>
                  {currentDeal.newPrice} {t(currentDeal.dealCurrency)}
                </span>
                <span> | </span>
                <span>
                  {GetDiscountPercentage(
                    currentDeal.oldPrice,
                    currentDeal.newPrice
                  )}
                </span>
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                style={{ cursor: "pointer" }}
              >
                {t("LBL_DEALS_BY")} :
                <Link
                  href={currentDeal.merchantBaseUrl ? null : "#"}
                  onClick={(event) => event.preventDefault()}
                >
                  {currentDeal.merchantName}
                </Link>
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {t("LBL_MEANS_OF_PAYMENT")} :
                {currentDeal.paymentMethod.map((item) => t(item)).join(" | ")}
              </Typography>
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
                variant="body2"
                color="textSecondary"
              >
                <span
                  style={{
                    paddingRight: "5px",
                  }}
                >
                  {GetShippingValue(currentDeal, t)}
                </span>
                <LocalShippingIcon />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

DealPreviewInfo.propTypes = {
  currentDeal: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  let auth = state.firebase.auth;

  return {
    auth,
  };
}

const mapDispatchToProps = {
  uploadImageToUserImageFolder,
};
export default connect(mapStateToProps, mapDispatchToProps)(DealPreviewInfo);
