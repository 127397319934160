import * as types from "../actions/actionTypes";

const initialState = {
  filteredDeals: [],
  initialDeals: [],
  filterPattern: "",
  dealsDomain: "",
  sortType: types.SORT_FOR_YOU_DEALS,
};

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_FILTER_GROUP_DEAL_OK:
      return {
        ...state,
        dealsDomain: action.dealsDomain,
        initialDeals: action.initialDeals,
        filteredDeals:
          action.dealsDomain !== state.dealsDomain ? [] : state.filteredDeals,
        filterPattern:
          action.dealsDomain !== state.dealsDomain ? "" : state.filterPattern,
      };

    case types.FILTER_GROUP_DEAL_OK: {
      if (state.initialDeals && action.filterPattern) {
        return {
          ...state,
          filteredDeals: filterDeals(state.initialDeals, action.filterPattern),
          filterPattern: action.filterPattern,
        };
      }
      return { ...state, filteredDeals: [], filterPattern: "" };
    }
    case types.UPDATE_FILTER_GROUP_DEAL_OK: {
      let initialDealsUpdated = state.initialDeals.map((_deal) =>
        _deal.id === action.targetDeal.id
          ? { ...action.targetDeal, title: _deal.title }
          : _deal
      );

      let filteredDealsUpdated = state.filteredDeals.map((_deal) =>
        _deal.id === action.targetDeal.id
          ? { ...action.targetDeal, title: _deal.title }
          : _deal
      );

      return {
        ...state,
        filteredDeals: filteredDealsUpdated,
        initialDeals: initialDealsUpdated,
      };
    }
    case types.SORT_PLUS_HOT_DEALS:
      return {
        ...state,
        sortType: types.SORT_PLUS_HOT_DEALS,
      };

    case types.SORT_FOR_YOU_DEALS:
      return {
        ...state,
        sortType: types.SORT_FOR_YOU_DEALS,
      };

    case types.SORT_WITHOUT_VOTE_DEALS:
      return {
        ...state,
        sortType: types.SORT_WITHOUT_VOTE_DEALS,
      };

      case types.SORT_CREATE_AT_DEALS:
        return {
          ...state,
          sortType: types.SORT_CREATE_AT_DEALS,
        };
    default:
      return state;
  }
}

function filterDeals(data, str) {
  const newArr = data
    .filter((item) => item.title.toLowerCase().includes(str.toLowerCase()))
    .map((item) => {
      let newTitle = item.title.replace(
        new RegExp(str, "gi"),
        (match) =>
          `<mark style="background: #FFA500; color: white;">${match}</mark>`
      );
      return {
        ...item,
        title: newTitle,
      };
    });

  return newArr;
}

function SortDeals(a, b, by) {
  let itemA = a[by];
  let itemB = b[by];
  if (itemA < itemB) return -1;
  if (itemA > itemB) return 1;
  return 0;
}
