import "firebase/analytics";
import firebase from "firebase/app";

export enum SeoCustomEvents {
  update_item_vote = "update_item_vote", // ok
  delete_item = "delete_item", //ok
  update_item_comment = "update_item_comment", // ok
  add_item_comment = "add_item_comment", // ok
  update_item_img = "update_item_img", // ok
  add_item = "add_item", // ok
  update_item = "update_item", // ok
  copy_item_url = "copy_item_url", // ok
  report_item = "report_item", // ok
  remove_from_wishlist = "remove_from_wishlist", // ok
  view_item_url = "view_item_url", // ok
  view_item_comments = "view_item_comments", // ok
  view_item_comments_page = "view_item_comments_page", // ok
  view_item_video = "view_item_video", // ok
  advertise_with_us = "advertise_with_us", // ok
  join_us = "join_us", // ok
  sort_items = "sort_items", // ok
  render_exception = "render_exception", // ok
  api_exception = "api_exception",
  scrolled_items = "scrolled_items",
  share_item = "share_item",
}

export enum SeoReferenceEvents {
  sign_up = "sign_up", // ok
  login = "login", // ok
  page_view = "page_view", // ok
  add_to_wishlist = "add_to_wishlist", // ok
  view_item = "view_item", // ok
  search = "search", // ok
}

const analytics = firebase.analytics();

export function EmitReferenceEvent(eventId: SeoReferenceEvents, jsonData: any) {
  /* console.log("EmitReferenceEvent");
  console.log(eventId.toString());
  console.log(jsonData);*/
  analytics.logEvent(eventId.toString(), jsonData);
}

export function EmitCustomEvent(eventId: SeoCustomEvents, jsonData: any) {
  /*console.log("EmitCustumEvent");
  console.log(eventId.toString());
  console.log(jsonData);*/
  analytics.logEvent(eventId.toString(), jsonData);
}

export function ConvertDealToAnalyticItemArray(deal: any) {
  let itemToReturn = new Item();
  itemToReturn.item_id = deal.id;
  itemToReturn.item_name = deal.title;
  itemToReturn.affiliation = deal.merchantName;
  itemToReturn.coupon = deal.promoCode;
  itemToReturn.currency = deal.dealCurrency.toString();
  itemToReturn.item_category = deal.dealGroupKey[0];
  itemToReturn.discount = Number(deal.oldPrice) - Number(deal.newPrice);
  itemToReturn.price = Number(deal.newPrice);
  return [itemToReturn];
}

class Item {
  item_id: string = "";
  item_name: string = "";
  affiliation: string = "";
  coupon: string = "";
  currency: string = "";
  item_category: string = "";
  discount: number = 0;
  price: number = 0;
}
