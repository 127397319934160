import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { GetEmptyComment } from "../../../helpers/modelsHelper";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  saveComment,
  getCommentByUserAndDealId,
} from "../../../redux/actions/commentActions";
import SignInSide from "../../authentication/SignInSide";
import { EmitCustomEvent, SeoCustomEvents } from "../../../helpers/seoHelper";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#293847",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  addComment: {
    width: "100%",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    padding: theme.spacing(4),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddComment({
  auth,
  targetComment,
  loading,
  saveComment,
  getCommentByUserAndDealId,
  deal,
  ...props
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState(targetComment);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation(["translation"]);
  useEffect(() => {
    if (deal && deal.id) {
      setComment({ ...comment, dealId: deal.id });
    }
  }, [deal, auth]);

  const handleClickOpen = () => {
    if (auth && auth.uid) {
      getCommentByUserAndDealId(deal.id, auth.uid)
        .then((_commment) => {
          if (_commment) {
            setComment(_commment);
          }
          setOpen(true);
        })
        .catch((error) => {
          setOpen(true);
        });
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputFormChange = (event) => {
    setComment({ ...comment, [event.target.name]: event.target.value });
  };

  const formIsValid = () => {
    let _errors = {};

    if (!comment.body) _errors.body = t("LBL_REQUIRED");
    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formIsValid()) return;
    if (auth && auth.uid) {
      if (comment.id) {
        EmitCustomEvent(SeoCustomEvents.update_item_comment);
      } else {
        EmitCustomEvent(SeoCustomEvents.add_item_comment);
      }

      setSaving(true);
      saveComment({ ...comment, dealId: deal.id })
        .then((_comment) => {
          setComment(_comment);
          setOpen(false);
          setSaving(false);
        })
        .catch((error) => {
          setSaving(false);
        });
    }
  };

  const handleInputValidationOnBlur = (event) => {
    let _errors = {};
    switch (event.target.name) {
      case "body":
        if (!comment.body) _errors.body = t("LBL_REQUIRED");
        break;
      default:
        break;
    }
    setErrors({ ...errors, [event.target.name]: _errors[event.target.name] });
  };

  return (
    <div>
      <Button
        className={classes.addComment}
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
      >
        {comment.id ? t("LBL_UPDATE_COMMENT") : t("LBL_ADD_COMMENT")}
      </Button>
      {auth && auth.uid ? (
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}></Typography>
              <Button
                autoFocus
                color="inherit"
                variant="outlined"
                text={saving ? "Saving..." : t("BTN_SUBMIT")}
                disabled={saving}
                onClick={handleSubmit}
              >
                {t("BTN_SUBMIT")}
              </Button>
            </Toolbar>
          </AppBar>
          <form
            className={classes.form}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="h6" variant="h5">
                  {t("LBL_COMMENT_MSG")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={10}
                  variant="outlined"
                  label={t("LBL_COMMENT")}
                  name="body"
                  error={errors.body != null}
                  helperText={errors.body}
                  value={comment.body}
                  onBlur={handleInputValidationOnBlur}
                  onChange={handleInputFormChange}
                />
              </Grid>
            </Grid>
          </form>
        </Dialog>
      ) : (
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {t("CONNECTED_USER_REQUIRED")}
              </Typography>
            </Toolbar>
          </AppBar>
          <SignInSide closePopup={true} onClosePopup={handleClose} />
        </Dialog>
      )}
    </div>
  );
}

AddComment.propTypes = {
  loading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  let auth = state.firebase.auth;
  let targetComment = GetEmptyComment(auth);
  return {
    auth,
    targetComment,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  saveComment,
  getCommentByUserAndDealId,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddComment);
