import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import { LanguagesValues } from "./models";

const userSavedLng = window.localStorage.getItem("i18nextLng");
// eslint-disable-next-line no-lone-blocks
{
  if (!userSavedLng) {
    window.localStorage.setItem("i18nextLng", LanguagesValues.FR);
  }
}
const fallbackLng = [LanguagesValues.FR];
const availableLanguages = [
  LanguagesValues.EN,
  LanguagesValues.FR,
  LanguagesValues.AR,
  LanguagesValues.TN,
];
i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: fallbackLng,
    debug: false,
    nsSeparator: ":",
    keySeparator: ".",
    ns: ["dealfilters", "dealgroups", "translation", "useraccount", "metatags"],
    whitelist: availableLanguages,

    defaultNS: "translation",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
  });

i18n.reloadResources(availableLanguages);
export default i18n;
