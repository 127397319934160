import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";
import AdminDealPreviewInfo from "./AdminDealPreviewInfo";
import PropTypes from "prop-types";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Chip from "@material-ui/core/Chip";
import { GetGroupUrlFromGroupKey } from "../../../helpers/modelsHelper";
import Button from "@material-ui/core/Button";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import SnackbarComponent from "../../common/SnackbarComponent";
import {
  updateDealWithFbPost,
  purgeDeal,
} from "../../../redux/actions/adminActions";

import ConfirmDialog from "../../common/ConfirmDialog";
import FacebookIcon from "@material-ui/icons/Facebook";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    maxHeight: 800,
    margin: theme.spacing(1),
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    marginRight: "auto",
  },
  avatar: {
    backgroundColor: grey[500],
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  controls: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
  },
  button: {
    marginLeft: "auto",
  },
  dealScore: {
    color: "#d50000",
    fontWeight: "bold",
  },
}));

export function AdminDealPreviewCard({
  currentDeal,
  auth,
  onImgUrlChange,
  purgeDeal,
  updateDealWithFbPost,
  ...props
}) {
  const { t } = useTranslation(["translation"]);
  const classes = useStyles();
  const handleClick = (event) => {
    let groupUrl = GetGroupUrlFromGroupKey(currentDeal.dealGroupKey[0]);
    window.open("/deal/" + groupUrl + "/details/" + currentDeal.id);
  };

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    open: false,
    severity: "error",
  });
  function SnackbarComponentChange() {
    setSnackbarData({ ...snackbarData, message: null, open: false });
  }
  const onDelete = (item) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    purgeDeal(item)
      .then(() => {
        setSnackbarData({
          ...snackbarData,
          message: t("SUCCESS_MSG", { ns: "translation" }),
          open: true,
          severity: "success",
        });
      })
      .catch((error) => {
        setSnackbarData({
          ...snackbarData,
          message: t("ERROR_MSG", { ns: "translation" }),
          open: true,
          severity: "error",
        });
      });
  };

  const onUpdateFb = (item) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    updateDealWithFbPost(item.id)
      .then(() => {
        setSnackbarData({
          ...snackbarData,
          message: t("SUCCESS_MSG", { ns: "translation" }),
          open: true,
          severity: "success",
        });
      })
      .catch((error) => {
        setSnackbarData({
          ...snackbarData,
          message: t("ERROR_MSG", { ns: "translation" }),
          open: true,
          severity: "error",
        });
      });
  };

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          action={<WhatshotIcon style={{ color: "#d50000" }} />}
          subheader={currentDeal.createAt + ""}
        />

        <AdminDealPreviewInfo
          currentDeal={currentDeal}
          onImgUrlChange={onImgUrlChange}
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {currentDeal.description}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <Chip
            className={classes.chipClass}
            avatar={<Avatar>{currentDeal.ownerInitials}</Avatar>}
            label={currentDeal.ownerPseudo}
            variant="outlined"
          />

          <IconButton
            color="primary"
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: t("ADD_FB_POST_DEAL_CONFIRM_MSG", {
                  ns: "translation",
                }),
                subTitle: t("CONFIRM_SUB_TITLE_MSG", {
                  ns: "translation",
                }),
                onConfirm: () => {
                  onUpdateFb(currentDeal);
                },
              });
            }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: t("DELETE_DEAL_CONFIRM_MSG", {
                  ns: "translation",
                }),
                subTitle: t("CONFIRM_SUB_TITLE_MSG", {
                  ns: "translation",
                }),
                onConfirm: () => {
                  onDelete(currentDeal);
                },
              });
            }}
          >
            <DeleteIcon />
          </IconButton>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<ChevronRightIcon />}
            onClick={handleClick}
          >
            {t("BTN_DETAILS")}
          </Button>
        </CardActions>
      </Card>
      <SnackbarComponent
        message={snackbarData.message}
        open={snackbarData.open}
        severity={snackbarData.severity}
        onClose={SnackbarComponentChange}
      />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}


function mapStateToProps(state) {
  let auth = state.firebase.auth;
  return {
    auth,
  };
}

const mapDispatchToProps = { purgeDeal, updateDealWithFbPost };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDealPreviewCard);
