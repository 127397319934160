import * as types from "./actionTypes";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as commentsApi from "../../core/api/commentApi";

export function createCommentSuccess(dealId, comment) {
  return { type: types.CREATE_COMMENT_SUCCESS, dealId, comment };
}

export function updateCommentSuccess(dealId, comment) {
  return { type: types.UPDATE_COMMENT_SUCCESS, dealId, comment };
}

export function loadCommentsSuccess(dealId, comments) {
  return { type: types.LOAD_COMMENTS_SUCCESS, dealId, comments };
}

export function loadCommentsFailer(dealId) {
  return { type: types.LOAD_COMMENTS_SUCCESS, dealId, comments: [] };
}

export function loadCommentSuccess(comment) {
  return { type: types.LOAD_COMMENT_SUCCESS, comment };
}

export function loadCommentFailer() {
  return { type: types.LOAD_COMMENT_SUCCESS, comment: null };
}

export function loadComments(dealId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return commentsApi
      .getCommentsByDealId(dealId)
      .then((comments) => {
        dispatch(loadCommentsSuccess(dealId, comments));
        return comments;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        dispatch(loadCommentsFailer(dealId));
        throw error;
      });
  };
}

export function getCommentByUserAndDealId(dealId, userId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return commentsApi
      .getCommentByUserAndDealId(dealId, userId)
      .then((_comment) => {
        dispatch(loadCommentSuccess(_comment));
        return _comment;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        dispatch(loadCommentFailer());
        throw error;
      });
  };
}

export function saveComment(comment) {
  return function (dispatch) {
    dispatch(beginApiCall());
    if (comment.id) {
      return commentsApi
        .updateComment(comment)
        .then((_comment) => {
          dispatch(updateCommentSuccess(_comment.dealId, _comment));
          return _comment;
        })
        .catch((error) => {
          dispatch(apiCallError(error));
          throw error;
        });
    } else {
      return commentsApi
        .createComment(comment)
        .then((_comment) => {
          dispatch(createCommentSuccess(_comment.dealId, _comment));
          return _comment;
        })
        .catch((error) => {
          dispatch(apiCallError(error));
          throw error;
        });
    }
  };
}
