import { Route, Redirect } from "react-router-dom";

const AuthGuardIsConnected = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth && auth.uid ? <Component {...props} /> : <Redirect to="/sign-in" />
    }
  />
);

export default AuthGuardIsConnected;
