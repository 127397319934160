import { DealModel } from "./models";

export function GetDataTableColumns() {
  let Columns = [];
  let deal = new DealModel();
  let ColumnsToKeep = [
    "id",
    "title",
    "createAt",
    "expireAt",
    "merchantName",
    "ownerPseudo",
    "status",
    "updateAt",
  ];
  for (let [key] of Object.entries(deal)) {
    if (ColumnsToKeep.indexOf(key) >= 0) {
      Columns.push({
        hide: false,
        field: key,
        headerName: key,
        width: 180,
        editable: true,
      });
    } else {
      Columns.push({
        hide: true,
        field: key,
        headerName: key,
        width: 180,
        editable: true,
      });
    }
  }
  return Columns;
}
