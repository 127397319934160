import * as React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { GetEmptyDeal } from "../../helpers/modelsHelper";

export default function AdminDataTable({
  columns,
  data,
  handleDataChangeOnSelect,
  ...props
}) {
  const [selection, setSelection] = React.useState([]);
  const handleSelectionChange = (ids) => {

    if (ids.length === 0) {
      setSelection([]);
      handleDataChangeOnSelect(GetEmptyDeal(),[]);
      return ;
    }

    if (ids.length === 1) {
      let deal = data.filter((row) => row.id === ids[ids.length-1])[0];
      handleDataChangeOnSelect(deal,ids);
      setSelection([ids[ids.length-1]])
    }
    
    if (ids.length > 1) {     
      handleDataChangeOnSelect(GetEmptyDeal(),ids);
      setSelection(ids);
    }


  };
  return (
    <div style={{ height: 600, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[50]}
        checkboxSelection
        disableSelectionOnClick
        selectionModel={selection}
        onSelectionModelChange={handleSelectionChange}
      />
    </div>
  );
}
