import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Controls from "../common/controls/Controls";
import SnackbarComponent from "../common/SnackbarComponent";
import { connect } from "react-redux";
import { signIn } from "../../redux/actions/authActions";
import PropTypes from "prop-types";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import { IsValidEmail } from "../../helpers/modelsHelper";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CopyrightComponent from "../common/CopyrightComponent";
import LoaderComponent from "../../components/common/LoaderComponent";
import PageTagsComponent from "../../components/common/PageTagsComponent";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/img/sign-in.svg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "80%",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export function SignInSide({
  auth,
  loading,
  authError,
  signIn,
  closePopup = false,
  onClosePopup,
  ...props
}) {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});
  const [signingIn, setSigningIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    message: authError,
    open: authError ? true : false,
    severity: "error",
  });
  let history = useHistory();

  useEffect(() => {
    setSnackbarData({
      severity: authError ? "error" : "success",
      message: authError,
      open: authError ? true : false,
    });
  }, [authError, loading]);

  const handleInputFormChange = (event) => {
    setCredentials({ ...credentials, [event.target.name]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formIsValid = () => {
    let _errors = {};
    if (!credentials.email) _errors.email = t("LBL_REQUIRED");
    if (!credentials.password) _errors.password = t("LBL_REQUIRED");
    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formIsValid()) return;
    setSigningIn(true);
    signIn(credentials)
      .then(() => {
        if (closePopup) {
          onClosePopup();
        } else {
          history.push("/");
        }
      })
      .catch((err) => {
        setSigningIn(false);
      });
  };

  const handleInputValidationOnBlur = (event) => {
    let _errors = {};
    switch (event.target.name) {
      case "email":
        if (!credentials.email) _errors.email = t("LBL_REQUIRED");
        else if (!IsValidEmail(credentials.email)) {
          _errors.email = t("LBL_FORMAT_INCORRECT");
        }
        break;
      case "password":
        if (!credentials.password) _errors.password = t("LBL_REQUIRED");
        break;
      default:
        _errors[event.target.name] = PropIsRequired(event.target.name);
        break;
    }
    setErrors({ ...errors, [event.target.name]: _errors[event.target.name] });
  };

  const PropIsRequired = (propName) => {
    if (!credentials[propName]) return t("LBL_REQUIRED");
  };

  function SnackbarComponentChange() {
    setSnackbarData({ ...snackbarData, message: null, open: false });
  }

  if (auth && auth.uid) return <Redirect to="/" />;

  return (
    <Grid container component="main" className={classes.root}>
      <PageTagsComponent title={"SIGN_IN"} />
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h4">
            {t("SIGN_IN")}
          </Typography>
          <Typography component="h5" variant="subtitle1" align="center">
            {t("ACCEPT_TERMS")}
          </Typography>
          <form
            className={classes.form}
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
          >
            <Controls.GenericTextInput
              variant="outlined"
              margin="normal"
              fullWidth
              label={t("LBL_EMAIL")}
              name="email"
              value={credentials.email}
              onChange={handleInputFormChange}
              error={errors.email}
              onBlur={handleInputValidationOnBlur}
              required
            />

            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                {t("LBL_PASSWORD")}
              </InputLabel>
              <OutlinedInput
                type={showPassword ? "text" : "password"}
                value={credentials.password}
                name="password"
                fullWidth
                onChange={handleInputFormChange}
                onBlur={handleInputValidationOnBlur}
                error={errors.password ? true : false}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
              {!!errors.password && (
                <FormHelperText error id="accountId-error">
                  {errors.password}
                </FormHelperText>
              )}
            </FormControl>

            <Controls.GenericButton
              disabled={signingIn}
              text={signingIn ? t("SIGN_IN") + "..." : t("SIGN_IN")}
              type="submit"
              className={classes.submit}
            />
            <Grid container>
              <Grid item xs>
                <Link href="/forget-pwd" variant="body2">
                  {t("FORGET_PWD")}
                </Link>
              </Grid>
              <Grid item>
                <Link href="/sign-up" variant="body2">
                  {t("DONT_HAVE_ACCOUNT")}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <CopyrightComponent />
              {signingIn ? <LoaderComponent /> : null}
            </Box>
          </form>
        </div>
      </Grid>

      <SnackbarComponent
        message={snackbarData.message}
        open={snackbarData.open}
        severity={snackbarData.severity}
        onClose={SnackbarComponentChange}
      />
    </Grid>
  );
}

SignInSide.propTypes = {
  loading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.firebase.auth,
    loading: state.apiCallsInProgress > 0,
    authError: state.auth ? state.auth.authError : null,
  };
}

const mapDispatchToProps = {
  signIn,
};
export default connect(mapStateToProps, mapDispatchToProps)(SignInSide);
