import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DealPreviewCard from "./DealPreviewCard";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
    },
  },
}));

export default function UserDealPreview({ deal, onImgUrlChange, ...props }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h4">Preview</Typography>
      {deal ? (
        <DealPreviewCard currentDeal={deal} onImgUrlChange={onImgUrlChange} />
      ) : null}
    </div>
  );
}
