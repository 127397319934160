import axios from "../config/api.config";
import * as config from "../config/api.config";
import { handleResponse, handleError } from "../helper/apiUtils";

export async function getCommentsByDealId(dealId: string) {
  await config.GetUserToken();
  return axios
    .get(config.API_GET_COMMENTS_BY_DEAL_ID, {
      params: {
        dealId: dealId,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getCommentByUserAndDealId(
  dealId: string,
  userId: string
) {
  await config.GetUserToken();
  return axios
    .get(config.API_GET_COMMENT_BY_USER_AND_DEAL_ID, {
      params: {
        dealId: dealId,
        userId: userId,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function createComment(comment: any) {
  await config.GetUserToken();
  return axios
    .post(config.API_POST_COMMENT, comment)
    .then(handleResponse)
    .catch(handleError);
}

export async function updateComment(comment: any) {
  await config.GetUserToken();
  return axios
    .put(config.API_PUT_COMMENT, comment)
    .then(handleResponse)
    .catch(handleError);
}
