import { useEffect, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  EmitReferenceEvent,
  SeoReferenceEvents,
} from "../../../helpers/seoHelper";
import { useLocation } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import { dealGroups } from "../../../helpers/refData";


const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      maxWidth: "40vw",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "gray"
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },

  inputInput: {
    fontSize: "12px",
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    // [theme.breakpoints.up("sm")]: {
    //   width: "20ch",
    // },
    maxWidth: "500px",
  },
}));

function SearchInputServer({
  loading,
  ...props
}) {
  const classes = useStyles();
  const { t } = useTranslation(["dealgroups", "translation"]);
  let history = useHistory();
  const search = useLocation().search;
  const q = new URLSearchParams(search).get('q');
  const [searchValue, setSearchValue] = useState(q ? q : "");

  const handleInputFormChange = (event, value, reason) => {
    if (reason === "input") {
      setSearchValue(value);
    }
  };

  let options = dealGroups.map((item) => {
    return {
      section: t("LBL_GROUP_LIST", { ns: "translation" }),
      ...item,
    };
  });
  options = [{
    section: "",
    url: "search",
    key: "SEARCH",
    lnkey: "SEARCH",
    isActive: true,
  }, ...options];




  const handleKeyPress = (event, value) => {

    if (event.key === "Enter") {
      if (searchValue) {
        EmitReferenceEvent(SeoReferenceEvents.search, {
          search_term: searchValue,
        });
        history.push("/search/deals?q=" + searchValue.trim().replace(" ", "+"))

      }
    }
  };

  const handleChange = (event, value) => {

    if (value && value.key) {
      if (value.key === "SEARCH") {
        if (searchValue) {
          EmitReferenceEvent(SeoReferenceEvents.search, {
            search_term: searchValue,
          });
          history.push("/search/deals?q=" + searchValue.trim().replace(" ", "+"))

        }
      } else {
        history.push("/deals/" + value.url)
      }
    }
  };

  useEffect(() => {

  }, []);



  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>


      <Autocomplete
        id="free-solo-demo"
        onChange={handleChange}
        onInputChange={handleInputFormChange}
        inputValue={searchValue}
        freeSolo
        groupBy={(option) => option.section}
        getOptionLabel={(option) => t(option.lnkey, { ns: "translation" })}
        autoComplete={false}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        options={options}
        filterOptions={(options, state) => options}
        renderOption={(option, state) => {
          return option.section === "" ? (
            <Button variant="contained" color="primary" size="small" style={{ width: "100%" }} >
              {t("BTN_SUBMIT_SEARCH", { ns: "translation" })}
            </Button>
          ) : (
            <>
              {t(option.lnkey, { ns: "dealgroups" })}
            </>
          )
        }}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <input type="text" onKeyPress={handleKeyPress} style={{ maxWidth: "none" }} {...params.inputProps} placeholder={t("LBL_SEARCH", { ns: "translation" })} />
          </div>
        )}
      />
    </div>
  );
}

SearchInputServer.propTypes = {
  loading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchInputServer);
