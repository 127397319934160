import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  avatarContainer: {
    width: "150px",
    height: "150px",
    margin: "0 auto",
    alignSelf: "center",
  },
}));

export default function MerchantAvatar({ merchant, ...props }) {
  const classes = useStyles();
  return (
    <div className={classes.avatarContainer}>
      <img src={merchant.logo} className={classes.avatar} alt={merchant.id} />
    </div>
  );
}
