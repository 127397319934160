import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  avatar: {
    height: "60px",
    width: "60px",
    marginLeft: "5px",
    marginRight: "5px",
  },
}));

export default function AvatarComponent({ svgValue, ...props }) {
  const classes = useStyles();
  const createMarkup = (html) => {
    return { __html: html };
  };

  return (
    <div
      className={classes.avatar}
      dangerouslySetInnerHTML={createMarkup(svgValue)}
    ></div>
  );
}
