import * as types from "./actionTypes";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as userApi from "../../api/userApi";

export function uploadImageToUserImageFolder(userId, file, uniId, callbackFn) {
  return function (dispatch, getState, { getFirebase, getFirestore }) {
    const firebase = getFirebase();
    const storage = firebase.storage();
    const fileName = `${uniId}-${file.name}`;
    const uploadTask = storage.ref(`/images/${userId}/${fileName}`).put(file);

    //initiates the firebase side uploading
    uploadTask.on(
      "state_changed",
      (snapShot) => {
        //takes a snap shot of the process as it is happening
      },
      (err) => {
        //catches the errors
      },
      () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        storage
          .ref(`/images/${userId}`)
          .child(fileName)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            callbackFn(fireBaseUrl);
          });
      }
    );
  };
}
