export enum LanguagesValues {
  FR = "fr",
  EN = "en",
  TN = "tn",
  AR = "ar",
}

export enum DealStatus {
  PENDING = "PENDING",
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  VERIFIED = "VERIFIED",
  BANNED = "BANNED",
  EXPIRED = "EXPIRED",
}

export enum PaymentMethod {
  OTHER = "OTHER",
  CASH = "CASH",
  CB = "CB",
}

export enum MeansOfPayment {
  OTHER = "OTHER",
  CASH = "CASH",
  CB = "CB",
  CASH_ON_DELIVERY = "CASH_ON_DELIVERY",
  CHECK ="CHECK",
  ONLINE_PAYMENT = "ONLINE_PAYMENT",
  BANK_TRANSFER = "BANK_TRANSFER",
  INSTALLMENT = "INSTALLMENT",
  FLOUCI="FLOUCI",
  D17 ="D17",
  E_DINAR= "E_DINAR"
}

export enum VoteLevel {
  VERY_HOT = "VERY_HOT",
  HOT = "HOT",
  VERY_COLD = "VERY_COLD",
  COLD = "COLD",
  NEUTRAL = "NEUTRAL",
}

export enum DealCurrency {
  TND = "TND",
  EUR = "EUR",
  USD = "USD",
}

export enum DealGroup {
  DEAL_ADS = "DEAL_ADS",
  OTHER = "OTHER",
  HIGH_TECH = "HIGH_TECH",
  FASHION_AND_ACCESSORIES = "FASHION_AND_ACCESSORIES",
  HEALTH_AND_COSMETICS = "HEALTH_AND_COSMETICS",
  ANIMALS = "ANIMALS",
  FAMILY_AND_CHILDREN = "FAMILY_AND_CHILDREN",
  HOUSE_AND_HABITAT = "HOUSE_AND_HABITAT",
  SPORTS_AND_OUTDOORS = "SPORTS_AND_OUTDOORS",
  TRAVEL = "TRAVEL",
  PHONE_OPERATOR = "PHONE_OPERATOR",
  INTERNET_PROVIDER = "INTERNET_PROVIDER",
  GROCERIES = "GROCERIES",
  GARDEN_AND_DIY = "GARDEN_AND_DIY",
}
export enum DealOrderBy {
  DEFAULT = "DEFAULT",
  CREATE_AT = "CREATE_AT",
  PLUS_HOT = "PLUS_HOT",
  FOR_YOU = "FOR_YOU",
  SAME_GROUP = "SAME_GROUP",
  WITHOUT_VOTE = "WITHOUT_VOTE",
}


export enum MerchantIds {
  OTHER = "18f20173-b58a-47d6-b9ce-6cd39a46ecfc",
  JUMIA_TN = "835aeb8a-eb2c-4f3e-9efa-28be4db4153a",
  MY_TEK_TN = "d0da4e0b-a090-435a-b35f-d04ce88216aa",
  WAMIA_TN = "ee91aefb-5a9e-4819-be84-f889d7c38eb8",
  WIKI_TN = "946e4ace-5114-487c-89cb-61d34d6b1583",
  SAVANA_TN = "cdeb08f1-ff34-4717-bbbd-883c671e86b7",
  SCOOP_TN = "09760c45-64e3-406a-888c-8eb35cdaaa92",
  FNAC_TN = "3e6d6a87-39d6-4779-bdec-c108279df7e7",
  DARTY_TN = "6d31d219-c897-48ef-9e19-4b16e7826ace",
  PEAK_SPORTS_TN = "05927179-1a4b-468d-b7d7-9f86b1a92621",
  DECATHLON_TN = "ac04daf2-0c6b-4240-b0f5-9f2956600c6f",
  BEAUTY_STORE_TN = "d064f57c-4e2e-4e9a-901b-ea586f0e320b",
  FATALES_TN = "13c245c6-2624-402e-8141-af975ddc4628",
  PARASHOP_TN = "51b47c38-5ea5-4728-ab41-5256bd0010a7",
  LAST_PRICE_TN = "b3d77421-471f-4e7a-bbe8-22a1a2d56592",
  POINT_M_TN = "0104ee82-ff09-4aaf-9047-240fba1d10bb",
  PANDA_TN = "62f45e26-4842-405d-8082-a5ed45e873be",
  TUNISIE_BOOKING_TN = "743ea1e9-caab-4823-a20d-cb67f3b4c834",
  TAP_AND_GET_TN = "931e4d0c-d7c6-4158-9b2b-9e721cf22a9b",
  WARM_TN = "06e80fb5-f221-4212-a2ec-77cbc6d249d9",
  MY_SHOP_TN = "83ee0a01-a2fe-4453-a35a-98ad5554aa31",
  MEGA_TN = "239a5a8b-ead8-4f2c-b973-24d278b471cd",
  DIRECTELECTRO_TN = "d7ca41f4-6ffe-4f9c-8357-685805f616e0",
  TDISCOUNT_TN = "2f4c7b94-106e-4157-a59f-0b7ba5b783f8",
  ALLANI_TN = "8a5ba648-a726-46a1-9040-9f19ff56b127",
  TUNISIANET_TN = "0d27e9d2-9355-4eaa-bdbf-6c4e80235317",
  PICSTORE_TN = "82a1e013-2c53-45b8-a8a4-089bc0f86da3",
  BAITY_TN = "765f22f3-871b-4100-aa6a-d2baf9b04f89",
  BOUTIKA_TN = "7ee92870-b189-47e8-a3a1-0f0cb848e6d1",
  TUNISIE_PROMO_TN = "568c074a-849d-4cd8-b587-4323686f6651",
  TAHA_VOYAGES_TN = "b9600ebf-c48d-423e-b403-ac548dfa8bac",
  LAMODE_TN = "46b52496-e1bc-4123-b65d-02037e9dbf6e",
  FIERCE_SPORTSWEAR_TN = "f7d8db63-9f78-4101-ae4e-03a5db517871",
  SPACENET_TN = "abdf7572-fb6e-498f-a6be-39ce546c0b47",
  LA_MAISON_TN = "7d089b32-4a9d-4ea2-b9f7-47cad9a92020",
  CITY_SHOP_TN = "f1950c6f-6219-4dcc-a2e4-dca6ea0b3b6e",
  FARM_TRUST_TN = "d2f04068-a73b-4307-ae6b-b9a25faaaf29",
  FLORAISON_TN = "7341c9cb-1793-4712-9716-7524ae2edc92",
  NOS_SAVEURS_TN = "4e6302c2-7e75-49b0-ad74-5865af32de6b",
  AYSHEK_TN = "51094047-4913-4154-9694-256bd871248c",
  ELAOULA_TN = "12948052-a3f3-4723-ae52-d1d508308ed5",
  SYNOTEC_TN = "a0fe7da2-9f01-491e-b26c-bdcc36661e12",
  JNINA_TN = "b6943084-f502-4f44-80dc-813d6a84f3d1",
  ZOOM_TN = "1f761210-23cd-48f9-9ccb-9ff1fde1671f",
  MEGA_PC_TN = "3a8c3ded-3947-45b1-ae45-59471974b7c3",
  WINCOM_SHOP_TN = "aa6d7d54-c563-4fe6-ad39-dccdd0176cd7",
  SHOPA_TN = "0dc8750f-c224-4d46-b4fb-4570614de7b8",
  AREA_NATURE_TN = "7c2641fb-87aa-45f8-9012-812e4ca48a5b",
  LE_MARCHET_TUNISIEN_TN = "dcbf9eb0-d03a-47e7-81c6-57953544574e",
  PIECES_AUTOS_TN = "b7b5daf6-a103-4bf7-9785-e28c75ff39e9",
  ALGOVITA_TN = "04ea45d6-1f9e-4fa1-9b2d-2be3e29f2b3e",
  MI_STORE_TN = "bb5e2622-382f-49d2-a58b-fcef8b0a166a",
  MASMOUDI_TN = "74326e44-398e-416e-9d98-ec6e5fc52bcc",
  MASMOUDI_FR = "bbfd4625-cab1-4f83-ac5a-c91cb7c8c437",
  INTERIEURS_TN = "910c3168-e196-44ce-9c6f-aafaa26643ad",
  TALOS_TN = "7df57961-5abd-4783-86e4-3cae872c246e",
  BEN_YAGHLANES_SHOPS_TN = "6c4af298-c759-434d-a48e-eb0e8fde7ef3",
  HOLLYWOOD_TN = "100e565b-3b64-4f5a-b104-4822da57dd58",
  NINE_999_TN = "86c44feb-c8aa-4b1b-8974-a751d1c8ccaa",
  TUNISIE_SHOP_TN = "89dd4a1b-20f8-4da7-84b0-b959bf4d0c01",
  MERKADO_TN = "7d19a794-5eaf-4178-992c-93e8df66e067",
  HEXABYTE_TN = "840494a5-6b53-4b85-bd6d-df07dbd696c2",
  TOPNET_TN = "8bb183fd-09ef-4970-a71e-d7d0eaebe712",
  ORANGE_TN = "904c110f-637e-4196-bc57-fe4d48eb6b12",
  OOREDOO_TN = "96615531-331e-4187-972b-7ab685030091",
  TUNISIE_TELECOM_TN = "1c003035-9cfb-4112-85c2-673d0c809833",
  TRAVEL_STORE_TN = "13ce707f-2e7b-4c14-9197-50a2ae48bb60",
  EDEN_TOURS_TN = "0467c2a2-5e90-4dec-aeaa-3917b9a6d271",
  TRAVEL_TODO_TN = "7068b405-108a-4056-9459-8e5c4e000a6a",
  SHOPHEAN_TN = "c696ee22-4bc1-44a6-b462-5d235794d032",
  FEERIE_TN = "ba315927-dc64-4e27-b762-1c571cbbf2cf",
  COSMETIQUE_TN = "6dc98ae4-896c-4f19-bdf9-2347ae0819fd",
  TANIT_DECO_TN = "d99ac9ce-25db-4479-a145-ec672de4e14f",
  ICE_TN = "14882cb9-caa1-46b9-b296-581dab9858c1",
  PROMOUV_TN = "888ccb26-107d-495f-b702-7b430dbebba0",
  MODECO_TN = "38327acd-3e30-4b8c-8750-343916ede3c0",
  TAYARA_TN = "e320df23-bef1-43fa-9cd6-ec393d1f339f",
  VONGO_TN = "3852bfdd-4479-44d8-bff7-be16af573a71",
  AFFARIYET_TN = "7bbfb90e-eed8-4423-95f6-f07e86b2035d",
  ZANIMO_FOUNA_TN = "243cfcfd-89a1-433a-9c9c-7e9080c53070",
  BIO_FOUNA_TN = "e1d1a44f-601f-4b88-9f4d-75e7b9fb0298",
  MAISON_FOUNA_TN = "17eed1b3-9956-4caa-8969-0e81f2f0bb44",
  SANTE_FOUNA_TN = "a1c38469-729c-4dc1-bd13-ef1b16c5bb89",
  BEAUTE_FOUNA_TN = "e168161c-3126-4721-a4cf-2ece3e03ca47",
  BEBE_FOUNA_TN = "789958d6-7e39-4438-a9bc-3e5017a78a72",
  ALIMENTATION_FOUNA_TN = "33e69439-d360-4e78-968c-12b9e2c1c05b",
  ENTRETIEN_FOUNA_TN = "3758659c-e6fd-4ff8-9cb4-e2f8f6a87d66",
  BRICO_FOUNA_TN = "05778258-3ab6-44d1-98bf-f81db4024d89",
  ANIMALERIE_FOUNA_TN = "f9f24049-af5a-4b47-960a-3adb16e69680",
  LOISIRS_FOUNA_TN = "1a3119e0-1ece-475f-9127-56cf701cd10b",
  LIBRAIRIE_FOUNA_TN = "e89954e3-4141-44e9-b57a-73bf1e68f943",
  PROMOTIONS_FOUNA_TN = "30ae4cb1-1af0-491c-984f-2cc323693bdf",
  PHARMA_SHOP_TN = "2b8f0734-e3b1-4f4f-88c6-92ef366d0934",
  CRAVATE_TN = "ac3e8393-9cf6-4f6b-8589-aa812c75b590",
  HABIBA_JEWELRY_TN = "cee53890-47f8-404c-9e1c-cf4e4bba8a9f",
  SAMSUNG_SHOP_TN = "7a01974c-c294-427d-bad5-8b489cc050b9",
}

export class DealModel {
  id: string = "";
  dealUrl: string = "";
  dealImgUrls: string[] = [];
  dealVideoUrl: string = "";
  createAt?: Date;
  updateAt?: Date;
  expireAt?: Date;
  startAt?: Date;
  title: string = "";
  titleTags: string[] = [];
  fbPostId: string = "";

  description: string = "";
  dealCurrency: DealCurrency = DealCurrency.TND;

  dealGroupKey: DealGroup[] = [DealGroup.OTHER];
  dealGroupTags: string[] = [];

  isSponsorised: boolean = false;
  sponsorisedScore: number = 0;

  sku: string = "";

  merchantId: MerchantIds = MerchantIds.OTHER;
  merchantName: string = "";
  merchantBaseUrl: string = "";

  ownerId: string = "";
  ownerInitials: string = "";
  ownerPseudo: string = "";

  newPrice: number = 0;
  oldPrice: number = 0;
  promoCode: string = "";
  shippingFees: number = 0;
  isFreeShipping: boolean = false;

  status: DealStatus = DealStatus.DRAFT;
  paymentMethod: PaymentMethod[] = [PaymentMethod.OTHER];

  vote: number = 0;
  voteLevel: VoteLevel = VoteLevel.NEUTRAL;
  positiveVoteUsers: string[] = [];
  negativeVoteUsers: string[] = [];

  reporterUsers: string[] = [];
  followerUsers: string[] = [];

  // TOD : add new prop when adding new deal and add prop to google analytics
  //brand: string = "";
  //category: string = "";
  //category2: string = "";
  //quantity: number = "";
  //location_id: string = "";
}

export class DealModelMinified {
  id: string = "";
  dealUrl: string = "";
  dealImgUrls: string[] = [];
  title: string = "";
  dealCurrency: DealCurrency = DealCurrency.TND;
  dealGroupKey: DealGroup[] = [DealGroup.OTHER];
  merchantId: MerchantIds = MerchantIds.OTHER;
  merchantName: string = "";
  ownerId: string = "";
  newPrice: number = 0;
  oldPrice: number = 0;
  status: DealStatus = DealStatus.DRAFT;
  followerUsers: string[] = [];
}

export class CommentModel {
  id: string = "";
  body: string = "";
  dealId: string = "";
  userId: string = "";
  userPseudo: string = "";
  createAt?: Date;
  updateAt?: Date;
  isActive: boolean = true;
  constructor(userId: string, userPseudo: string) {
    this.userId = userId;
    this.userPseudo = userPseudo;
    this.isActive = true;
    this.body = "";
    this.id = "";
    this.dealId = "";
  }
}

export class UserModel {
  pseudo: string = "";
  email: string = "";
  password: string = "";
  roles: string[] = [];
  receiveUpdates: boolean = false;
  //followedDeals: DealModelMinified[] = [];
}


export class MerchantModel {
  id: MerchantIds = MerchantIds.OTHER;
  address: object = {
    "@type": "PostalAddress",
    streetAddress: "",
    addressLocality: "",
    addressRegion: "",
    postalCode: "",
    addressCountry: ""
  };
  name: string = "";
  geo : object = {
    latitude: 0,
    longitude: 0,
  }
  description: string = "";
  url: string = "";  
  logoUrl: string = "";
  imgsUrl: string[] = [];
  socialMediaUrls : string[] = [];
  meansOfPayment: MeansOfPayment[] = [MeansOfPayment.OTHER];
  "@type": string =  "LocalBusiness";
  aggregateRating: object = {
    "@type": "AggregateRating",
    ratingValue: "0",
    bestRating: "0",
    ratingCount: "0"
  }

  //stores: MerchantStoreModel[] = [];
}


export class MerchantStoreModel {
  id: string= "";
}
