import GenericTextInput from "./GenericTextInput";
import GenericCheckbox from "./GenericCheckbox";
import GenericDatePicker from "./GenericDatePicker";
import GenericButton from "./GenericButton";
import GenericNumberInput from "./GenericNumberInput";
import GenericSelect from "./GenericSelect";

const Controls = {
  GenericSelect,
  GenericTextInput,
  GenericCheckbox,
  GenericDatePicker,
  GenericButton,
  GenericNumberInput,
};

export default Controls;
