import { useEffect, useState } from "react";
import DealComment from "./DealComment";
import BasicPagination from "../../common/BasicPagination";
import usePagination from "../../../Hooks/usePagination";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { loadComments } from "../../../redux/actions/commentActions";
import { useTranslation } from "react-i18next";
import {
  EmitCustomEvent,
  SeoCustomEvents,
  ConvertDealToAnalyticItemArray,
} from "../../../helpers/seoHelper";

function DealComments({
  auth,
  dealComments,
  loading,
  loadComments,
  isExpanded,
  currentDeal,
  ...props
}) {
  const { t } = useTranslation(["translation"]);
  const [comments, setComments] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  let [page, setPage] = useState(1);
  const PER_PAGE = 3;
  const count = Math.ceil(comments.length / PER_PAGE);
  let commentsPerPage = usePagination(comments, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    commentsPerPage.jump(p);
    EmitCustomEvent(SeoCustomEvents.view_item_comments_page, {
      page: p,
      currency: currentDeal.dealCurrency.toString(),
      value: Number(currentDeal.newPrice.toString()),
      items: ConvertDealToAnalyticItemArray(currentDeal),
    });
  };

  useEffect(() => {
    if (isExpanded) {
      EmitCustomEvent(SeoCustomEvents.view_item_comments, {
        page: page,
        currency: currentDeal.dealCurrency.toString(),
        value: Number(currentDeal.newPrice.toString()),
        items: ConvertDealToAnalyticItemArray(currentDeal),
      });
      if (dealComments && dealComments.length > 0) {
        let commentList = dealComments.slice(0, 10);
        setComments(commentList);
        setIsFetching(false);
      } else {
        loadComments(currentDeal.id).then((_comments) => {
          if (_comments) {
            let commentList = _comments.slice(0, 10);
            setComments(commentList);
          }
          setIsFetching(false);
        });
      }
    }
  }, [isExpanded, dealComments]);

  return (
    <>
      {isFetching ? (
        "Loading..."
      ) : (
        <>
          <DealComment comments={commentsPerPage.currentData()} />
          {comments && comments.length > 0 ? (
            <BasicPagination
              count={count}
              page={page}
              onChange={handleChange}
            />
          ) : (
            t("NO_COMMENTS")
          )}
        </>
      )}
    </>
  );
}

DealComments.propTypes = {
  loadComments: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  let auth = state.firebase.auth;
  let dealComments = state.commentsMap.get(ownProps.currentDeal.id);

  return {
    auth,
    dealComments,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  loadComments,
};
export default connect(mapStateToProps, mapDispatchToProps)(DealComments);
