import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import AdminDealPreview from "./AdminDealPreview";
import Paper from "@material-ui/core/Paper";
import {
  GetGroupUrlFromGroupKey,
  IsValidUrl,
  IsValidVideoUrl,
  GenerateTags,
  stringToFloat,
} from "../../../helpers/modelsHelper";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getDealById,
  getDealByUrl,
  saveAdminDeal,
} from "../../../redux/actions/dealActions";
import { useParams, useHistory } from "react-router-dom";
import SnackbarComponent from "../../common/SnackbarComponent";
import { useTranslation } from "react-i18next";
import AdminManageDealForm from "./AdminManageDealForm";
import ReactPlayer from "react-player";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "24px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  videoContainer: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginTop: "1%",
    marginBottom: "1%",
  },
}));

export function AdminManageDeal({
  auth,
  targetDeal,
  loading,
  saveAdminDeal,
  getDealById,
  getDealByUrl,
  ...props
}) {
  const classes = useStyles();
  const [deal, setDeal] = useState(targetDeal);
  const [existingDealUrl, setExistingDealUrl] = useState(null);
  const [saving, setSaving] = useState(false);

  const [errors, setErrors] = useState({});
  const [snackbarData, setSnackbarData] = useState({
    open: false,
    message: null,
    severity: "info",
  });
  const { t } = useTranslation(["translation"]);

  useEffect(() => {
    setDeal(targetDeal);
  }, [targetDeal]);

  function SnackbarComponentChange() {
    if (snackbarData.severity === "success") {
      setSaving(false);
      //return;
    }
    setSnackbarData({
      open: false,
      message: null,
      severity: snackbarData.severity,
    });
  }

  const formIsValid = () => {
    let _errors = { ...errors };

    if (deal.dealVideoUrl && !IsValidVideoUrl(deal.dealVideoUrl)) {
      _errors.dealVideoUrl = t("LBL_VIDEO_URL_INVALID_FORMAT");
    }

    if (!deal.newPrice) _errors.newPrice = t("LBL_REQUIRED");
    if (!deal.oldPrice) _errors.oldPrice = t("LBL_REQUIRED");
    if (!deal.title) _errors.title = t("LBL_REQUIRED");

    if (!deal.dealUrl) _errors.dealUrl = t("LBL_REQUIRED");
    else if (!IsValidUrl(deal.dealUrl)) {
      _errors.dealUrl = t("LBL_INVALID_URL_FORMAT");
    }

    setErrors(_errors);
    return Object.values(_errors).filter((err) => err).length === 0;
  };

  const handleInputFormChange = (event) => {
    setDeal({ ...deal, [event.target.name]: event.target.value });
  };

  const handleDataChange = (data) => {
    setDeal(data);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formIsValid()) return;
    setSaving(true);
    let dealToSubmit = GenerateTags(deal);
    dealToSubmit = {
      ...dealToSubmit,
      newPrice: stringToFloat(dealToSubmit.newPrice),
      oldPrice: stringToFloat(dealToSubmit.oldPrice),
      shippingFees: stringToFloat(dealToSubmit.shippingFees),
      vote: stringToFloat(dealToSubmit.vote),
    };
    if (!dealToSubmit) {
      setSnackbarData({
        open: true,
        message: "ERROR_CNX_MESSAGE",
        severity: "warning",
      });
      setSaving(false);
      return;
    }
    saveAdminDeal(dealToSubmit)
      .then((deal) => {
        setSnackbarData({
          open: true,
          message: "SUCESS_MESSAGE",
          severity: "success",
        });
        //setDeal(deal);
      })
      .catch((error) => {
        setSnackbarData({
          open: true,
          message: "ERROR_MESSAGE",
          severity: "error",
        });
        setSaving(false);
      });
  };

  const handleInputValidationOnBlur = (event) => {
    let _errors = {};
    switch (event.target.name) {
      case "dealUrl":
        if (!deal.dealUrl) _errors.dealUrl = t("LBL_REQUIRED");
        else if (!IsValidUrl(deal.dealUrl)) {
          _errors.dealUrl = t("LBL_INVALID_URL_FORMAT");
        } else {
          getDealByUrl(deal.dealUrl).then((_deals) => {
            if (_deals && _deals.length > 0) {
              let groupUrl = GetGroupUrlFromGroupKey(_deals[0].dealGroupKey[0]);
              let url =
                window.location.origin +
                "/deal/" +
                groupUrl +
                "/details/" +
                _deals[0].id;
              setExistingDealUrl(url);
            } else {
              setExistingDealUrl(null);
            }
          });
        }

        break;
      case "dealVideoUrl":
        if (deal.dealVideoUrl && !IsValidVideoUrl(deal.dealVideoUrl)) {
          _errors.dealVideoUrl = t("LBL_VIDEO_URL_INVALID_FORMAT");
        }
        break;
      default:
        _errors[event.target.name] = PropIsRequired(event.target.name);
        break;
    }
    setErrors({ ...errors, [event.target.name]: _errors[event.target.name] });
  };

  const PropIsRequired = (propName) => {
    if (!deal[propName]) return t("LBL_REQUIRED");
  };

  const onImgUrlChange = (fireBaseUrl) => {
    setDeal({ ...deal, dealImgUrls: [fireBaseUrl] });
  };

  return (
    <>
      <SnackbarComponent {...snackbarData} onClose={SnackbarComponentChange} />
      <Container className={classes.root}>
        <Grid container spacing={2}>
          <Grid item sm={7} xs={12} component={Paper} elevation={6} square>
            <AdminManageDealForm
              saving={saving}
              errors={errors}
              deal={deal}
              onChange={handleInputFormChange}
              onBlur={handleInputValidationOnBlur}
              onSubmit={handleSubmit}
              handleDataChange={handleDataChange}
              existingDealUrl={existingDealUrl}
            />
          </Grid>
          <Grid item sm={5} xs={12}>
            {deal.dealVideoUrl && deal.dealVideoUrl !== "" ? (
              <Paper variant="outlined" className={classes.videoContainer}>
                <Typography variant="h4">Video</Typography>
                <ReactPlayer
                  url={deal.dealVideoUrl}
                  controls
                  playbackRate={2}
                  width="100%"
                  height="100%"
                />
              </Paper>
            ) : null}
            <AdminDealPreview deal={deal} onImgUrlChange={onImgUrlChange} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

function mapStateToProps(state) {
  let auth = state.firebase.auth;
  return {
    auth,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  saveAdminDeal,
  getDealById,
  getDealByUrl,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminManageDeal);
