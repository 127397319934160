import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";

export default function GenericTextInput({
  name,
  label,
  value,
  onChange,
  error = null,
  ...props
}) {
  return (
    <>
      <TextField
        variant="outlined"
        fullWidth
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        {...(error && { error: true, helperText: error })}
        {...props}
      />
    </>
  );
}

GenericTextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.any,
};

GenericTextInput.defaultProps = {
  error: null,
};
