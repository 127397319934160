import {
  handleAdminDealsResponse,
  handleError,
  handleSingleUserDocResponse,
} from "./apiUtils";

import { FireStoreCollections } from "./config/firebase.config";

export function getDealsByFilter(
  firestore: any,
  dealStatus: any,
  startDate: any,
  endDate: any
) {
  return firestore
    .collection(FireStoreCollections.DEALS_COLLECTION)
    .orderBy("createAt", "desc")
    .where("createAt", ">=", startDate)
    .where("status", "==", dealStatus)
    .where("createAt", "<=", endDate)
    .get()
    .then(handleAdminDealsResponse)
    .catch(handleError);
}

export function getUser(firestore: any, userId: any) {
  return firestore
    .collection(FireStoreCollections.USERS_COLLECTION)
    .doc(userId)
    .get()
    .then(handleSingleUserDocResponse)
    .catch(handleError);
}
