import axios from "../config/api.config";
import * as config from "../config/api.config";
import { handleResponse, handleError } from "../helper/apiUtils";

export async function createUser(user: any, response: any) {
  const _config = {
    headers: { Authorization: `Bearer ${await response.getIdToken()}` },
  };
  return axios
    .post(config.API_POST_USER, user, _config)
    .then(handleResponse)
    .catch(handleError);
}
