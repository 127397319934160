import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  footerFirstLevel: {
    padding: theme.spacing(3, 2),
    marginTop: "auto",
    backgroundColor: "#334859",
  },
  footerSecondLevel: {
    padding: theme.spacing(3, 2),
    marginTop: "auto",
    backgroundColor: "#293847",
  },
  textStyle: {
    color: "white",
  },
}));

export default function Footer() {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);
  return (
    <div id="footer-zone">
      <footer className={classes.footerFirstLevel}>
        <Container maxWidth="sm">
          <Typography variant="body1" className={classes.textStyle}>
            <strong> {t("LBL_TOP_OU_FLOP")}</strong> {t("LBL_FOOTER_MSG")}
          </Typography>
        </Container>
      </footer>
      <footer className={classes.footerSecondLevel}>
        <Container maxWidth="sm">
          <Typography variant="body2" className={classes.textStyle}>
            <Link color="inherit" href="/support/privacy">
              {t("LBL_CONFIDENTIALITY")} {"  |  "}
            </Link>
            <Link color="inherit" href="/support/cookies">
              {t("LBL_POLICY")} {"  |  "}
            </Link>
            <Link color="inherit" href="/support/terms">
              {t("LBL_CONDITION")}
            </Link>
          </Typography>
          <Typography className={classes.textStyle} variant="body1">
            {t("LBL_COPYRIGHT")}
            <Link color="inherit" href="/">
              {t("LBL_YOUR_WEBSITE")}
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
          <Typography className={classes.textStyle} variant="body1">
            {t("LBL_CONTACT_EMAIL")}
          </Typography>
        </Container>
      </footer>
    </div>
  );
}
