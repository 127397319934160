import * as types from "../actions/actionTypes";

const initialState =  {
    authError : null,
  };


export default function authReducer(state = initialState.authError, action) {
  switch (action.type) {
    case types.USER_SIGN_IN_SUCCESS:
        return {...state , authError : null};

    case types.USER_SIGN_IN_FAILER:
        return {...state , authError : action.err.message ?  action.err.message : "ERROR_LOGIN_MSG"};
   
    case types.USER_SIGN_OUT_SUCCESS:
        return state;

    case types.USER_SIGN_OUT_FAILER:
        return state;    

    case types.USER_SIGN_UP_SUCCESS:
        return {...state , authError : null};
    
    case types.USER_SIGN_UP_FAILER:
        return {...state , authError : action.err.message}; 

    default:
      return state;
  }
}