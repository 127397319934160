import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
//import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
//import RemoveCircleOutlineRoundedIcon from "@material-ui/icons/RemoveCircleOutlineRounded";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { voteDeal } from "../../../redux/actions/dealActions";
import { ProcessVote, GetVoteColor } from "../../../helpers/modelsHelper";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import SignInSide from "../../authentication/SignInSide";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { useTranslation } from "react-i18next";
import SnackbarComponent from "../../common/SnackbarComponent";
import {
  EmitCustomEvent,
  SeoCustomEvents,
  ConvertDealToAnalyticItemArray,
} from "../../../helpers/seoHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
    minWidth: "130px",
  },
  dealScore: {
    fontWeight: "bold",
    textAlign: "center",
  },
  divContainer: {
    width: "30px",
    height: "30px",
  },
  appBar: {
    position: "relative",
    backgroundColor: "#293847",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DealVoteHorizontal({
  auth,
  loading,
  voteDeal,
  currentDeal,
  onDataChange,
  ...props
}) {
  const classes = useStyles();
  const [vote, setVote] = useState(0);
  const [hidePositiveVote, setHidePositiveVote] = useState(null);
  const [hideNegativeVote, setHideNegativeVote] = useState(null);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(["translation"]);
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    open: false,
    severity: "error",
  });
  useEffect(() => {
    if (currentDeal) {
      setVote(currentDeal.vote);
    }

    if (auth.uid && currentDeal.negativeVoteUsers) {
      setHideNegativeVote(
        currentDeal.negativeVoteUsers.includes(auth.uid) === false ? null : true
      );
    }

    if (auth.uid && currentDeal.positiveVoteUsers) {
      setHidePositiveVote(
        currentDeal.positiveVoteUsers.includes(auth.uid) === false ? null : true
      );
    }
  }, [currentDeal]);

  const handleClick = (event, _vote) => {
    if (!auth.uid) {
      setOpen(true);
      return;
    }

    if (_vote === 1) setHidePositiveVote(true);
    if (_vote === -1) setHideNegativeVote(true);

    EmitCustomEvent(SeoCustomEvents.update_item_vote, {
      vote: _vote,
      score: currentDeal.vote,
      currency: currentDeal.dealCurrency.toString(),
      value: Number(currentDeal.newPrice.toString()),
      items: ConvertDealToAnalyticItemArray(currentDeal),
    });

    currentDeal = { ...ProcessVote(currentDeal, auth.uid, _vote) };

    voteDeal(currentDeal, auth.uid, _vote).then((_deal) => {
      setVote(vote + _vote);
      onDataChange(_deal);
      setSnackbarData({
        ...snackbarData,
        message: t("SUCCESS_MSG"),
        open: true,
        severity: "success",
      });
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  function SnackbarComponentChange() {
    setSnackbarData({ ...snackbarData, message: null, open: false });
  }
  return (
    <>
      <Paper className={classes.controls} variant="outlined">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end"
        >
          <Grid item sm={4} xs={4}>
            {hidePositiveVote === null ? (
              <IconButton
                color="primary"
                onClick={(event) => handleClick(event, -1)}
                disabled={hideNegativeVote}
                aria-label="flop"
              >
                <ArrowDownwardIcon />
              </IconButton>
            ) : (
              <div className={classes.divContainer}></div>
            )}
          </Grid>
          <Grid item sm={4} xs={4} style={{ alignSelf: "center" }}>
            <Typography
              className={classes.dealScore}
              style={GetVoteColor(vote)}
            >
              {vote}°
            </Typography>
          </Grid>
          <Grid item sm={4} xs={4}>
            {hideNegativeVote === null ? (
              <IconButton
                color="secondary"
                onClick={(event) => handleClick(event, 1)}
                disabled={hidePositiveVote}
                aria-label="top"
              >
                <ArrowUpwardIcon />
              </IconButton>
            ) : (
              <div className={classes.divContainer}></div>
            )}
          </Grid>
        </Grid>
      </Paper>
      <SnackbarComponent
        message={snackbarData.message}
        open={snackbarData.open}
        severity={snackbarData.severity}
        onClose={SnackbarComponentChange}
      />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {t("CONNECTED_USER_REQUIRED")}
            </Typography>
          </Toolbar>
        </AppBar>
        <SignInSide closePopup={true} onClosePopup={handleClose} />
      </Dialog>
    </>
  );
}

DealVoteHorizontal.propTypes = {
  loading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  let auth = state.firebase.auth;
  return {
    auth,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  voteDeal,
};

export default connect(mapStateToProps, mapDispatchToProps)(DealVoteHorizontal);
