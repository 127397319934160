import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Controls from "../common/controls/Controls";
import SnackbarComponent from "../common/SnackbarComponent";
import { connect } from "react-redux";
import { resetPwdFn } from "../../redux/actions/authActions";
import PropTypes from "prop-types";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { IsValidEmail } from "../../helpers/modelsHelper";
import { useTranslation } from "react-i18next";
import CopyrightComponent from "../common/CopyrightComponent";
import PageTagsComponent from "../../components/common/PageTagsComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(/img/forget-pwd.svg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "80%",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export function ForgotPassword({ loading, authError, resetPwdFn, ...props }) {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);
  const [credentials, setCredentials] = useState({ email: "" });
  const [errors, setErrors] = useState({});
  const [resetPwd, setResetPwd] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    message: authError,
    open: authError ? true : false,
    severity: "error",
  });

  useEffect(() => {
    setSnackbarData({
      severity: authError ? "error" : "success",
      message: authError,
      open: authError ? true : false,
    });
  }, [authError, loading]);

  const handleInputFormChange = (event) => {
    setCredentials({ ...credentials, [event.target.name]: event.target.value });
  };

  const formIsValid = () => {
    let _errors = {};
    if (!credentials.email) _errors.email = t("LBL_REQUIRED");
    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formIsValid()) return;
    setResetPwd(true);
    resetPwdFn(credentials)
      .then(() => {
        setSnackbarData({
          severity: "success",
          message: t("RESET_PWD_EMAIL_SENT"),
          open: true,
        });
      })
      .catch((err) => {
        setResetPwd(false);
        setSnackbarData({
          severity: "error",
          message: err.message,
          open: true,
        });
      });
  };

  const handleInputValidationOnBlur = (event) => {
    let _errors = {};
    switch (event.target.name) {
      case "email":
        if (!credentials.email) _errors.email = t("LBL_REQUIRED");
        else if (!IsValidEmail(credentials.email)) {
          _errors.email = t("LBL_FORMAT_INCORRECT");
        }
        break;
      default:
        _errors[event.target.name] = PropIsRequired(event.target.name);
        break;
    }
    setErrors({ ...errors, [event.target.name]: _errors[event.target.name] });
  };

  const PropIsRequired = (propName) => {
    if (!credentials[propName]) return t("LBL_REQUIRED");
  };

  function SnackbarComponentChange() {
    setSnackbarData({ ...snackbarData, message: null, open: false });
  }

  return (
    <Grid container component="main" className={classes.root}>
      <PageTagsComponent title={"RESET_PWD"} />
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <VpnKeyIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("RESET_PWD_MSG")}
          </Typography>
          <form
            className={classes.form}
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
          >
            <Controls.GenericTextInput
              variant="outlined"
              margin="normal"
              fullWidth
              label={t("LBL_EMAIL")}
              name="email"
              value={credentials.email}
              onChange={handleInputFormChange}
              error={errors.email}
              onBlur={handleInputValidationOnBlur}
              required
            />

            <Controls.GenericButton
              disabled={resetPwd}
              text={resetPwd ? t("RESET_PWD") + "..." : t("RESET_PWD")}
              type="submit"
              className={classes.submit}
            />
            <Grid container>
              <Grid item xs>
                <Link href="/sign-in" variant="body2">
                  {t("SIGN_IN")} ?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/sign-up" variant="body2">
                  {t("DONT_HAVE_ACCOUNT")}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <CopyrightComponent />
            </Box>
          </form>
        </div>
      </Grid>

      <SnackbarComponent
        message={snackbarData.message}
        open={snackbarData.open}
        severity={snackbarData.severity}
        onClose={SnackbarComponentChange}
      />
    </Grid>
  );
}

ForgotPassword.propTypes = {
  loading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    loading: state.apiCallsInProgress > 0,
    authError: state.auth ? state.auth.authError : null,
  };
}

const mapDispatchToProps = {
  resetPwdFn,
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
