import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Chip from "@material-ui/core/Chip";
import { dealGroups } from "../../helpers/refData";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Image from "material-ui-image";
const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
    backgroundSize: "80%",
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  imgDiv: {
    height: "80px",
    width: "80px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

export default function LandingPage() {
  const classes = useStyles();
  const { t } = useTranslation(["dealgroups", "translation"]);
  let history = useHistory();
  const handleChipDealGroupClick = (item) => () => {
    window.scrollTo(0, 0);
    history.push("deals/" + item.url);
  };
  return (
    <main>
      {/* Hero unit */}
      <div className={classes.heroContent}>
        <Container maxWidth="md">
          <div className={classes.imgDiv}>
            <Image
              justify="center"
              src="./1cf30186d4d14889b0997c86b596e572.ico"
            />
          </div>
          <Typography variant="h2" align="center" color="textSecondary">
            <strong> {t("LBL_TOP_OU_FLOP", { ns: "translation" })}</strong>{" "}
          </Typography>
          <Typography
            variant="h6"
            align="center"
            color="textSecondary"
            paragraph
          >
            {t("LBL_TOP_OU_FLOP", { ns: "translation" })}{" "}
            {t("LBL_LANDING_MSG_1", { ns: "translation" })} <br />
            {t("LBL_LANDING_MSG_2", { ns: "translation" })}
          </Typography>
          <div className={classes.heroButtons}>
            <Grid container spacing={2} justifyContent="center">
              {dealGroups.map((mapItem, index) => (
                <Grid item key={mapItem.key}>
                  <Chip
                    onClick={handleChipDealGroupClick(mapItem)}
                    variant="outlined"
                    icon={mapItem.icon}
                    label={t(mapItem.key)}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </Container>
      </div>
      <Container className={classes.cardGrid} maxWidth="md">
        {/* End hero unit */}
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                image="./img/landingPage/undraw_add_post_re_174w.svg"
                title="Un système de scores"
              />
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h2">
                  {t("LBL_ADD_DEAL", { ns: "translation" })}
                </Typography>
                <Typography>
                  {t("LBL_ADD_DEAL_MSG", { ns: "translation" })}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                image="./img/landingPage/undraw_upvote_re_qn2k.svg"
                title="Un système de scores"
              />
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h2">
                  {t("LBL_DEAL_SCORE", { ns: "translation" })}
                </Typography>
                <Typography>
                  {t("LBL_DEAL_SCORE_MSG", { ns: "translation" })}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                image="./img/landingPage/undraw_feedback_re_urmj.svg"
                title="LBL_REVIEWS"
              />
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h2">
                  {t("LBL_REVIEWS", { ns: "translation" })}
                </Typography>
                <Typography>
                  {t("LBL_REVIEW_MSG", { ns: "translation" })}{" "}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                image="./img/landingPage/undraw_wallet_aym5.svg"
                title="Image title"
              />
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h2">
                  {t("LBL_SAVE_MONEY", { ns: "translation" })}
                </Typography>
                <Typography>
                  {t("LBL_SAVE_MONEY_MSG", { ns: "translation" })}{" "}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                image="./img/landingPage/undraw_working_late_re_0c3y.svg"
                title="JOIN_US"
              />
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h2">
                  {t("LBL_JOIN_US", { ns: "translation" })}
                </Typography>
                <Typography>
                  {t("LBL_JOIN_US_MSG", { ns: "translation" })}{" "}
                  <strong>
                    {t("LBL_JOIN_US_EMIAL", { ns: "translation" })}{" "}
                  </strong>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                image="./img/landingPage/undraw_product_photography_91i2.svg"
                title="ADVERTISE_WITH_US"
              />
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h2">
                  {t("LBL_ADVERTISE_WITH_US", { ns: "translation" })}
                </Typography>
                <Typography>
                  {t("LBL_ADVERTISE_WITH_MSG", { ns: "translation" })}
                  {"  "}
                  <strong>
                    {t("LBL_ADVERTISING_EMIAL", { ns: "translation" })}
                  </strong>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                image="./img/landingPage/undraw_shared_workspace_re_3gsu.svg"
                title="contact-us"
              />
              <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="h5" component="h2">
                  {t("LBL_CONTACT_US", { ns: "translation" })}
                </Typography>
                <Typography>
                  {t("LBL_CONTACT_US_MSG", { ns: "translation" })}
                  {"  "}
                  <strong>
                    {t("LBL_CONTACT_EMAIL", { ns: "translation" })}
                  </strong>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
}
