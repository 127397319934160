import PropTypes from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default function GenericCheckbox({
  name,
  label,
  value,
  onChange,
  ...props
}) {
  const convertToDefaultEventParam = (name, value) => ({
    target: {
      name,
      value,
    },
  });
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            color="primary"
            name={name}
            onChange={(e) =>
              onChange(convertToDefaultEventParam(name, e.target.checked))
            }
            {...props}
          />
        }
        label={label}
      />
    </>
  );
}

GenericCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

GenericCheckbox.defaultProps = {
  error: "",
};
