import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: "2%",
  },
  textStyle: {
    padding: theme.spacing(2),
  },
}));

export default function LoaderComponent() {
  const classes = useStyles();
  const [msgArray, setMsgArray] = useState([
    "LOADING_MSG1",
    "LOADING_MSG2",
    "LOADING_MSG3",
  ]);
  const [loadingMsg, setLoadingMsg] = useState("LOADING_MSG1");
  const [msgIndex, setMsgIndex] = useState(0);
  const { t } = useTranslation(["translation"]);
  useEffect(() => {
    if (msgIndex <= 2) {
      const timer = setTimeout(() => {
        setMsgIndex(msgIndex + 1);
        setLoadingMsg(msgArray[msgIndex]);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [msgIndex, loadingMsg, msgArray]);

  return (
    <div className={classes.root}>
      <Paper variant="outlined" className={classes.paper}>
        <LinearProgress color="secondary" />
        <Typography variant="body1" className={classes.textStyle}>
          {t(loadingMsg)}
        </Typography>
      </Paper>
    </div>
  );
}
