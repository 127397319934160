import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import DealVoteHorizontal from "./DealVoteHorizontal";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {
  EmitCustomEvent,
  SeoCustomEvents,
  ConvertDealToAnalyticItemArray,
} from "../../../helpers/seoHelper";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
  },
  textColor: {
    color: "black",
    padding: "2%",
    fontWeight: "bold",
  },
  textgrid: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  button: {
    float: "right",
    backgroundColor: "#2bb04a",
    color: "white",
    "&:hover": {
      background: "#44C260",
    },
  },
}));

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function DealVoteHeader(props) {
  const classes = useStyles();
  const handleClick = (event) => {
    EmitCustomEvent(SeoCustomEvents.view_item_url, {
      currency: props.currentDeal.dealCurrency.toString(),
      value: Number(props.currentDeal.newPrice.toString()),
      items: ConvertDealToAnalyticItemArray(props.currentDeal),
    });
    window.open(props.currentDeal.dealUrl);
  };
  return (
    <>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar className={classes.root}>
          <Toolbar>
            <Grid justifyContent="space-between" container>
              <Grid item xs={4} sm={2} md={2}>
                <DealVoteHorizontal
                  currentDeal={props.currentDeal}
                  onDataChange={props.onDataChange}
                />
              </Grid>
              <Grid item xs={2} sm={6} md={6} className={classes.textgrid}>
                <span className={classes.textColor}>
                  {props.currentDeal.title}
                </span>
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  size="small"
                  endIcon={<ExitToAppIcon />}
                  onClick={handleClick}
                >
                  {props.currentDeal.newPrice +
                    " " +
                    props.currentDeal.dealCurrency}
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </>
  );
}
