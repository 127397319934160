
export enum UtmEnum {
    utm_source ="utm_source",
    utm_medium="utm_medium",
    utm_campaign="utm_campaign",
    utm_content="utm_content",
    utm_term="utm_term"
}

export enum UtmMediumEnum {
    cpc ="cpc",
}


export function BuildUtmUrl(url : string , group : string, orderby: string){
    const fullUrl = new URL(url);
    fullUrl.searchParams.append(UtmEnum.utm_source,"topflop")
    fullUrl.searchParams.append(UtmEnum.utm_medium,UtmMediumEnum.cpc)
    fullUrl.searchParams.append(UtmEnum.utm_campaign,group + "_" + orderby)
    return fullUrl.href ;
}