import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import DealDetail from "./DealDetail";
import DealCommentsContainer from "../comments/DealCommentsContainer";
import AddComment from "../comments/AddComment";
import LoaderComponent from "../../common/LoaderComponent";
import InformativeMsgComponent from "../../common/InformativeMsgComponent";
import ContactComponent from "../../common/ContactComponent";
import DealVoteHeader from "../vote/DealVoteHeader";
import Container from "@material-ui/core/Container";
import { useParams } from "react-router-dom";
import { GetEmptyDeal } from "../../../helpers/modelsHelper";
import { getDealById } from "../../../redux/actions/dealActions";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import {
  GetGroupKeysFromGroupUrl,
  GetDealById,
} from "../../../helpers/modelsHelper";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import PageTagsComponent from "../../common/PageTagsComponent";
import {
  EmitCustomEvent,
  SeoCustomEvents,
  ConvertDealToAnalyticItemArray,
} from "../../../helpers/seoHelper";

import { useHistory } from "react-router-dom";
import DealsPropContainer from "../propostion/DealsPropContainer"
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginTop: "1%",
    marginBottom: "1%",
  },
  videoContainer: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginTop: "1%",
    marginBottom: "1%",
  },
  paperAds: {
    padding: theme.spacing(3),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginTop: "1%",
    marginBottom: "1%",
  },
}));

export function DealDetailContainer({ targetDeal, getDealById, ...props }) {
  const { t } = useTranslation(["translation"]);
  const classes = useStyles();
  const [deal, setDeal] = useState(targetDeal);
  const [loading, setLoading] = useState(true);
  let { id } = useParams();
  const [expanded, setExpanded] = React.useState(false);
  const location = useLocation();
  let history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (targetDeal && !targetDeal.id) {
      getDealById(id)
        .then((deal) => {
          setDeal(deal);
          setLoading(false);
        })
        .catch((err) => {
          history.push("/NotFound");
        });
    } else {
      setLoading(false);
    }
  }, [location]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleStartVideo = () => {
    EmitCustomEvent(SeoCustomEvents.view_item_video, {
      currency: targetDeal.dealCurrency.toString(),
      value: Number(targetDeal.newPrice.toString()),
      items: ConvertDealToAnalyticItemArray(targetDeal),
    });
  };

  const handleDataChange = (newData) => {
    setDeal(newData);
  };

  return (
    <>
      <Container>
        {deal ? (
          <div className={classes.root}>
            <PageTagsComponent
              title={deal.title}
              description={deal.description}
              keywords={deal.merchantName}
              subDescription={"SITE_URL"}
            />
            <DealVoteHeader
              currentDeal={deal}
              onDataChange={handleDataChange}
            />
            <Grid item sm={12} xs={12}>
              <Paper variant="outlined" className={classes.paperAds}>
                {" "}
                <ContactComponent
                  titleKey={"LBL_ADVERTISE_WITH_US"}
                  msgKey={"LBL_ADVERTISE_WITH_MSG"}
                  emailKey={"LBL_ADVERTISING_EMIAL"}
                />
              </Paper>
            </Grid>
            <Grid container spacing={2}>
              <Grid item sm={8} xs={12}>
                {loading ? (
                  <LoaderComponent />
                ) : (
                  <>
                    {deal.id && (
                      <DealDetail deal={deal} onDataChange={handleDataChange} />
                    )}
                  </>
                )}
                <Paper variant="outlined" className={classes.paperAds}>
                {t("LBL_ADVERTISING_SPACE", { ns: "translation" })}
                </Paper>
              
                <DealsPropContainer currentDeal={deal}/>

          
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{t("COMMUNITY_COMMENTS")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item sm={12} xs={12}>
                        <DealCommentsContainer
                          isExpanded={expanded === "panel1"}
                          currentDeal={deal}
                        />
                      </Grid>
                      <Grid item sm={12} xs={12}>
                        <AddComment deal={deal} />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {deal.dealVideoUrl && deal.dealVideoUrl != "" ? (
                  <Paper variant="outlined" className={classes.videoContainer}>
                    <ReactPlayer
                      url={deal.dealVideoUrl}
                      controls
                      playbackRate={2}
                      width="100%"
                      height="100%"
                      onStart={handleStartVideo}
                    />
                  </Paper>
                ) : null}
              </Grid>
              <Grid item sm={4} xs={12}>
                <Paper variant="outlined" className={classes.paperAds}>
                {t("LBL_ADVERTISING_SPACE", { ns: "translation" })}
                </Paper>
              </Grid>
            </Grid>
          </div>
        ) : (
          <InformativeMsgComponent msgKey="NO_DEAL_FOUND" />
        )}
      </Container>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  const dealId = ownProps.match.params.id;
  const group = ownProps.match.params.group;
  let groupKey = GetGroupKeysFromGroupUrl(group);
  let deals = state.dealReducer.deals;
  let targetDeal =
    dealId && groupKey
      ? GetDealById(dealId, deals)
      : GetEmptyDeal();

  return {
    targetDeal,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  getDealById,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DealDetailContainer);
