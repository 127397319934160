import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
  } from "react-share";
import InstagramIcon from '@material-ui/icons/Instagram';
import { makeStyles } from "@material-ui/core/styles";
import LinkIcon from '@material-ui/icons/Link';
import Link from '@material-ui/core/Link';
const useStyles = makeStyles((theme) => ({

}));

export default function SocialMediaIcon({ url, ...props }) {
  const classes = useStyles();
  function GetSocialMediaIcon  (url)  {
    if (!url) return null;
    if (url.toLowerCase().includes("facebook")) {
      return <FacebookIcon size={32}/>
    }

    if (url.toLowerCase().includes("twitter")) {
      return <TwitterIcon size={32}/>
    }

    if (url.toLowerCase().includes("linkedin")) {
        return <LinkedinIcon size={32}/>
    }

    if (url.toLowerCase().includes("instagram")) {
        return <InstagramIcon fontSize="large" />
    }

    return  <LinkIcon/>
      
}
  return (
    <>
    <Link href={url} target="_blank" >
      {GetSocialMediaIcon(url)}
    </Link>
   
    </>
  );
}
