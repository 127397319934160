import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import UserProfileTabs from "../UserProfileTabs";
import Container from "@material-ui/core/Container";
import UserDeals from "./display/UserDeals";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { loadUserDeals } from "../../../redux/actions/dealActions";
import PageTagsComponent from "../../common/PageTagsComponent";
import LoaderComponent from "../../common/LoaderComponent";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "2%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function UserDealContainer({ auth, userDeals, loadUserDeals, ...props }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth.uid && userDeals && userDeals.length === 0) {
      loadUserDeals(auth.uid)
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <>
      <UserProfileTabs />
      <PageTagsComponent title={"USER_DEALS"} />
      <Container className={classes.root}>
        {loading ? (
          <LoaderComponent />
        ) : (
          <UserDeals dealsData={userDeals} myDeals={true} />
        )}
      </Container>
    </>
  );
}

UserDealContainer.propTypes = {
  loadUserDeals: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  let auth = state.firebase.auth;
  let userDeals = state.userAccountData.userDeals;
  return {
    auth,
    userDeals,
  };
}

const mapDispatchToProps = {
  loadUserDeals,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserDealContainer);
