import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: "2%",
  },
  textStyle: {
    padding: theme.spacing(2),
  },
}));

export default function InformativeMsgComponent({ msgKey, ...props }) {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);

  return (
    <div className={classes.root}>
      <Paper variant="outlined" className={classes.paper}>
        <Typography variant="body1" className={classes.textStyle}>
          {t(msgKey)}
        </Typography>
      </Paper>
    </div>
  );
}
