import { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import Divider from "@material-ui/core/Divider";
import PropTypes from "prop-types";
import Controls from "../../common/controls/Controls";
import { dealGroups, dealPaymentMethod } from "../../../helpers/refData";
import Button from "@material-ui/core/Button";
import {
  MapPaymentDataFromDeal,
  MapGroupsDataFromDeal,
  IsValidUrl,
  GetMerchantFromUrl,
  GetCurrencyList,
  GetDealStatusList,
} from "../../../helpers/modelsHelper";
import MetaScrapperComponent from "../../common/MetaScrapperComponent";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  paper: {
    margin: theme.spacing(6, 0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  dropzoneClass: {
    backgroundColor: "#F5F5F5",
  },
  dropzoneClassContainer: {
    backgroundColor: "#F5F5F5",
    border: "solid !important",
    minHeight: "0px !important",
  },
  hiddenDiv: {
    visibility: "hidden",
    maxHeight: "0px",
  },
}));

export default function AdminManageDealForm({
  saving = false,
  errors,
  deal,
  onChange,
  onBlur,
  onSubmit,
  handleDataChange,
  existingDealUrl,
  ...props
}) {
  const classes = useStyles();
  const [groupsData, setGroupsData] = useState(dealGroups);

  const [paymentMethodData, setPaymentMethodData] = useState(dealPaymentMethod);
  const [fetching, setFetching] = useState(false);

  const { t } = useTranslation(["translation", "dealgroups"]);

  useEffect(() => {
    setPaymentMethodData(MapPaymentDataFromDeal(deal));
    setGroupsData(MapGroupsDataFromDeal(deal));
    setFetching(false);
  }, [deal.dealUrl]);

  const handleChipDealGroupClick = (itemKey) => () => {
    let updatedData = groupsData.map((item) => {
      if (item.key === itemKey && item.isActive === false)
        return { ...item, isActive: true };
      if (item.key !== itemKey) return { ...item, isActive: false };
      return { ...item };
    });
    setGroupsData(updatedData);
    let updatedtDeal = {
      ...deal,
      dealGroupKey: updatedData
        .filter((item) => item.isActive === true)
        .map((item) => item.key),
    };
    handleDataChange(updatedtDeal);
  };

  const handleChipPaymentMethodDataClick = (itemKey) => () => {
    let updatedData = paymentMethodData.map((item) =>
      item.key === itemKey ? { ...item, isActive: !item.isActive } : item
    );
    setPaymentMethodData(updatedData);
    deal = {
      ...deal,
      paymentMethod: updatedData
        .filter((item) => item.isActive === true)
        .map((item) => item.key),
    };
    handleDataChange(deal);
  };

  const handleScrapperChangeData = (data) => {
    if (data === false) {
      let merchantData = GetMerchantFromUrl(deal.dealUrl);
      deal = {
        ...deal,
        merchantId: merchantData.merchantId,
        merchantName: merchantData.merchantName,
        merchantBaseUrl: merchantData.baseUrl,
      };
      handleDataChange(deal);
    } else {
      let merchantData = GetMerchantFromUrl(data.url);
      deal = {
        ...deal,
        title: data.title,
        description: data.description,
        // content : data.content  ,
        dealImgUrls: data.image,
        merchantId: merchantData.merchantId,
        merchantName: merchantData.merchantName,
        merchantBaseUrl: merchantData.baseUrl,
      };
      handleDataChange(deal);
    }
  };

  return (
    <div className={classes.paper}>
      <Typography component="h1" variant="h5">
        {deal.id === "" ? t("ADD_NEW_DEAL") : t("UPDATE_DEAL")} ‌
      </Typography>
      <form
        className={classes.form}
        onSubmit={onSubmit}
        noValidate
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Controls.GenericTextInput
              label={t("LBL_DEAL_URL")}
              name="dealUrl"
              value={deal.dealUrl}
              onChange={onChange}
              onBlur={onBlur}
              error={errors.dealUrl}
              required
            />
          </Grid>

          <Grid item xs={2}>
            <Button
              disabled={fetching}
              onClick={(event) => {
                setFetching(!fetching);
              }}
              fullWidth
              variant="contained"
              size="large"
              color="primary"
            >
              {fetching ? "fetching..." : "fetch meta"}
            </Button>
          </Grid>

          {fetching === true ? (
            <Grid item xs={12}>
              {IsValidUrl(deal.dealUrl) && (
                <MetaScrapperComponent
                  url={deal.dealUrl}
                  onDataChange={handleScrapperChangeData}
                />
              )}
            </Grid>
          ) : null}

          {existingDealUrl ? (
            <Grid item xs={12}>
              <Typography>
                <a href="#" onClick={(event) => window.open(existingDealUrl)}>
                  {existingDealUrl}
                </a>
                ‌
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Controls.GenericTextInput
              label={t("LBL_DEAL_ID")}
              name="id"
              value={deal.id}
              onChange={onChange}
              onBlur={onBlur}
              error={errors.id}
              required
              disabled={deal.id !== ""}
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.GenericTextInput
              label={t("LBL_DEAL_OWNER_ID")}
              name="ownerId"
              value={deal.ownerId}
              disabled={true}
              onChange={onChange}
              onBlur={onBlur}
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.GenericTextInput
              label={t("LBL_DEAL_MERCHANT_ID")}
              name="merchantId"
              value={deal.merchantId}
              disabled={true}
              onChange={onChange}
              onBlur={onBlur}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={12}
              variant="outlined"
              label={t("LBL_DEAL_IMG_URLS")}
              name="dealImgUrls"
              value={deal.dealImgUrls ? deal.dealImgUrls.join("\n\n") : ""}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              label={t("LBL_DEAL_REPORTED_USERS")}
              name="reporterUsers"
              value={deal.reporterUsers ? deal.reporterUsers.join("\n") : ""}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              label={t("LBL_DEAL_FOLLOWED_USERS")}
              name="followerUsers"
              value={deal.followerUsers ? deal.followerUsers.join("\n") : ""}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider variant="middle" />
          </Grid>
          <Grid item xs={12}>
            <Typography component="h1" variant="h6">
              {t("LBL_DEAL_DETAILS")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controls.GenericNumberInput
              label={t("LBL_DEAL_NEW_PRICE")}
              name="newPrice"
              value={deal.newPrice}
              onChange={onChange}
              error={errors.newPrice}
              onBlur={onBlur}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controls.GenericNumberInput
              label={t("LBL_DEAL_OLD_PRICE")}
              name="oldPrice"
              value={deal.oldPrice}
              onChange={onChange}
              error={errors.oldPrice}
              onBlur={onBlur}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controls.GenericNumberInput
              label={t("LBL_DEAL_SHIPPING_FEES")}
              name="shippingFees"
              value={deal.shippingFees}
              onChange={onChange}
              error={errors.shippingFees}
              disabled={deal.isFreeShipping}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controls.GenericSelect
              label={t("LBL_DEAL_CURRENCY")}
              name="dealCurrency"
              value={deal.dealCurrency}
              onChange={onChange}
              options={GetCurrencyList()}
              error={errors.dealCurrency}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controls.GenericSelect
              label={t("LBL_DEAL_STATUS")}
              name="status"
              value={deal.status}
              onChange={onChange}
              options={GetDealStatusList()}
              error={errors.status}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controls.GenericTextInput
              label={t("LBL_DEAL_TEMPERATURE")}
              name="vote"
              value={deal.vote}
              onChange={onChange}
              onBlur={onBlur}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controls.GenericCheckbox
              label={t("LBL_DEAL_FREE_SHIPPING")}
              name="isFreeShipping"
              value={deal.isFreeShipping}
              onChange={onChange}
              disabled={deal.shippingFees > 0}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controls.GenericTextInput
              label={t("LBL_DEAL_PROMO_CODE")}
              name="promoCode"
              value={deal.promoCode}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography component="h1" variant="h6">
              {t("LBL_DEAL_PAYMENT_OPTIONS")}
            </Typography>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            {paymentMethodData.map((mapItem, index) => (
              <Grid item key={mapItem.key + index}>
                <Chip
                  key={index + mapItem.key}
                  label={t(mapItem.lnKey)}
                  clickable
                  color={mapItem.isActive ? "primary" : "default"}
                  onDelete={handleChipPaymentMethodDataClick(mapItem.key)}
                  onClick={handleChipPaymentMethodDataClick(mapItem.key)}
                  deleteIcon={mapItem.isActive ? <DoneIcon /> : null}
                  variant="outlined"
                  icon={mapItem.icon}
                />
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Divider variant="middle" />
          </Grid>
          <Grid item xs={12}>
            <Controls.GenericTextInput
              label={t("LBL_DEAL_TITLE")}
              name="title"
              value={deal.title}
              onChange={onChange}
              error={errors.title}
              onBlur={onBlur}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={2}
              variant="outlined"
              label={t("LBL_DEAL_TITLE_TAGS")}
              name="titleTags"
              value={deal.titleTags || ""}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={6}
              variant="outlined"
              label={t("LBL_DEAL_DESCRIPTION")}
              name="description"
              value={deal.description || ""}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.GenericTextInput
              label={t("LBL_DEAL_VIDEO_URL")}
              name="dealVideoUrl"
              value={deal.dealVideoUrl}
              onChange={onChange}
              error={errors.dealVideoUrl}
              onBlur={onBlur}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controls.GenericDatePicker
              label={t("DEAL_START_AT")}
              name="startAt"
              value={deal.startAt}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controls.GenericDatePicker
              label={t("DEAL_END_AT")}
              name="expireAt"
              value={deal.expireAt}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography component="h1" variant="h6">
              {t("LBL_DEAL_DOMAIN")} ‌
            </Typography>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            {groupsData.map((mapItem, index) => (
              <Grid item key={mapItem.key + index}>
                <Chip
                  key={index + mapItem.key}
                  label={t(mapItem.lnkey, { ns: "dealgroups" })}
                  clickable
                  color={mapItem.isActive ? "primary" : "default"}
                  onClick={handleChipDealGroupClick(mapItem.key)}
                  onDelete={handleChipDealGroupClick(mapItem.key)}
                  deleteIcon={mapItem.isActive ? <DoneIcon /> : null}
                  variant="outlined"
                />
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={2}
              variant="outlined"
              label={t("LBL_DEAL_GROUP_TAGS")}
              name="dealGroupTags"
              value={deal.dealGroupTags || ""}
              disabled={true}
            />
          </Grid>
        </Grid>
        <Controls.GenericButton
          disabled={saving}
          text={saving ? "Saving..." : "Save"}
          type="submit"
          className={classes.submit}
        />
      </form>
    </div>
  );
}

