import axios from "../config/api.config";
import * as config from "../config/api.config";
import {
  handleDealListResponse,
  handleDealResponse,
  handleError,
} from "../helper/apiUtils";


export async function searchDeals(q: string) {
  return axios
    .get(config.API_SEARCH_DEALS, {
      params: {
        q: q
      },
    })
    .then(handleDealListResponse)
    .catch(handleError);
}
export async function getDealsByDomainKey(dealGrKey: string ,dealOrderBy : string) {
  return axios
    .get(config.API_GET_DEALS_BY_DOMAIN, {
      params: {
        dealGrKey: dealGrKey,
        dealOrderBy: dealOrderBy
      },
    })
    .then(handleDealListResponse)
    .catch(handleError);
}

export function getDealById(dealId: string) {
  return axios
    .get(config.API_GET_DEAL_BY_ID, {
      params: {
        dealId: dealId,
      },
    })
    .then(handleDealResponse)
    .catch(handleError);
}

export async function getDealByUrl(dealUrl: string) {
  await config.GetUserToken();
  return axios
    .get(config.API_GET_DEAL_BY_URL, {
      params: {
        dealUrl: dealUrl,
      },
    })
    .then(handleDealResponse)
    .catch(handleError);
}

export async function getSavedDeals(userId: string) {
  await config.GetUserToken();
  return axios
    .get(config.API_GET_DEAL_BY_USER_ID, {
      params: {
        userId: userId,
      },
    })
    .then(handleDealListResponse)
    .catch(handleError);
}

export async function getDealsOwner(ownerId: string) {
  await config.GetUserToken();
  return axios
    .get(config.API_GET_DEAL_BY_OWNER_ID, {
      params: {
        ownerId: ownerId,
      },
    })
    .then(handleDealListResponse)
    .catch(handleError);
}

export async function createDeal(deal: any) {
  await config.GetUserToken();
  return axios
    .post(config.API_POST_DEAL, deal)
    .then(handleDealResponse)
    .catch(handleError);
}

export async function updateDeal(deal: any) {
  await config.GetUserToken();
  return axios
    .put(config.API_PUT_DEAL, deal)
    .then(handleDealResponse)
    .catch(handleError);
}

export async function followDeal(dealId: string, userId: string) {
  await config.GetUserToken();
  return axios
    .post(config.API_POST_FOLLOW_DEAL, null, {
      params: {
        dealId: dealId,
        userId: userId,
      },
    })
    .then(handleDealResponse)
    .catch(handleError);
}

export async function unFollowDeal(dealId: string, userId: string) {
  await config.GetUserToken();
  return axios
    .post(config.API_POST_UNFOLLOW_DEAL, null, {
      params: {
        dealId: dealId,
        userId: userId,
      },
    })
    .then(handleDealResponse)
    .catch(handleError);
}

export async function reportDeal(dealId: string, userId: string) {
  await config.GetUserToken();
  return axios
    .post(config.API_POST_REPORT_DEAL, null, {
      params: {
        dealId: dealId,
        userId: userId,
      },
    })
    .then(handleDealResponse)
    .catch(handleError);
}

export async function updateDealTemperature(
  dealId: string,
  userId: string,
  vote: Number
) {
  await config.GetUserToken();
  return axios
    .post(config.API_POST_TEMPERATURE_DEAL, null, {
      params: {
        dealId: dealId,
        userId: userId,
        vote: vote,
      },
    })
    .then(handleDealResponse)
    .catch(handleError);
}

export async function deleteDeal(dealId: string) {
  await config.GetUserToken();
  return axios
    .delete(config.API_DELETE_DEAL, {
      params: {
        dealId: dealId,
      },
    })
    .then(handleDealResponse)
    .catch(handleError);
}
